import { BaseClient, logisticsApi } from '../../shared';
import {
    IPlanning,
    IPlanningEntry,
    IPlanningEntryQuery,
    IPlanningQuery,
    ISavePattern,
    ISavePlanning,
    ISavePlanningEntry,
} from '../models';

class PlanningsClient extends BaseClient<IPlanning, ISavePlanning, IPlanningQuery> {
    constructor() {
        super(logisticsApi, '/plannings');
    }

    public async getPlanningEntries(planningId: string, params: IPlanningEntryQuery) {
        const { data } = await this.api.get<IPlanningEntry[]>(`${this.basePath}/${planningId}/entries`, { params });
        return data;
    }

    public async createPlanningEntry(planning: string, entry: ISavePlanningEntry): Promise<IPlanningEntry> {
        const { data } = await this.api.post<IPlanningEntry>(`${this.basePath}/${planning}/entries`, entry);
        return data;
    }

    public async createPattern(planning: string, pattern: ISavePattern): Promise<void> {
        await this.api.post<IPlanningEntry>(`${this.basePath}/${planning}/patterns`, pattern);
    }

    public async updatePlanningEntry(planning: string, entry: ISavePlanningEntry): Promise<IPlanningEntry> {
        const { data } = await this.api.put<IPlanningEntry>(`${this.basePath}/${planning}/entries/${entry.id}`, entry);
        return data;
    }

    public async deletePlanningEntry(
        planning: string,
        entry: string,
        includeFutureEntries: boolean,
    ): Promise<IPlanningEntry> {
        const { data } = await this.api.delete<IPlanningEntry>(
            `${this.basePath}/${planning}/entries/${entry}?includeFutureEntries=${includeFutureEntries}`,
        );
        return data;
    }
}

export const planningsClient = new PlanningsClient();
