export const authTranslationsNL = {
    getProvidersError: 'Er is een probleem opgetreden bij het ophalen van de providers.',
    getUserError: 'Er is een probleem opgetreden bij het ophalen van de gebruiker.',
    getUserRolesError: 'Er is een probleem opgetreden bij het ophalen van de rollen van de gebruiker.',
    selectLoginMethod: 'Welkom terug in de Carity Logistics applicatie. Selecteer hieronder je login methode.',
    loginTitle: 'Welkom terug!',
    forbiddenTitle: 'Geen toegang',
    forbiddenText: 'U heeft geen toegang tot deze pagina',
    backButton: 'Ga terug naar vorige pagina',
};
