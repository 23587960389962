import { Checkbox, FormControlLabel } from '@mui/material';
import { ComponentProps, FC } from 'react';
import { Controller } from 'react-hook-form';

type Props = ComponentProps<typeof Checkbox> & {
    name: string;
    label: string;
};

export const ControlledCheckbox: FC<Props> = ({ name, label, ...props }) => {
    return (
        <Controller
            name={name}
            defaultValue={false}
            render={({ field: { name, onChange, value } }) => (
                <FormControlLabel
                    control={<Checkbox {...props} checked={value} onChange={onChange} name={name} />}
                    label={label}
                />
            )}
        />
    );
};
