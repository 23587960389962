import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledCheckbox,
    ControlledDatePicker,
    ControlledInput,
    ControlledSelect,
    FormCard,
    FormGrid,
    IOrganisation,
    IParams,
    Page,
    Section,
    useOrganisationsList,
} from '../../../shared';
import { FormProvider, useForm } from 'react-hook-form';
import { IBlacklistedProductForm, IProductCategory } from '../../models';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MenuItem } from '@mui/material';
import { useBlacklistedProductSchema } from '../../validators';
import { blacklistedProductFromFormMapper, blacklistedProductToFormMapper } from '../../mappers';
import { useBlackListedProduct, useProductCategoriesList, useSaveBlackListedProduct } from '../../hooks';

export const BlacklistedProductEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: blacklistedProduct, isFetching } = useBlackListedProduct(id);
    const { mutateAsync: saveBlacklistedProduct, isPending: isPendingSave } = useSaveBlackListedProduct();
    const { data: organisations, isPending: isOrganisationsLoading } = useOrganisationsList({ pageSize: 100 });
    const { data: categories, isPending: isCategoriesLoading } = useProductCategoriesList({ pageSize: 100 });

    const form = useForm<IBlacklistedProductForm>({
        resolver: yupResolver(useBlacklistedProductSchema()),
        mode: 'all',
    });
    const isBlockForAllOrganisations = form.watch('blockForAllOrganisations');
    const isPermanentWatch = form.watch('isPermanent');
    const isBlockByProductCode = form.watch('blockByProductCode');

    useEffect(() => {
        if (blacklistedProduct) {
            form.reset(blacklistedProductToFormMapper(blacklistedProduct));
        }
    }, [blacklistedProduct, form]);

    const onSubmit = useCallback(
        async (item: IBlacklistedProductForm) => {
            await saveBlacklistedProduct({ id, item: blacklistedProductFromFormMapper(item) });
            navigate('/admin/blacklisted-products');
        },
        [id, navigate, saveBlacklistedProduct],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            <Button onClick={() => navigate('/admin/blacklisted-products')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, navigate],
    );

    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={blacklistedProduct ? t('editBlacklistedProduct') : t('newBlacklistedProduct')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isPendingSave || isOrganisationsLoading || isCategoriesLoading}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Section title={t('data')}>
                        <FormGrid containerProps={{ direction: 'column' }}>
                            <FormGrid>
                                <ControlledCheckbox
                                    name="blockForAllOrganisations"
                                    label={t('blockForAllOrganisations')}
                                />
                            </FormGrid>

                            {!isBlockForAllOrganisations && (
                                <FormGrid>
                                    <ControlledSelect
                                        name="organisationId"
                                        label={t('organisation')}
                                        required
                                        minWidth={400}
                                        disabled={isBlockForAllOrganisations}
                                    >
                                        {(organisations?.data || []).map((organisation: IOrganisation) => (
                                            <MenuItem value={organisation.id} key={organisation.id}>
                                                {organisation.name}
                                            </MenuItem>
                                        ))}
                                    </ControlledSelect>
                                </FormGrid>
                            )}

                            <ControlledCheckbox name="blockByProductCode" label={t('blockByProductCode')} />

                            {!isBlockByProductCode && (
                                <FormGrid>
                                    <ControlledSelect name="categoryId" label={t('category')} minWidth={400}>
                                        <MenuItem value="" sx={{ p: 2 }} />
                                        {(categories?.data || []).map((category: IProductCategory) => (
                                            <MenuItem value={category.id} key={category.id}>
                                                {category.name}
                                            </MenuItem>
                                        ))}
                                    </ControlledSelect>

                                    <ControlledInput
                                        name="partialProductName"
                                        label={t('partialProductName')}
                                        sx={{ minWidth: '400px' }}
                                    />
                                </FormGrid>
                            )}

                            {isBlockByProductCode && (
                                <FormGrid>
                                    <ControlledInput
                                        name="productCode"
                                        label={t('productCode')}
                                        sx={{ minWidth: '400px' }}
                                    />
                                </FormGrid>
                            )}

                            <ControlledCheckbox name="isPermanent" label={t('isPermanent')} />

                            {!isPermanentWatch && (
                                <FormGrid>
                                    <ControlledDatePicker name="startDate" label={t('startDate')} width={400} />
                                    <ControlledDatePicker name="endDate" label={t('endDate')} width={400} />
                                </FormGrid>
                            )}
                        </FormGrid>
                    </Section>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
