import { logisticsApi, ReadOnlyClient } from '../../shared';
import { IStockProduct } from '../models';

class StockProductsClient extends ReadOnlyClient<IStockProduct> {
    constructor() {
        super(logisticsApi, '/stock-products');
    }
}

export const stockProductsClient = new StockProductsClient();
