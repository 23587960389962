import { Chip, Grid } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { IProductCategory } from '../../../admin/models';
import { useAllCategories } from '../../hooks';
import { compareProductCategory } from '../../utils';

interface Props {
    usedCategories?: IProductCategory[];
}

export const OrganisationProgressLegend: FC<Props> = ({ usedCategories }) => {
    const categories = useAllCategories();

    const sortedCategories = useMemo(() => {
        return categories.sort(compareProductCategory);
    }, [categories]);

    const isCategoryUsed = useCallback(
        (category: IProductCategory) => usedCategories?.some((used) => used.id === category.id),
        [usedCategories],
    );
    return (
        <Grid container spacing={1} direction="row" flexWrap="wrap">
            {sortedCategories.map((category) => (
                <Grid item key={category.id}>
                    <Chip
                        label={`${category.name} = ${category.abbreviation}`}
                        size="small"
                        sx={{
                            backgroundColor: '#FFF',
                            border: isCategoryUsed(category) ? 1.5 : 1,
                            borderColor: isCategoryUsed(category) ? 'black' : 'gray',
                        }}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
