import { useMemo } from 'react';
import { array, mixed, object, string } from 'yup';
import { ContactType, IAddress, IContact } from '../../shared';

export function useOrganisationSchema() {
    return useMemo(
        () =>
            object().shape({
                name: string().required(),
                type: string().required(),
                address: object<IAddress>()
                    .shape({
                        street: string().optional(),
                        number: string().optional(),
                        postalCode: string().optional(),
                        city: string().optional(),
                        country: string().optional(),
                    })
                    .optional(),
                contacts: array()
                    .of(
                        object<IContact>().shape({
                            id: string().optional(),
                            type: mixed<ContactType>().oneOf(Object.values(ContactType)).required(),
                            data: string().required(),
                        }),
                    )
                    .optional(),
            }),
        [],
    );
}
