import React, { FC } from 'react';
import { Button, TableCell } from '@mui/material';
import { IFormWeekEntry } from '../../models';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { ControlledCheckbox } from '../../../shared';

interface Props {
    onAddEntry: (day: keyof IFormWeekEntry, isCategory: boolean) => void;
    day: keyof IFormWeekEntry;
    path: string;
}

export const AddEntry: FC<Props> = ({ onAddEntry, day, path }) => {
    const { t } = useTranslation();
    const isAppointmentOnlyWatch = useWatch({ name: `${path}.isAppointmentOnly`, defaultValue: false });
    const watchRequirements = useWatch({ name: `${path}.requirements`, defaultValue: [] });
    const watchIsFeadProductsPattern = useWatch({ name: 'feadProductsPattern' });

    return (
        <>
            {!watchIsFeadProductsPattern && (
                <>
                    <TableCell align="center">
                        <ControlledCheckbox
                            name={`${path}.isAppointmentOnly`}
                            label={``}
                            sx={{ display: watchRequirements.length > 0 ? 'none' : 'block' }}
                        />
                    </TableCell>
                    <TableCell colSpan={1}>
                        <Button onClick={() => onAddEntry(day, true)} disabled={isAppointmentOnlyWatch}>
                            + {t('addCategory')}
                        </Button>
                    </TableCell>
                </>
            )}

            <TableCell colSpan={1}>
                <Button onClick={() => onAddEntry(day, false)} disabled={isAppointmentOnlyWatch}>
                    + {t('addProduct')}
                </Button>
            </TableCell>

            <TableCell colSpan={2} />
        </>
    );
};
