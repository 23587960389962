import { Button } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IParams, Page, PermissionKeys, RemoveModal, useHasPermission } from '../../../shared';
import { Calendar, PatternDialog, PlanningEntryDialog, UpdateEntryWithPatternWarning } from '../../components';
import { useDeletePlanning, useDeletePlanningEntry, usePlanning } from '../../hooks';
import { IPlanningEntry } from '../../models';
import { FeadProductsInfo } from '../../components/fead-products-info/fead-products-info.component';
import { startOfWeek } from 'date-fns';

export const PlanningDetailPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.PLANNINGS_WRITE);

    const [entryDialog, setEntryDialog] = useState<{ show: boolean; date?: string; entry?: IPlanningEntry }>({
        show: false,
    });
    const [showPatternDialog, setShowPatternDialog] = useState(false);
    const [deletingEntryWithPattern, setDeletingEntryWithPattern] = useState<IPlanningEntry | undefined>(undefined);
    const [startOfCalendar, setStartOfCalendar] = useState<Date>(() => startOfWeek(new Date(), { weekStartsOn: 1 }));

    const { mutateAsync: deletePlanningEntry } = useDeletePlanningEntry();
    const { data: planning, isFetching } = usePlanning(id);
    const { mutateAsync: deletePlanning, isPending: isPendingDelete } = useDeletePlanning();

    const onDelete = useCallback(async () => {
        await deletePlanning(id);
        navigate('/planning/plannings');
    }, [deletePlanning, id, navigate]);

    const onDeletePlanningEntry = async (entry: IPlanningEntry) => {
        if (planning) {
            if (entry.patternEntry) {
                setDeletingEntryWithPattern(entry);
            } else {
                await deletePlanningEntry({ planning: planning.id, entry: entry.id, includeFutureEntries: false });
            }
        }
    };

    const onDeletePlanningEntryWithPattern = async (includeFutureEntries: boolean) => {
        if (planning && deletingEntryWithPattern) {
            await deletePlanningEntry({
                planning: planning.id,
                entry: deletingEntryWithPattern.id,
                includeFutureEntries,
            });
            setDeletingEntryWithPattern(undefined);
        }
    };

    return (
        <Page
            title={t(`planningDetails`, { organisation: planning?.organisation?.name || '' })}
            onBack={() => navigate('/planning/plannings')}
            actions={
                hasWritePermission
                    ? [
                          <RemoveModal
                              title={t('removePlanning')}
                              text={t('removePlanningWarning')}
                              button={<Button>{t('removePlanning')}</Button>}
                              handleDelete={onDelete}
                          />,
                          <Button
                              onClick={() => setShowPatternDialog(true)}
                              color="primary"
                              variant="outlined"
                              disabled={!planning}
                          >
                              {t('newPattern')}
                          </Button>,
                          <Button
                              onClick={() => setEntryDialog({ show: true, date: undefined, entry: undefined })}
                              color="primary"
                              variant="contained"
                              disabled={!planning}
                          >
                              {t('newPlanningEntry')}
                          </Button>,
                      ]
                    : []
            }
            loading={isFetching || isPendingDelete}
        >
            <FeadProductsInfo organisationId={planning?.organisation.id} />
            <div>
                <Calendar
                    planningId={id}
                    addPlanningEntry={(date) => setEntryDialog({ show: true, date: date, entry: undefined })}
                    editPlanningEntry={(entry) => setEntryDialog({ show: true, entry, date: undefined })}
                    deletePlanningEntry={onDeletePlanningEntry}
                    date={startOfCalendar}
                    setDate={setStartOfCalendar}
                />
            </div>
            {planning && (
                <PlanningEntryDialog
                    open={entryDialog.show}
                    date={entryDialog.date}
                    entry={entryDialog.entry}
                    planning={planning}
                    handleClose={() => setEntryDialog({ show: false, date: undefined, entry: undefined })}
                />
            )}

            <PatternDialog open={showPatternDialog} handleClose={() => setShowPatternDialog(false)} />

            <UpdateEntryWithPatternWarning
                open={!!deletingEntryWithPattern}
                onSubmit={onDeletePlanningEntryWithPattern}
                onCancel={() => setDeletingEntryWithPattern(undefined)}
            />
        </Page>
    );
};
