export const feadTranslationsNL = {
    fead: 'fead',
    feadDeliveries: 'Leveringen',
    feadDelivery: 'Levering',
    newFeadDelivery: 'Nieuwe levering',
    addFeadDeliveryEntry: '+ Organisatie toevoegen',
    plannedAmount: 'Gepland aantal',
    deliveredAmount: 'Geleverd aantal',
    startSearchingProducts: 'Type om een product te zoeken...',
    noProductsFound: 'Geen producten gevonden',
    feadDeliveryDeleteWarningTitle: 'Levering verwijderen?',
    feadDeliveryDeleteWarningText: 'Ben je zeker dat je deze levering wilt verwijderen?',
    feadDeliveryInUseTitle: 'Levering in gebruik',
    feadDeliveryInUseDescription: 'Deze levering is reeds in gebruik door een uit stock beweging.',
    editFeadDeliveryInUseConfirmQuestion: 'Ben je zeker dat je deze fead levering wil aanpassen?',
    reservationNotRequired: 'Reservering niet verplicht',
    productRequiresReservationFalse: 'Dit product heeft reservering verplicht niet aangevinkt',
    notDelivered: 'Nog niet geleverd',
};
