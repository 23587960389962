import { DownloadOutlined } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    code: string;
    name: string;
    variant?: 'icon' | 'default';
}

export const DownloadCard: FC<Props> = ({ code, name, variant = 'default' }) => {
    const { t } = useTranslation();
    const href = `/logistics-api/cards/${code}?name=${name}`;

    return variant === 'icon' ? (
        <IconButton href={href} target="_blank" onClick={(e) => e.stopPropagation()}>
            <DownloadOutlined />
        </IconButton>
    ) : (
        <Button color="secondary" href={href} target="_blank" onClick={(e) => e.stopPropagation()}>
            {t('download')}
        </Button>
    );
};
