export const adminTranslationsEN = {
    admin: 'Admin',
    parties: 'Parties',

    devices: 'Devices',
    newDevice: 'New device',
    deviceDeleteWarningTitle: 'Remove device?',
    deviceDeleteWarningText: 'Are you sure you want to remove this device?',

    enrollmentTitle: 'Enroll device',
    enrollmentStartSummary:
        "You're about to enroll a new device. In The next step you'll receive a public key. You can use that key to finish the enrollment on the device.",
    enrollmentCompleteSummary: 'Use following device code and public key to finish the enrollment on the device.',
    startEnrollment: 'Start enrollment',
    enrollmentDeviceCode: 'Device code',
    enrollmentPublicKey: 'Public key',
    enrollmentAlreadyCompleted:
        'This device has already been enrolled. If you continue, you will have to enroll the device again.',

    organisations: 'Organisations',
    organisation: 'Organisation',
    newOrganisation: 'New organisation',
    organisationDeleteWarningTitle: 'Remove organisation?',
    organisationDeleteWarningText: 'Are you sure you want to remove this organisation?',
    features: 'Features',
    usesCaritySupport: 'Uses carity support',
    usesCarityLogistics: 'Uses carity logistics',
    contactInformation: 'Contact information',
    contact: 'Contact',
    contactsAdd: '+ Add contact',
    EMAIL: 'Email',
    PHONE: 'Phone',

    operators: 'Operators',
    operator: 'Operator',
    newOperator: 'New operator',
    operatorDeleteWarningTitle: 'Remove operator?',
    operatorDeleteWarningText: 'Are you sure you want to remove this operator?',

    suppliers: 'Suppliers',
    supplier: 'Supplier',
    newSupplier: 'New supplier',
    supplierDeleteWarningTitle: 'Remove supplier?',
    supplierDeleteWarningText: 'Are you sure you want to remove this supplier?',

    products: 'Products',
    product: 'Product',
    newProduct: 'New product',
    productDeleteWarningTitle: 'Remove product?',
    productDeleteWarningText: 'Are you sure you want to remove this product?',
    measurementUnit: 'Unit',
    weight: 'Weight per unit (gr.)',
    content: 'Content',
    brandName: 'Brand name',
    regulatedName: 'Regulated name',
    ingredients: 'Ingredients',
    nutritionalInformation: 'Nutritional information',
    dietInformation: 'Diet information',
    dietInformationEntry: 'Diet',
    addDietInformation: '+ Add diet',
    allergenInformation: 'Allergen information',
    levelOfContainment: 'Level',
    allergenType: 'Allergy type',
    addAllergenInformation: '+ Add allergen information',
    links: 'Links',
    linksEntry: 'Link',
    addLink: '+ Add link',
    noCategoriesFound: 'No categories found',
    startSearchingCategories: 'Start typing to search a category...',
    GRAM: 'Gram',
    PIECE: 'Piece',
    '30': '30',
    '50': '50',
    '60': '60',
    CONTAINS: 'CONTAINS',
    FREE_FROM: 'FREE_FROM',
    MAY_CONTAIN: 'MAY_CONTAIN',
    UNDECLARED: 'UNDECLARED',

    draftProducts: 'Draft products',
    draft: 'Draft product',

    productCategories: 'Categories',
    newProductCategory: 'New category',
    productCategoryDeleteWarningTitle: 'Remove category?',
    productCategoryDeleteWarningText: 'Are you sure you want to remove this category?',

    stockLocations: 'Stock locations',
    stockLocation: 'Stock location',
    newStockLocation: 'New stock location',
    stockLocationDeleteWarningTitle: 'Remove stock location?',
    stockLocationDeleteWarningText: 'Are you sure you want to remove this stock location?',

    qrCodes: 'Qr code',
    newQrCode: 'New qr code',
    qrCodeDeleteWarningTitle: 'Remove qr code?',
    qrCodeDeleteWarningText: 'Are you sure you want to remove this qr code?',

    BATCH: 'Batch',

    settings: 'Settings',
    shipmentMailBody: 'Shipment mail body',
    shipmentMailSubject: 'Shipment mail subject',
    productHasStockMovements: 'This product cannot be removed because it is used in stock movements.',
};
