import { IOrganisation, IOrganisationQueryDto, ISaveOrganisation } from '../models';
import { BaseClient } from './base.client';
import { logisticsApi } from './logistics-api.client';

class OrganisationsClient extends BaseClient<IOrganisation, ISaveOrganisation, IOrganisationQueryDto> {
    constructor() {
        super(logisticsApi, '/organisations');
    }
}

export const organisationsClient = new OrganisationsClient();
