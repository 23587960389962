import { Box } from '@mui/material';
import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { ErrorFallback, ProtectedRoute, SideBar, SideNav } from '../../../shared';
import { adminSubRoutes } from '../../admin.routes';
import { useProductsList } from '../../hooks';

export const AdminPage: FC = () => {
    const { t } = useTranslation();
    const { data } = useProductsList({ isDraft: true });
    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav
                    items={[
                        { text: t('products'), key: 'products' },
                        { path: '/admin/products', text: t('products') },
                        {
                            path: '/admin/draft-products',
                            text: `${t('draftProducts')} ${data?.pagination?.size ? `(${data.pagination.size})` : ''}`,
                        },
                        { path: '/admin/blacklisted-products', text: t('blacklistedProducts') },
                        { path: '/admin/product-categories', text: t('productCategories') },
                        { text: t('parties'), key: 'parties' },
                        { path: '/admin/organisations', text: t('organisations') },
                        { path: '/admin/organisation-types', text: t('organisationTypes') },
                        { path: '/admin/suppliers', text: t('suppliers') },
                        { path: '/admin/stock-locations', text: t('stockLocations') },
                        { text: t('admin'), key: 'admin' },
                        { path: '/admin/operators', text: t('operators') },
                        { path: '/admin/devices', text: t('devices') },
                        { path: '/admin/qr-codes', text: t('qrCodes') },
                        { path: '/admin/settings', text: t('settings') },
                    ]}
                />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {adminSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ProtectedRoute requiredPermission={subRoute.requiredPermissions}>
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        {<subRoute.component />}
                                    </ErrorBoundary>
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};
