import { IProductCategoryForm, IProductCategory, ISaveProductCategory } from '../models';

export const productCategoryToFormMapper = (item: IProductCategory): IProductCategoryForm => {
    return { ...item, priority: item.priority ? item.priority : '' };
};

export const productCategoryFromFormMapper = (item: IProductCategoryForm): ISaveProductCategory => {
    return {
        ...item,
        averagePricePerKg: item.averagePricePerKg == '' ? 0 : Number(item.averagePricePerKg),
        priority: item.priority == '' ? null : Number(item.priority),
    };
};
