import { Delete } from '@mui/icons-material';
import { Button, IconButton, MenuItem, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledNumberInput,
    ControlledSelect,
    IFeadDelivery,
    IOrganisation,
    MeasurementUnit,
    useFeadDeliveryProduct,
    useOrganisationsList,
} from '../../../shared';
import { FeadDeliveryEntryDetail } from './fead-delivery-entry-detail.component';

interface Props {
    delivery: IFeadDelivery | undefined;
}

export const FeadDeliveryEntryInputs: FC<Props> = ({ delivery }) => {
    const { t } = useTranslation();
    const entries = useFieldArray({ name: 'entries' });

    const { data: organisations } = useOrganisationsList({ pageSize: 100 });
    const watchProduct = useWatch({ name: 'product' });
    const { data: productInfo } = useFeadDeliveryProduct(watchProduct?.id);

    const isGram = watchProduct?.measurementUnit === MeasurementUnit.GRAM;

    return (
        <Stack spacing={3} alignItems="start">
            {entries.fields.map((field, index) => (
                <Stack key={field.id} direction="row" spacing={2}>
                    <ControlledSelect name={`entries[${index}].organisationId`} label={t('organisation')} required>
                        <MenuItem value="" sx={{ p: 2 }} />
                        {(organisations?.data || []).map((organisation: IOrganisation) => (
                            <MenuItem value={organisation.id} key={organisation.id}>
                                {organisation.name}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                    <ControlledNumberInput
                        name={`entries[${index}].plannedAmount`}
                        label={`${t('plannedAmount')} ${isGram ? t('gram') : t('piece')}`}
                        decimalScale={0}
                        required
                    />
                    <ControlledNumberInput
                        name={`entries[${index}].deliveredAmount`}
                        label={`${t('deliveredAmount')} ${isGram ? t('gram') : t('piece')}`}
                        decimalScale={0}
                    />
                    <FeadDeliveryEntryDetail
                        productsInfo={productInfo || []}
                        path={`entries[${index}].organisationId`}
                        delivery={delivery}
                    />
                    <IconButton onClick={() => entries.remove(index)}>
                        <Delete />
                    </IconButton>
                </Stack>
            ))}

            <Button
                size="small"
                onClick={() =>
                    entries.append({
                        organisationId: '',
                        plannedAmount: null,
                    })
                }
            >
                {t('addFeadDeliveryEntry')}
            </Button>
        </Stack>
    );
};
