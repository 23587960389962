import { FC } from 'react';
import { Login, Logout } from '../../../auth';
import {
    DrawerContextProvider,
    Loading,
    PermissionsContext,
    useGetCurrentUser,
    useGetUserRoles,
} from '../../../shared';
import { Header } from '../header/header.component';
import { Routes } from '../routes/routes.component';
import { useLocation } from 'react-router-dom';

export const Main: FC = () => {
    const { data, isPending } = useGetCurrentUser();
    const { data: roles } = useGetUserRoles(data?.data?.id);

    const location = useLocation();

    if (isPending) {
        return <Loading fullscreen />;
    }

    if (location?.pathname === '/logout') {
        return <Logout />;
    } else if (!data?.success) {
        return <Login />;
    }

    return (
        <>
            <PermissionsContext.Provider value={{ permissions: roles?.items }}>
                <DrawerContextProvider>
                    <Header />
                    <Routes />
                </DrawerContextProvider>
            </PermissionsContext.Provider>
        </>
    );
};
