import { Warning } from '@mui/icons-material';
import React, { SVGProps } from 'react';
import { LabelProps } from 'recharts';

export const renderWarningLabel = ({ x, width, value }: Omit<SVGProps<SVGTextElement>, 'viewBox'> & LabelProps) => {
    return (
        value && (
            <g>
                <foreignObject
                    x={(x as number) - 10 + (width as number) / 2}
                    y={97}
                    width={20}
                    height={20}
                    textAnchor="middle"
                >
                    <Warning sx={{ color: 'orange' }} fontSize="small" />
                </foreignObject>
            </g>
        )
    );
};
