import { mapDateToApiDate } from '../../shared';
import { IBlacklistedProduct, IBlacklistedProductForm, ISaveBlacklistedProduct } from '../models';

export const blacklistedProductToFormMapper = (item: IBlacklistedProduct): IBlacklistedProductForm => {
    return {
        ...item,
        organisationId: item?.organisationId || undefined,
        categoryId: item?.category?.id || undefined,
        partialProductName: item?.partialProductName || undefined,
        productCode: item?.productCode || undefined,
        startDate: item?.startDate ? new Date(item?.startDate) : undefined,
        endDate: item?.endDate ? new Date(item?.endDate) : undefined,
        blockByProductCode: !!item?.productCode,
    };
};

export const blacklistedProductFromFormMapper = (item: IBlacklistedProductForm): ISaveBlacklistedProduct => {
    return {
        ...item,
        organisationId: item?.blockForAllOrganisations ? undefined : item?.organisationId,
        categoryId: item?.blockByProductCode ? null : item?.categoryId,
        partialProductName: item?.blockByProductCode ? null : item?.partialProductName,
        productCode: item?.blockByProductCode ? item?.productCode : null,
        startDate: item?.isPermanent ? null : mapDateToApiDate(item?.startDate),
        endDate: item?.isPermanent ? null : mapDateToApiDate(item?.endDate),
    };
};
