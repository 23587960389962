import { useMemo } from 'react';

export const useEntityMap = (
    entities: { data: { id: string; name: string }[] } | undefined,
): Record<string, string> => {
    return useMemo(
        () =>
            (entities?.data || []).reduce(
                (record, entity) => {
                    record[entity.id] = entity.name;
                    return record;
                },
                {} as Record<string, string>,
            ),
        [entities],
    );
};
