import { AxiosError } from 'axios';

export function isAxiosError(err: unknown): err is AxiosError {
    return (err as AxiosError).isAxiosError;
}

export function isBadRequest(err: unknown): boolean {
    return isAxiosError(err) && err.response?.status === 400;
}

export function isConflictException(err: unknown): boolean {
    return isAxiosError(err) && err.response?.status === 409;
}
