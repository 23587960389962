import { MenuItem } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledSelect, LevelOfContainmentCode } from '../../../shared';

interface Props {
    name: string;
}

export const ContainmentLevelPicker: FC<Props> = ({ name }) => {
    const { t } = useTranslation();

    return (
        <ControlledSelect name={name} label={t('levelOfContainment')}>
            {Object.values(LevelOfContainmentCode).map((level) => (
                <MenuItem value={level} key={level}>
                    {t(level)}
                </MenuItem>
            ))}
        </ControlledSelect>
    );
};
