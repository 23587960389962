import { Filter } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { addDays, subDays } from 'date-fns';
import { useMemo } from 'react';
import { useAllCategories } from '../../planning/hooks';
import { cubejsApi } from '../clients';
import { IFilter } from '../models';
import { useFilterGenerator } from '../utils';
import { mapDateToApiDate } from '../../shared';

export const usePlannedWeightPerCategoryReport = (filter: IFilter, differentiateOrganisations = false) => {
    const categories = useAllCategories();
    const categoryIds = useMemo(() => categories.map(({ id }) => id), [categories]);

    return useCubeQuery(
        {
            measures: ['Requirement.amount'],
            order: {
                'ProductCategory.name': 'asc',
            },
            filters: [
                {
                    member: 'Requirement.patternentryid',
                    operator: 'notSet',
                },
                ...(filter.startDate
                    ? [
                          {
                              member: 'PlanningEntry.date',
                              operator: 'afterDate',
                              values: [mapDateToApiDate(subDays(new Date(filter.startDate), 1))],
                          } as Filter,
                      ]
                    : []),
                ...(filter.endDate
                    ? [
                          {
                              member: 'PlanningEntry.date',
                              operator: 'beforeDate',
                              values: [mapDateToApiDate(addDays(new Date(filter.endDate), 1))],
                          } as Filter,
                      ]
                    : []),
                ...useFilterGenerator(filter.productCategories || [...categoryIds, ''], 'ProductCategory.id'),
                ...useFilterGenerator(filter.organisations || [], 'Planning.organisation'),
            ],
            dimensions: [
                ...(filter.showTotalCategories ? [] : ['ProductCategory.name']),
                ...(differentiateOrganisations ? ['Planning.organisation'] : []),
            ],
            timeDimensions: [
                {
                    dimension: 'PlanningEntry.date',
                    granularity: filter.grouping || undefined,
                },
            ],
        },
        { cubejsApi, skip: categories.length === 0 },
    );
};
