import { Checkbox, ListItemText, MenuItem } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledSelect, useOrganisationsList } from '../../../shared';
import { Report } from '../../enums/report.enum';
import { IFilter } from '../../models';

export const ReportFilterOrganisationsSelect: FC<{
    report: Report;
    form: UseFormReturn<IFilter>;
    filter: Partial<IFilter>;
    onChange: (filter: IFilter) => void;
}> = ({ filter, report, form, onChange }) => {
    const { t } = useTranslation();
    const { data: organisations } = useOrganisationsList({ pageSize: 200 });

    useEffect(() => {
        if (organisations) {
            const organisationValues =
                report === Report.REDISTRIBUTED
                    ? organisations?.data?.map(({ id }) => id)
                    : [...organisations?.data?.map(({ id }) => id), ''];
            form.setValue('organisations', organisationValues);
            onChange({ ...filter, organisations: organisationValues });
        }
    }, [organisations, form, report]); // eslint-disable-line react-hooks/exhaustive-deps

    const organisationItems = useMemo(() => {
        if (report !== Report.REDISTRIBUTED) {
            return organisations?.data ? [...organisations?.data?.map(({ id }) => id), ''] : [''];
        }
        return organisations?.data?.map(({ id }) => id) || [];
    }, [organisations, report]);

    return (
        <ControlledSelect
            name="organisations"
            label={t('organisation')}
            multiple
            renderValue={(selected) =>
                (selected as string[])?.length ==
                (organisations?.pagination.size || 0) + (report === Report.REDISTRIBUTED ? 0 : 1)
                    ? t('all')
                    : (selected as string[])
                          .map((value) => organisations?.data?.find((item) => item.id == value))
                          .map((item) => (item?.name ? item.name : t('unknown')))
                          .join(', ')
            }
            items={organisationItems}
            size="small"
        >
            <MenuItem value="all">
                <Checkbox
                    checked={
                        filter?.organisations?.length ===
                        (organisations?.pagination?.size || 0) + (report === Report.REDISTRIBUTED ? 0 : 1)
                    }
                />
                <ListItemText primary={t('all')} />
            </MenuItem>
            {organisations?.data?.map((organisation) => (
                <MenuItem value={organisation.id} key={organisation.id}>
                    <Checkbox checked={filter?.organisations?.includes(organisation.id)} />
                    <ListItemText primary={organisation.name} />
                </MenuItem>
            ))}
            {report !== Report.REDISTRIBUTED && (
                <MenuItem value="">
                    <Checkbox checked={filter?.organisations?.includes('')} />
                    <ListItemText primary={t('unknown')} />
                </MenuItem>
            )}
        </ControlledSelect>
    );
};
