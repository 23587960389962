export const reportsTranslationsNL = {
    reports: 'Rapporten',
    reportFoodDistributionRedistributed: 'Geleverd aan organisaties',
    reportFoodDistributionSupplied: 'Verkregen door leveranciers',
    foodDistributionRedistributed: 'Organisaties',
    foodDistributionSupplied: 'Leveranciers',
    foodDistribution: 'Voedseldistributie',
    unknown: 'Onbekend',
    report: {
        productcategory: {
            name: 'Category',
        },
        stockmovement: {
            totalweight: 'TotaalGewicht (kg)',
        },
        foodDistribution: 'Rapport-voedselverdeling',
    },
    barChart: 'Staafdiagram',
    categories: 'Categoriën',
    pieChart: 'Taartdiagram',
    totalWeightKg: 'Totaal gewicht (kg)',
    quarter: 'Kwartaal',
    year: 'Jaar',
    none: 'Geen',
    grouping: 'Groepering',
    refresh: 'Vernieuw',
    more: 'meer',
    showPlanned: 'Toon gepland',
    planned: 'Gepland',
    month: 'Maand',
    showTotalCategories: 'Toon totaal categoriën',
    allCategories: 'Alle categoriën',
    totalPrice: 'Totale prijs',
    export: 'Export',
};
