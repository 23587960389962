import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Route, Routes as RRRoutes } from 'react-router-dom';
import { adminRoutes } from '../../../admin';
import { feadRoutes } from '../../../fead';
import { planningRoutes } from '../../../planning';
import { reportsRoutes } from '../../../reports';
import { ErrorFallback, IRouteConfig, IRouteConfigWithRedirect, ProtectedRoute } from '../../../shared';
import { stockRoutes } from '../../../stock';
import { PageNotFound } from '../../pages';

export const ROUTES: IRouteConfig[] = [
    { path: '/', redirect: '/planning' },
    ...stockRoutes,
    ...planningRoutes,
    ...adminRoutes,
    ...reportsRoutes,
    ...feadRoutes,
];

function isRedirect(config: IRouteConfig): config is IRouteConfigWithRedirect {
    return !!(config as IRouteConfigWithRedirect).redirect;
}

export function Routes() {
    return (
        <RRRoutes>
            {ROUTES.map((config, i) =>
                isRedirect(config) ? (
                    <Route path={config.path} key={i} element={<Navigate to={config.redirect} />} />
                ) : (
                    <Route
                        key={i}
                        path={config.path}
                        element={
                            <ProtectedRoute requiredPermission={config.requiredPermissions}>
                                <ErrorBoundary FallbackComponent={ErrorFallback}>{<config.component />}</ErrorBoundary>
                            </ProtectedRoute>
                        }
                    />
                ),
            )}
            <Route element={<PageNotFound />} />
        </RRRoutes>
    );
}
