import { QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { shipmentsClient } from '../clients';
import { IShipment, IShipmentDashboard, IShipmentDashboardQuery, IShipmentQuery } from '../models';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { stockMovementsClient } from '../../stock/clients';

const client = shipmentsClient;
const listKey = QueryKeys.Shipments;
const itemKey = QueryKeys.Shipment;

export function useShipmentsList(...args: UseListParams<IShipment, IShipmentQuery>) {
    return useList(client, listKey, ...args);
}

export function useShipment(...args: UseItemParams<IShipment>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveShipment() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteShipment() {
    return useDelete(client, listKey);
}

export function useCompleteShipments(): UseMutationResult<
    void,
    AxiosError,
    { shipments: string[]; sendMails: boolean }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (item) => shipmentsClient.completeShipments(item.shipments, item.sendMails),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [QueryKeys.Shipment] }),
    });
}

export function useShipmentDashboard(
    query: IShipmentDashboardQuery,
    interval: number,
): UseQueryResult<IShipmentDashboard, AxiosError> {
    return useQuery<IShipmentDashboard, AxiosError>({
        queryKey: [QueryKeys.Dashboard, query],
        queryFn: () => shipmentsClient.getShipmentDashboard(query),
        refetchInterval: interval,
    });
}

export function useNotifyShipment(): UseMutationResult<void, AxiosError, string> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id) => shipmentsClient.notify(id),
        onSettled: async () => await queryClient.invalidateQueries({ queryKey: [QueryKeys.Shipment] }),
    });
}

export function useStockMovementDeallocateShipment(): UseMutationResult<
    void,
    AxiosError,
    { stockMovement: string; shipment: string }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ stockMovement }) => stockMovementsClient.deallocateFromShipment(stockMovement),
        onSuccess: async (r, { shipment }) =>
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Shipment, shipment] }),
    });
}
