import { AccountCircle, Logout, Person } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar,
    Box,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Tab,
    Tabs,
    Theme,
    Toolbar,
    Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useGetCurrentUser, useShowDrawer } from '../../../shared';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            borderBottom: `solid 1px ${theme.palette.grey['300']}`,
            backgroundColor: 'white',
            color: theme.palette.primary.main,
        },
        toolBar: {
            height: 'auto',
            minHeight: 55,
            '& .MuiTab-root': {
                minHeight: 55,
            },
        },
        spacer: {
            height: 55,
        },
        logo: {
            marginRight: 12,
            height: 40,
        },
        appTitle: {
            alignSelf: 'stretch',
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            width: 216,
            boxSizing: 'border-box',
            textTransform: 'uppercase',
            borderRight: `solid 1px ${theme.palette.grey['300']}`,
        },
    }),
);

export function Header() {
    const location = useLocation();
    const selectedTab = useMemo(() => location.pathname.split('/')[1] || 'planning', [location.pathname]);
    const classes = useStyles();
    const { t } = useTranslation();
    const { data } = useGetCurrentUser();
    const user = data?.data;
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const { showDrawer, setShowDrawer } = useShowDrawer();
    const handleDrawerToggle = () => {
        setShowDrawer(!showDrawer);
    };

    const onLogOut = useCallback(() => {
        window.location.href = '/auth/logout';
    }, []);

    return (
        <>
            <div className={classes.spacer} />
            <AppBar className={classes.appBar} color="default" elevation={0}>
                <Toolbar className={classes.toolBar}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { xl: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Box component="div" sx={{ display: { xs: 'none', xl: 'block' } }}>
                        <Typography component="div" className={classes.appTitle}>
                            <img className={classes.logo} src={window.carity.environment.theme.logo} alt="Carity" />
                            carity
                            <strong>logistics</strong>
                        </Typography>
                    </Box>
                    <Tabs indicatorColor="primary" textColor="primary" sx={{ flexGrow: 1 }} value={selectedTab}>
                        <Tab label={t('planning')} value="planning" component={Link} to="/planning" />
                        <Tab label={t('stock')} value="stock" component={Link} to="/stock" />
                        <Tab label={t('admin')} value="admin" component={Link} to="/admin" />
                        <Tab label={t('fead')} value="fead" component={Link} to="/fead" />
                        <Tab label={t('reports')} value={'reports'} component={Link} to={'/reports'} />
                    </Tabs>
                    <IconButton size="large" edge="end" color="inherit" onClick={(e) => setAnchorEl(e.currentTarget)}>
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        sx={{ mt: 5 }}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(undefined)}
                    >
                        <MenuItem>
                            <ListItemIcon>
                                <Person />
                            </ListItemIcon>
                            {user?.firstName} {user?.lastName}
                        </MenuItem>
                        <MenuItem onClick={onLogOut}>
                            <ListItemIcon>
                                <Logout />
                            </ListItemIcon>
                            {t('logOut')}
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </>
    );
}
