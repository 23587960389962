import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ControlledInput, FormCard, FormGrid, IParams, Page, Section } from '../../../shared';
import { DownloadCard } from '../../components';
import { useProductCategory, useSaveProductCategory } from '../../hooks';
import { IProductCategoryForm } from '../../models';
import { useProductCategorySchema } from '../../validators';
import { productCategoryFromFormMapper, productCategoryToFormMapper } from '../../mappers';

export const ProductCategoryEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: productCategory, isFetching } = useProductCategory(id);

    const form = useForm<IProductCategoryForm>({
        resolver: yupResolver(useProductCategorySchema()),
        mode: 'all',
    });
    const { mutateAsync: saveProductCategory, isPending: isPendingSave } = useSaveProductCategory();

    useEffect(() => {
        if (productCategory) {
            form.reset(productCategoryToFormMapper(productCategory));
        }
    }, [productCategory, form]);

    const onSubmit = useCallback(
        async (item: IProductCategoryForm) => {
            try {
                await saveProductCategory({
                    id,
                    item: productCategoryFromFormMapper(item),
                });
                navigate('/admin/product-categories');
            } catch (e) {
                form.setError('abbreviation', { type: 'custom', message: t('abbreviationUniqueError') });
            }
        },
        [form, id, navigate, saveProductCategory, t],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            productCategory ? <DownloadCard name={productCategory.name} code={productCategory.code} /> : <></>,
            <Button onClick={() => navigate('/admin/product-categories')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, productCategory, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={productCategory?.name || t('newProductCategory')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isPendingSave}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Section title={t('data')}>
                        <FormGrid>
                            <ControlledInput name="name" label={t('name')} required={true} />
                            <ControlledInput name="abbreviation" label={t('abbreviation')} required={true} />
                            {id && <ControlledInput name="code" label={t('code')} disabled />}
                        </FormGrid>
                    </Section>
                    <Section title={t('additionalData')}>
                        <FormGrid>
                            <ControlledInput name="averagePricePerKg" label={t('averagePricePerKg')} type="number" />
                            <ControlledInput name="priority" label={t('priority')} type="number" />
                        </FormGrid>
                    </Section>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
