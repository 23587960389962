import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
    label: string;
    children?: ReactNode;
}

export const Definition: FC<Props> = ({ label, children }) => {
    return (
        <Box>
            <Typography variant="overline" display="block" fontWeight="bold" color="text.secondary">
                {label}
            </Typography>
            <Typography display="block">{children || '-'}</Typography>
        </Box>
    );
};
