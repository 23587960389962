import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    open: boolean;
    onSubmit: (updateFutureEntries: boolean) => void;
    onCancel: () => void;
}

export const UpdateEntryWithPatternWarning: FC<Props> = ({ open, onSubmit, onCancel }) => {
    const { t } = useTranslation();
    return (
        <Dialog open={open}>
            <DialogTitle>{t('changeRepeatingEventTitle')}</DialogTitle>
            <DialogContent>{t('changeRepeatingEventContent')}</DialogContent>
            <DialogActions>
                <Button onClick={() => onSubmit(false)} variant="contained">
                    {t('onlyThisEvent')}
                </Button>
                <Button onClick={() => onSubmit(true)} variant="outlined">
                    {t('allEvents')}
                </Button>
                <Button onClick={onCancel}>{t('cancel')}</Button>
            </DialogActions>
        </Dialog>
    );
};
