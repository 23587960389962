import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledAutocomplete, FormGrid, Section, useDebouncedState } from '../../../shared';
import { useProductCategoriesList } from '../../hooks';
import { IProductCategory } from '../../models';

export const ProductCategories: FC = () => {
    const { t } = useTranslation();
    const [debouncedSearch, , setSearch] = useDebouncedState('');
    const { data: categories, isPending } = useProductCategoriesList({
        search: debouncedSearch,
    });

    return (
        <Section title={t('productCategory')}>
            <FormGrid>
                <ControlledAutocomplete<IProductCategory, true>
                    name="category"
                    minWidth={500}
                    label={t('productCategory')}
                    defaultValue={null}
                    loading={isPending}
                    options={debouncedSearch ? categories?.data || [] : []}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(category) => category.name}
                    noOptionsText={debouncedSearch ? t('noCategoriesFound') : t('startSearchingCategories')}
                    onInputChange={(e, value) => setSearch(value)}
                />
            </FormGrid>
        </Section>
    );
};
