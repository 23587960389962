import { format, isValid } from 'date-fns';

export function mapDateToApiDate(date: Date | undefined | null): string | null {
    return date && isValid(date) && !format(date, 'yyyy-MM-dd').startsWith('0') ? format(date, 'yyyy-MM-dd') : null;
}
export function mapDateToRequiredApiDate(date: Date): string {
    return format(date, 'yyyy-MM-dd');
}
export function mapDateStringToApiDate(date: string | undefined | null): string | null {
    try {
        return date ? format(new Date(date), 'yyyy-MM-dd') : null;
    } catch (e) {
        return null;
    }
}
