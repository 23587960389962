import { adminTranslationsNL } from './admin.nl';
import { authTranslationsNL } from './auth.nl';
import { commonTranslationsNL } from './common.nl';
import { feadTranslationsNL } from './fead.nl';
import { planningTranslationsNL } from './planning.nl';
import { reportsTranslationsNL } from './reports.nl';
import { stockTranslationsNL } from './stock.nl';
import { validationTranslationsNL } from './validation.nl';
import { dashboardTranslationsNL } from './dashboard.nl';

export const translationsNL = {
    translation: {
        ...adminTranslationsNL,
        ...authTranslationsNL,
        ...commonTranslationsNL,
        ...planningTranslationsNL,
        ...stockTranslationsNL,
        ...validationTranslationsNL,
        ...reportsTranslationsNL,
        ...dashboardTranslationsNL,
        ...feadTranslationsNL,
    },
};
