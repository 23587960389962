import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';
import { translationsEN } from './en';
import { translationsNL } from './nl';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'nl',
        lng: 'nl',
        resources: {
            en: translationsEN,
            nl: translationsNL,
        },
    });

setLocale({
    mixed: {
        required: i18n.t('required'),
    },
});

export default i18n;
