export const stockTranslationsEN = {
    stock: 'Stock',
    stockProducts: 'Current stock',
    stockCounts: 'Total in stock',

    IN_STOCK: 'Stock in',
    OUT_STOCK: 'Stock out',
    DISTRIBUTION: 'Distribution',

    stockMovements: 'Stock movements',
    newStockMovement: 'New stock movement',
    editStockMovement: 'Edit stock movement',
    stockMovementDeleteWarningTitle: 'Remove stock movement?',
    stockMovementDeleteWarningText: 'Are you sure you want to remove this stock movement?',
    amount: 'Amount',
    productCode: 'Product code',
    createdDate: 'Date',
    stockMovementInUseTitle: 'Stock movement in use',
    stockMovementInUseDescription:
        "This stock movement is already in use for other stock movements, it's no longer possible to change or delete it.",
};
