import { useTranslation } from 'react-i18next';
import { array, boolean, date, mixed, number, object, ref, string } from 'yup';
import { formatDateString, IProduct } from '../../shared';

export const usePlanningPatternEntrySchema = () => {
    const { t } = useTranslation();

    const requirements = object().shape({
        categoryId: string()
            .nullable()
            .when('isCategory', { is: true, then: (schema) => schema.required() }),
        product: mixed<IProduct>()
            .nullable()
            .when('isCategory', { is: false, then: (schema) => schema.required() }),
        isCategory: boolean().required(),
        amount: number()
            .typeError(t('invalidNumber'))
            .when('product', {
                is: (value: IProduct) => value?.requiresReservation,
                then: (schema) => schema.optional(),
                otherwise: (schema) => schema.min(0).required(),
            }),
    });

    const weekdayEntry = object().shape({
        isAppointmentOnly: boolean().required(),
        requirements: array().of(requirements).required(),
    });

    return object().shape({
        startDate: date().typeError(t('invalidDate')).required(),
        endDate: date()
            .typeError(t('invalidDate'))
            .required()
            .min(
                ref('startDate'),
                ({ min }) =>
                    `${t('dateMustBeAfter', {
                        date: formatDateString(min?.toString()),
                        interpolation: { escapeValue: false },
                    })}`,
            ),
        weeks: array()
            .of(
                object().shape({
                    monday: weekdayEntry,
                    tuesday: weekdayEntry,
                    wednesday: weekdayEntry,
                    thursday: weekdayEntry,
                    friday: weekdayEntry,
                    saturday: weekdayEntry,
                    sunday: weekdayEntry,
                }),
            )
            .required(),
        feadProductsPattern: boolean().required(),
    });
};
