import { IFeadDelivery, IFormFeadDelivery, ISaveFeadDelivery, mapDateToRequiredApiDate } from '../../shared';

export const feadDeliveryToFormMapper = (delivery: IFeadDelivery): IFormFeadDelivery => {
    return { ...delivery, date: new Date(delivery.date), stockLocationId: delivery.stockLocation?.id };
};

export const feadDeliveryFromFormMapper = (item: IFormFeadDelivery): ISaveFeadDelivery => {
    return {
        ...item,
        productId: item.product.id,
        entries: item.entries || [],
        stockLocationId: item.stockLocationId || null,
        date: mapDateToRequiredApiDate(item.date),
    };
};
