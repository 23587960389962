import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FC, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useProductCategoriesList } from '../../../admin/hooks';
import { ControlledCheckbox, Loading, Section } from '../../../shared';
import { WeekInput } from '../../components';
import { IFormWeekEntry, IPlanningPatternForm, ISaveRequirement } from '../../models';

export const emptyWeek: IFormWeekEntry = {
    monday: { isAppointmentOnly: false, requirements: [] },
    tuesday: { isAppointmentOnly: false, requirements: [] },
    wednesday: { isAppointmentOnly: false, requirements: [] },
    thursday: { isAppointmentOnly: false, requirements: [] },
    friday: { isAppointmentOnly: false, requirements: [] },
    saturday: { isAppointmentOnly: false, requirements: [] },
    sunday: { isAppointmentOnly: false, requirements: [] },
};

export const PatternEntryInputs: FC = () => {
    const { t } = useTranslation();

    const { data: categories, isPending: isCategoriesLoading } = useProductCategoriesList({ pageSize: 100 });
    const isPending = isCategoriesLoading;

    const form = useFormContext<IPlanningPatternForm>();
    const { fields: weeks, append, update, remove } = useFieldArray({ name: 'weeks', control: form.control });
    const watch = form.watch();

    const removeEntryFromWeekDay = useCallback(
        (weekIndex: number, day: keyof IFormWeekEntry, entryIndex: number) => {
            update(weekIndex, {
                ...watch.weeks[weekIndex],
                [day]: {
                    isAppointmentOnly: false,
                    requirements: watch.weeks[weekIndex][day].requirements.filter(
                        (item, itemIndex) => itemIndex !== entryIndex,
                    ),
                },
            });
        },
        [update, watch],
    );

    const addEntryToWeekDay = useCallback(
        (weekIndex: number, day: keyof IFormWeekEntry, entry: ISaveRequirement) => {
            update(weekIndex, {
                ...watch.weeks[weekIndex],
                [day]: { isAppointmentOnly: false, requirements: [...watch.weeks[weekIndex][day].requirements, entry] },
            });
        },
        [update, watch],
    );

    return isPending ? (
        <Loading />
    ) : (
        <>
            <ControlledCheckbox name="feadProductsPattern" label={t('feadProductsPattern')} />
            <Section title={t('pattern')} sx={{ mt: 2 }}>
                <Table size="small" sx={{ minWidth: watch.feadProductsPattern ? 'auto' : '1200px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell width={'2.5%'} />
                            <TableCell width={'10%'}>{t('week')}</TableCell>
                            <TableCell width={'8%'}>{t('day')}</TableCell>
                            {!watch.feadProductsPattern && (
                                <>
                                    <TableCell width={'12%'}>{t('appointmentOnly')}</TableCell>
                                    <TableCell width={'25%'}>{t('category')}</TableCell>
                                </>
                            )}
                            <TableCell width={watch.feadProductsPattern ? '75%' : '25%'}>{t('product')}</TableCell>
                            {!watch.feadProductsPattern && <TableCell width={'15%'}>{t('desiredAmount')}</TableCell>}
                            <TableCell width={watch.feadProductsPattern ? '4.5%' : '2.5%'} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {weeks.map((week, index) => (
                            <WeekInput
                                key={week.id}
                                week={week}
                                weekIndex={index}
                                removeWeek={() => remove(index)}
                                addEntry={(day, entry) => addEntryToWeekDay(index, day, entry)}
                                removeEntry={(day, entryIndex) => removeEntryFromWeekDay(index, day, entryIndex)}
                                categories={categories?.data || []}
                            />
                        ))}
                    </TableBody>
                </Table>
                <Button onClick={() => append(emptyWeek)}>+ {t('addWeek')}</Button>
            </Section>
        </>
    );
};
