import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Page, PermissionKeys, ProtectedRoute } from '../../../shared';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { ShipmentCompleteDatePage } from '../shipment-complete-date/shipment-complete-date.page';
import { ShipmentCompleteShipmentsPage } from '../shipment-complete-shipments/shipment-complete-shipments.page';
import { ShipmentCompleteSendMailsPage } from '../shipment-complete-send-mails/shipment-complete-send-mails.page';

export const ShipmentsCompleteWizardPage: FC = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const [selectedShipments, setSelectedShipments] = useState<string[]>([]);

    const activeStep = useMemo(() => {
        const activeStepPath = location.pathname.split('/').pop();
        return Math.max(['', 'shipments', 'send-mails'].indexOf(`${activeStepPath}`), 0);
    }, [location]);

    return (
        <Page>
            <Box sx={{ p: 3, pt: 0 }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step>
                        <StepLabel>{t('date')}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{t('shipments')}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{t('sendMails')}</StepLabel>
                    </Step>
                </Stepper>
            </Box>
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute requiredPermission={PermissionKeys.SHIPMENTS_WRITE}>
                            <ShipmentCompleteDatePage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/:date/shipments"
                    element={
                        <ProtectedRoute requiredPermission={PermissionKeys.SHIPMENTS_WRITE}>
                            <ShipmentCompleteShipmentsPage
                                selectedShipments={selectedShipments}
                                onChangeSelectedShipments={setSelectedShipments}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/:date/send-mails"
                    element={
                        <ProtectedRoute requiredPermission={PermissionKeys.SHIPMENTS_WRITE}>
                            <ShipmentCompleteSendMailsPage selectedShipmentIds={selectedShipments} />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Page>
    );
};
