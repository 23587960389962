import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { lazy, number, object, string } from 'yup';

export function useProductCategorySchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                name: string().required(),
                abbreviation: string().required().max(4, t('abbreviationMaxLengthError')),
                averagePricePerKg: lazy((value) =>
                    value === '' ? string() : number().typeError(t('invalidNumber')).min(0, t('negativeNumber')),
                ),
                priority: lazy((value) => (value === '' ? string() : number().typeError(t('invalidNumber')))),
            }),
        [t],
    );
}
