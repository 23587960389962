import React, { FC, useEffect, useState } from 'react';
import { IBlacklistedProductQuery, IProductCategory } from '../../models';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import {
    ControlledDatePicker,
    ControlledInput,
    ControlledSelect,
    FormGrid,
    IOrganisation,
    Options,
    OptionsToggle,
    useOrganisationsList,
} from '../../../shared';
import { MenuItem } from '@mui/material';
import { useProductCategoriesList } from '../../hooks';

interface Props {
    filter: IBlacklistedProductQuery;
    onChange: (filter: IBlacklistedProductQuery) => void;
}

export const BlacklistedProductsFilter: FC<Props> = ({ filter, onChange }) => {
    const { t } = useTranslation();
    const form = useForm<IBlacklistedProductQuery>({ mode: 'onChange' });

    const [options, setOptions] = useState<Options>({
        filter: { type: 'title', label: t('filter') },
        organisationId: { label: t('organisation'), active: false },
        categoryId: { label: t('category'), active: false },
        partialProductName: { label: t('partialProductName'), active: false },
        productCode: { label: t('productCode'), active: false },
        isPermanent: { label: t('isPermanent'), active: false },
        startDate: { label: t('startDate'), active: false },
        endDate: { label: t('endDate'), active: false },
    });

    const { data: organisations } = useOrganisationsList({ pageSize: 100 });
    const { data: categories } = useProductCategoriesList({ pageSize: 100 });

    const formValues = form.watch();

    useEffect(() => {
        const { organisationId, categoryId, partialProductName, productCode, isPermanent, startDate, endDate } =
            options;
        if (!organisationId.active) {
            form.setValue('organisationId', undefined);
        }

        if (!categoryId.active) {
            form.setValue('categoryId', undefined);
        }

        if (!partialProductName.active) {
            form.setValue('partialProductName', undefined);
        }

        if (!productCode.active) {
            form.setValue('productCode', undefined);
        }

        if (!isPermanent.active) {
            form.setValue('isPermanent', undefined);
        }

        if (!startDate.active) {
            form.setValue('startDate', undefined);
        }

        if (!endDate.active) {
            form.setValue('endDate', undefined);
        }
    }, [form, options]);

    useEffect(() => {
        if (JSON.stringify(formValues) !== JSON.stringify(filter)) {
            onChange(formValues);
        }
    }, [formValues, filter, onChange]);

    return (
        <FormProvider {...form}>
            <FormGrid alignItems="center" spacing={1}>
                <OptionsToggle options={options} onChange={setOptions} />
                {options.organisationId.active && (
                    <ControlledSelect name="organisationId" label={t('organisation')} size="small" minWidth={100}>
                        <MenuItem value="">{t('all')}</MenuItem>
                        <MenuItem value="blockForAllOrganisations">{t('blockedForAllOrganisations')}</MenuItem>
                        {(organisations?.data || []).map((organisation: IOrganisation) => (
                            <MenuItem value={organisation.id} key={organisation.id}>
                                {organisation.name}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}

                {options.categoryId.active && (
                    <ControlledSelect name="categoryId" label={t('category')} size="small" minWidth={100}>
                        <MenuItem value="">{t('all')}</MenuItem>
                        {(categories?.data || []).map((category: IProductCategory) => (
                            <MenuItem value={category.id} key={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}

                {options.partialProductName.active && (
                    <ControlledInput name="partialProductName" label={t('partialProductName')} size="small" />
                )}

                {options.productCode.active && (
                    <ControlledInput name="productCode" label={t('productCode')} size="small" />
                )}

                {options.isPermanent.active && (
                    <ControlledSelect name="isPermanent" label={t('isPermanent')} size="small" minWidth={100}>
                        <MenuItem value="">{t('none')}</MenuItem>
                        <MenuItem value="TRUE">{t('yes')}</MenuItem>
                        <MenuItem value="FALSE">{t('no')}</MenuItem>
                    </ControlledSelect>
                )}

                {options.startDate.active && (
                    <ControlledDatePicker name="startDate" label={t('startDate')} size="small" />
                )}

                {options.endDate.active && <ControlledDatePicker name="endDate" label={t('endDate')} size="small" />}
            </FormGrid>
        </FormProvider>
    );
};
