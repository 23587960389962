import { BaseClient, logisticsApi } from '../../shared';
import { ISaveStockLocation, IStockLocation } from '../models';

class StockLocationsClient extends BaseClient<IStockLocation, ISaveStockLocation> {
    constructor() {
        super(logisticsApi, '/stock-locations');
    }
}

export const stockLocationsClient = new StockLocationsClient();
