import { CloudUploadTwoTone } from '@mui/icons-material';
import { Alert, Button, Dialog, DialogActions, DialogContent, styled, Typography } from '@mui/material';
import { transparentize } from 'polished';
import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../../shared';

interface Props {
    open: boolean;
    onClose: () => void;
    uploading: boolean;
    failed: boolean;
    onUpload: (file: File) => void;
}

const FilePicker = styled('label')(({ theme: { palette, spacing } }) => ({
    borderStyle: 'dashed',
    borderColor: palette.divider,
    borderRadius: 3,
    borderWidth: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: spacing(4),
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
        borderColor: palette.primary.main,
        backgroundColor: transparentize(0.9, palette.primary.main),
    },
}));

export const UploadShipmentAssetDialog: FC<Props> = ({ open, onClose, uploading, failed, onUpload }) => {
    const { t } = useTranslation();
    const [file, setFile] = useState<File>();

    const upload = useCallback(() => {
        if (file) {
            onUpload(file);
            setFile(undefined);
        }
    }, [onUpload, file]);

    const close = useCallback(() => {
        setFile(undefined);
        onClose();
    }, [onClose]);

    return (
        <Dialog open={open || uploading} onClose={close}>
            <DialogContent sx={{ width: 450 }}>
                {uploading ? (
                    <Loading />
                ) : (
                    <>
                        {failed && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {t('uploadFailed')}
                            </Alert>
                        )}
                        <FilePicker htmlFor="file">
                            <CloudUploadTwoTone color="primary" fontSize="large" sx={{ mb: 1 }} />
                            <Typography>{file ? file.name : t('uploadFile')}</Typography>
                        </FilePicker>
                        <input
                            id="file"
                            type="file"
                            hidden
                            disabled={uploading}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setFile(e.target.files?.[0])}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button fullWidth disabled={uploading} onClick={upload} variant="contained" color="primary">
                    {t('upload')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
