import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { settingsClient } from '../clients';
import { ISaveSettings } from '../models';
import { QueryKeys } from '../../shared';
import { useTranslation } from 'react-i18next';

export function useSettings() {
    return useQuery({ queryKey: [QueryKeys.Settings], queryFn: () => settingsClient.getSettings() });
}

export function useUpdateSettings() {
    const { t } = useTranslation();

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (settings: ISaveSettings) => settingsClient.updateSettings(settings, t('saveSuccess')),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [QueryKeys.Settings] }),
    });
}
