import React, { FC, useMemo } from 'react';
import { IBlacklistedProduct, IProductCategory } from '../../../admin/models';
import { IOrganisation } from '../../../shared';
import { ShipmentStatus } from '../../enums';
import { useAllCategories } from '../../hooks';
import { IProgress, IProgressBar } from '../../models';
import { compareProductCategory } from '../../utils';
import { useTranslation } from 'react-i18next';
import { mapProgressToProgressBar } from '../../mappers';
import { ProgressCategoriesHeader } from './progress-categories-header.component';
import { ProgressItems } from './progress-items.component';

interface Props {
    organisation?: IOrganisation;
    shipmentDate?: string;
    shipmentStatus?: ShipmentStatus;
    shipmentAmountOfDrafts?: number;
    progresses: IProgress[];
    showLabels?: boolean;
    visibleCategories?: IProductCategory[];
    chartsPerRow?: number;
    blacklistedCategories: IBlacklistedProduct[];
}

export const ProgressCategories: FC<Props> = ({
    organisation,
    progresses,
    showLabels = false,
    shipmentDate,
    shipmentStatus,
    shipmentAmountOfDrafts,
    visibleCategories,
    chartsPerRow,
    blacklistedCategories,
}) => {
    const categories = useAllCategories();
    const { t } = useTranslation();
    const categoryProgresses = useMemo(() => progresses.filter(({ category }) => !!category), [progresses]);

    const data = useMemo(() => {
        if (categoryProgresses && categories?.length) {
            const data: IProgressBar[] = categoryProgresses
                .filter(({ totalScanned, totalPlanned }) => !(totalScanned === 0 && totalPlanned === 0))
                .map((progress) => mapProgressToProgressBar(progress, categories, t, blacklistedCategories));

            if (visibleCategories) {
                visibleCategories.forEach((category) => {
                    if (!data.some((progress) => progress.category === category.id)) {
                        const isBlacklisted = !!blacklistedCategories.find(
                            (blacklisted) => blacklisted.category?.id === category.id,
                        );
                        data.push({
                            category: category.id,
                            totalScanned: 0,
                            totalPlanned: 0,
                            name: category.name,
                            abbreviation: category.abbreviation,
                            priority: category.priority,
                            isBlacklisted,
                        });
                    }
                });
            }
            return data.sort(compareProductCategory);
        } else return undefined;
    }, [categoryProgresses, categories, visibleCategories, t, blacklistedCategories]);

    return (
        <>
            {organisation && (
                <ProgressCategoriesHeader
                    organisation={organisation}
                    shipmentDate={shipmentDate}
                    shipmentStatus={shipmentStatus}
                    shipmentAmountOfDrafts={shipmentAmountOfDrafts}
                />
            )}

            <ProgressItems progresses={data} chartsPerRow={chartsPerRow} showLabels={showLabels} />
        </>
    );
};
