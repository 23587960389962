import { ResultSet } from '@cubejs-client/core';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { useColoredCategories } from '../../../planning/hooks';

interface Props {
    resultSet: ResultSet;
}

const renderCustomizedLabel = ({ x, y, cx, percent }: any) => {
    return (
        <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export const FoodDistributionPieChart: FC<Props> = ({ resultSet }) => {
    const { t } = useTranslation();
    const categories = useColoredCategories();

    const chartData = useMemo(() => {
        return resultSet?.chartPivot().map((value) => (value.x === '∅' ? { ...value, x: t('unknown') } : value));
    }, [resultSet, t]);

    return (
        <ResponsiveContainer width="100%" height={350}>
            <PieChart>
                <Pie
                    label={renderCustomizedLabel}
                    isAnimationActive={false}
                    data={chartData}
                    nameKey="x"
                    dataKey={resultSet.seriesNames()[0]?.key}
                >
                    {chartData?.map((e, index) => (
                        <Cell key={index} fill={categories?.find(({ name }) => e.x === name)?.color || '#D3D3D3'} />
                    ))}
                </Pie>
                <Legend />
            </PieChart>
        </ResponsiveContainer>
    );
};
