import { useMemo } from 'react';

export const useCsvExport = (columns: { field: string; headerName?: string }[], data: any) => {
    return useMemo(() => {
        if (data) {
            const result = [
                columns.map(({ headerName }) => headerName),
                ...data.map((item: any) => columns.map(({ field }) => item[field])),
            ];
            const csvContent = `data:text/csv;charset=utf-8,${result.map((e: any) => e.join(',')).join('\n')}`;
            return encodeURI(csvContent);
        }
    }, [columns, data]);
};
