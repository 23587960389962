import React, { FC, useMemo } from 'react';
import { IProgressBar } from '../../models';
import { ProgressChart } from './progress-chart.component';

interface Props {
    progresses?: IProgressBar[];
    chartsPerRow?: number;
    showLabels?: boolean;
}

export const ProgressItems: FC<Props> = ({ progresses, chartsPerRow, showLabels = true }) => {
    const splitProgresses = useMemo(() => {
        const chunks = [];
        if (progresses && chartsPerRow) {
            for (let i = 0; i < progresses.length; i += chartsPerRow) {
                const chunk = progresses.slice(i, i + chartsPerRow);
                chunks.push(chunk);
            }
        }
        return chunks;
    }, [chartsPerRow, progresses]);

    return (
        <>
            {progresses && (
                <>
                    {!chartsPerRow || progresses.length <= chartsPerRow ? (
                        <ProgressChart progresses={progresses} showLabels={showLabels} />
                    ) : (
                        <>
                            {splitProgresses.map((progress, index) => (
                                <ProgressChart key={index} progresses={progress} showLabels={showLabels} />
                            ))}
                        </>
                    )}
                </>
            )}
        </>
    );
};
