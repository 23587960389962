import { DataGrid, GridFeatureMode, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { ComponentProps, Dispatch } from 'react';

interface Props extends ComponentProps<typeof DataGrid> {
    sortModel?: GridSortItem[];
    onSortModelChange?: Dispatch<GridSortModel>;
    defaultSortModel?: GridSortItem[];
    mode?: GridFeatureMode;
    disableColumnMenu?: boolean;
}

export function TableComponent({
    onSortModelChange,
    defaultSortModel,
    mode = 'server',
    disableColumnMenu = true,
    ...props
}: Props) {
    const handleSortModelChange = (newModel: GridSortModel) => {
        if (onSortModelChange && defaultSortModel && props.sortModel) {
            if (!newModel[0]) {
                onSortModelChange(defaultSortModel);
            } else if (newModel[0].sort !== props.sortModel[0].sort || newModel[0].field !== props.sortModel[0].field) {
                onSortModelChange(newModel);
            }
        }
    };

    return (
        <DataGrid
            autoHeight
            pageSizeOptions={props.pageSizeOptions ? props.pageSizeOptions : [5, 10, 20]}
            pagination
            paginationMode={mode}
            sortingMode={mode}
            sortingOrder={['asc', 'desc']}
            onSortModelChange={handleSortModelChange}
            disableColumnFilter
            disableColumnMenu={disableColumnMenu}
            {...props}
        />
    );
}
