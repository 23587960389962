import { Stack, TextField } from '@mui/material';
import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { IFeadDelivery, IFeadDeliveryProduct } from '../../../shared';
import { useTranslation } from 'react-i18next';

interface Props {
    productsInfo: IFeadDeliveryProduct[];
    path: string;
    delivery: IFeadDelivery | undefined;
}

export const FeadDeliveryEntryDetail: FC<Props> = ({ productsInfo, path, delivery }) => {
    const { t } = useTranslation();
    const watchOrganisation = useWatch({ name: path });

    const details = productsInfo.find((item) => item.organisationId === watchOrganisation);
    const oldEntry = delivery?.entries.find((entry) => entry.organisationId === watchOrganisation);

    const plannedAmount = (details?.totalPlanned || 0) - (oldEntry?.plannedAmount || 0);
    const deliveredAmount = (details?.totalDelivered || 0) - (oldEntry?.deliveredAmount || 0);

    return (
        <Stack spacing={2} alignItems="center" direction="row">
            <TextField
                label={t('totalAlreadyPlanned')}
                value={plannedAmount}
                inputProps={{ readOnly: true }}
                sx={{ minWidth: '170px', '.MuiOutlinedInput-notchedOutline': { border: 'none' }, color: 'black' }}
            />
            <TextField
                label={t('totalAlreadyDelivered')}
                value={deliveredAmount}
                inputProps={{ readOnly: true }}
                sx={{ minWidth: '170px', '.MuiOutlinedInput-notchedOutline': { border: 'none' } }}
            />
        </Stack>
    );
};
