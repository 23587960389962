import { IBatch, IBatchForm, ISaveBatch } from '../../shared';
import { format } from 'date-fns';

export const batchToFormMapper = (item: IBatch): IBatchForm => {
    return {
        ...item,
        supplierId: item.supplier.id,
        date: item.date,
        referenceNumber: item.referenceNumber || '',
        qrCodeId: item.qrCode?.id || '',
    };
};

export const batchFromFormMapper = (item: IBatchForm, confirm: boolean): ISaveBatch => {
    return {
        ...item,
        confirm,
        supplierId: item.supplierId,
        date: format(new Date(item.date), 'yyyy-MM-dd'),
        referenceNumber: item.referenceNumber || '',
        qrCodeId: item.qrCodeId || null,
    };
};
