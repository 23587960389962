import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactType, RemoveModal } from '../../../shared';
import { IShipment } from '../../models';
import { Button, Stack, Tooltip } from '@mui/material';
import { ShipmentStatus } from '../../enums';
import { useNotifyShipment, useSaveShipment } from '../../hooks';
import { useNavigate } from 'react-router-dom';

interface Props {
    id: string;
    shipment?: IShipment;
    deleteShipment: (id: string) => void;
}

export const ShipmentDetailActions: FC<Props> = ({ id, shipment, deleteShipment }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { mutateAsync: notifyShipment, isPending: notifying } = useNotifyShipment();
    const { mutateAsync: saveShipment, isPending: isPendingSave } = useSaveShipment();

    const emailConfigured = useMemo(() => {
        return shipment?.receiver?.contacts
            ? shipment?.receiver.contacts?.filter((contact) => contact.type === ContactType.Email)?.length > 0
            : false;
    }, [shipment]);

    const onNotify = useCallback(async () => {
        await notifyShipment(id);
    }, [id, notifyShipment]);

    const onComplete = useCallback(async () => {
        if (shipment) {
            await saveShipment({
                id,
                item: {
                    id,
                    receiverId: shipment.receiver.id,
                    shipmentDate: shipment.shipmentDate,
                    stockMovements: shipment.stockMovements.map(({ id }) => id),
                    status: ShipmentStatus.COMPLETED,
                },
            });
        }
    }, [id, saveShipment, shipment]);

    const onEdit = useCallback(async () => {
        navigate(`/planning/shipments/${id}`);
    }, [id, navigate]);

    const onDelete = useCallback(async () => {
        deleteShipment(id);
        navigate('/planning/shipments');
    }, [deleteShipment, id, navigate]);

    return (
        <Stack spacing={1} direction="row">
            <RemoveModal
                title={t('removeShipment')}
                text={t('removeShipmentWarning')}
                button={<Button>{t('removeShipment')}</Button>}
                handleDelete={onDelete}
            />
            {shipment?.status === ShipmentStatus.COMPLETED ? (
                <Tooltip title={emailConfigured ? '' : (t('emailNotConfigured') as string)}>
                    <span>
                        <Button variant="outlined" onClick={onNotify} disabled={notifying || !emailConfigured}>
                            {t('notifyShipment')}
                        </Button>
                    </span>
                </Tooltip>
            ) : (
                <Button variant="outlined" onClick={onComplete} disabled={isPendingSave}>
                    {t('completeShipment')}
                </Button>
            )}
            <Button variant="contained" onClick={onEdit}>
                {t('editShipment')}
            </Button>
        </Stack>
    );
};
