import { DeleteOutline } from '@mui/icons-material';
import { IconButton, MenuItem, TableCell, TableRow } from '@mui/material';
import React, { FC, Fragment, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IProductCategory } from '../../../admin/models';
import { ControlledSelect } from '../../../shared';
import { IFormWeekEntry, IPatternRequirementForm, ISaveRequirement } from '../../models';
import { EntryAmount } from '../entry-amount/entry-amount.component';
import { AddEntry } from './add-entry.component';
import { EntrySelectProduct } from './entry-select-product.component';

interface Props {
    week: IFormWeekEntry;
    weekIndex: number;
    removeWeek: () => void;
    addEntry: (day: keyof IFormWeekEntry, entry: IPatternRequirementForm) => void;
    removeEntry: (day: keyof IFormWeekEntry, index: number) => void;
    categories: IProductCategory[];
}

const DAYS: Array<keyof IFormWeekEntry> = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

export const getRowsPerWeekDay = (day: ISaveRequirement[]): number => (day.length > 0 ? day.length + 2 : 1);

export const WeekInput: FC<Props> = ({ weekIndex, week, removeWeek, addEntry, removeEntry, categories }) => {
    const { t } = useTranslation();
    const watchFeadProductsPattern = useWatch({ name: 'feadProductsPattern' });

    const totalWeekRows = useMemo(
        () =>
            getRowsPerWeekDay(week.monday.requirements) +
            getRowsPerWeekDay(week.tuesday.requirements) +
            getRowsPerWeekDay(week.wednesday.requirements) +
            getRowsPerWeekDay(week.thursday.requirements) +
            getRowsPerWeekDay(week.friday.requirements) +
            getRowsPerWeekDay(week.saturday.requirements) +
            getRowsPerWeekDay(week.sunday.requirements),
        [week],
    );

    const rowStyle = { backgroundColor: weekIndex % 2 ? 'common.white' : 'background.default' };

    const onAddEntry = (day: keyof IFormWeekEntry, isCategory: boolean) =>
        addEntry(day, { amount: 0, categoryId: '', product: null, isCategory });

    return (
        <>
            {DAYS.map((day, dayIndex) => (
                <Fragment key={dayIndex}>
                    <TableRow sx={rowStyle}>
                        {dayIndex === 0 && (
                            <>
                                <TableCell rowSpan={totalWeekRows}>
                                    <IconButton onClick={removeWeek}>
                                        <DeleteOutline />
                                    </IconButton>
                                </TableCell>
                                <TableCell rowSpan={totalWeekRows}>
                                    {t('week')} {weekIndex + 1}
                                </TableCell>
                            </>
                        )}
                        <TableCell rowSpan={getRowsPerWeekDay(week[day].requirements)}>{t(day)}</TableCell>
                        {week[day].requirements.length === 0 && (
                            <AddEntry onAddEntry={onAddEntry} day={day} path={`weeks.${weekIndex}.${day}`} />
                        )}
                    </TableRow>
                    {week[day].requirements.map((entry, index) => (
                        <TableRow key={index} sx={rowStyle}>
                            {!watchFeadProductsPattern && (
                                <>
                                    <TableCell colSpan={1} />
                                    <TableCell>
                                        <ControlledSelect
                                            name={`weeks.${weekIndex}.${day}.requirements.${index}.categoryId`}
                                            required
                                            size="small"
                                            sx={{ display: !entry.isCategory ? 'none' : 'block' }}
                                        >
                                            {categories.map((category: IProductCategory) => (
                                                <MenuItem value={category.id} key={category.id}>
                                                    {category.name}
                                                </MenuItem>
                                            ))}
                                        </ControlledSelect>
                                    </TableCell>
                                </>
                            )}
                            <TableCell>
                                <EntrySelectProduct
                                    name={`weeks.${weekIndex}.${day}.requirements.${index}.product`}
                                    isHidden={entry.isCategory}
                                />
                            </TableCell>
                            {!watchFeadProductsPattern && (
                                <TableCell>
                                    <EntryAmount
                                        path={`weeks.${weekIndex}.${day}.requirements.${index}`}
                                        disabledWhenProductRequiresReservation={true}
                                    />
                                </TableCell>
                            )}

                            <TableCell>
                                <IconButton onClick={() => removeEntry(day, index)}>
                                    <DeleteOutline />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                    {week[day].requirements.length > 0 && (
                        <TableRow sx={rowStyle}>
                            <AddEntry onAddEntry={onAddEntry} day={day} path={`weeks.${weekIndex}.${day}`} />
                        </TableRow>
                    )}
                </Fragment>
            ))}
        </>
    );
};
