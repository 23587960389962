export const commonTranslationsNL = {
    addBtn: 'Voeg toe',
    cancel: 'Annuleer',
    confirm: 'bevestig',
    comments: 'Commentaar',
    'creation-successful': 'Succesvol aangemaakt',
    data: 'Gegevens',
    delete: 'Verwijder',
    edit: 'Pas aan',
    goBack: 'Ga terug naar de vorige pagina',
    noInfo: 'Geen gegevens beschikbaar.',
    ok: 'ok',
    pageNotFoundErr: 'Deze pagina kon niet worden gevonden.',
    save: 'Opslaan',
    search: 'Zoek',
    'something-went-wrong': 'Er ging iets mis',
    'update-successful': 'Succesvol aangepast',
    logOut: 'Uitloggen',
    name: 'Naam',
    code: 'Code',
    home: 'Home',
    type: 'Type',
    download: 'Download',
    address: 'Adres',
    street: 'Straat',
    number: 'Nummer',
    postalCode: 'Postcode',
    city: 'Stad',
    country: 'Land',
    total: 'Totaal',
    all: 'Alle',
    date: 'Datum',
    status: 'Status',
    next: 'Volgende',
    previous: 'Vorige',
    attachments: 'Bijlage',
    addAttachment: 'Bijlage toevoegen',
    deleteAttachmentTitle: 'Bijlage verwijderen?',
    deleteAttachmentDescription: 'Ben je zeker dat je deze bijlage wilt verwijderen?',
    upload: 'Upload',
    uploadFile: 'Sleep je bestand hierheen, of klik om een bestand te selecteren.',
    uploadFailed: 'Er ging iets mis bij het uploaden van het bestand.',
    complete: 'Voltooi',
    amountGreaterThanOrEqualToZero: 'Aantal moet groter zijn of gelijk aan 0',
    modify: 'Wijzig',
    filter: 'Filter',
    options: 'Opties',
    yes: 'Ja',
    no: 'Nee',
    createdBy: 'Aangemaakt door',
    saveSuccess: 'Successvol opgeslagen',
    tryAgainLater:
        'Probeer later nog eens, indien deze foutmelding zich opnieuw voordoet: contacteer de verantwoordelijke.',
};
