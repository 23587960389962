import { IOrganisationType, ISaveOrganisationType } from '../models';
import { BaseClient } from './base.client';
import { logisticsApi } from './logistics-api.client';

class OrganisationTypesClient extends BaseClient<IOrganisationType, ISaveOrganisationType> {
    constructor() {
        super(logisticsApi, '/organisation-types');
    }
}

export const organisationTypesClient = new OrganisationTypesClient();
