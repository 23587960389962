import { ListAltTwoTone } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatDateString, FormGrid, IParams, Page, PermissionKeys, useHasPermission, Widget } from '../../../shared';
import {
    ProgressCategories,
    ProgressProducts,
    ShipmentDetailActions,
    ShipmentDetailInfo,
    ShipmentDetailStockMovements,
} from '../../components';
import { ShipmentDetailAttachments } from '../../components/shipment-detail/shipment-detail-attachments.component';
import { useDeleteShipment, useShipment } from '../../hooks';

export const ShipmentDetailPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();

    const { state: redirectToDashboard } = useLocation();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SHIPMENTS_WRITE);

    const { data: shipment, isPending: shipmentIsPending } = useShipment(id, { refetchInterval: 5000 });
    const { mutateAsync: deleteShipment, isPending: isPendingDelete } = useDeleteShipment();

    const onlyProducts = useMemo(() => {
        return shipment?.progresses.every(({ product }) => product && product?.requiresReservation) || false;
    }, [shipment]);

    return (
        <Page
            title={t(`shipmentDetails`, {
                organisation: shipment && shipment.receiver?.name,
                date: shipment && formatDateString(shipment.shipmentDate),
                interpolation: { escapeValue: false },
            })}
            onBack={() => navigate(redirectToDashboard ? redirectToDashboard : '/planning/shipments')}
            loading={shipmentIsPending || isPendingDelete}
            actions={
                hasWritePermission && (
                    <ShipmentDetailActions id={id} shipment={shipment} deleteShipment={deleteShipment} />
                )
            }
        >
            {shipment && (
                <Stack spacing={2}>
                    <FormGrid xs={6} sx={{ '&.MuiGrid-root:first-child': { pl: 0 } }}>
                        <ShipmentDetailInfo shipment={shipment} />
                        <ShipmentDetailAttachments shipment={shipment} />
                    </FormGrid>

                    {!onlyProducts && (
                        <Widget title={t('progressesCategories')} icon={<ListAltTwoTone color="primary" />}>
                            <ProgressCategories
                                progresses={shipment.progresses}
                                showLabels
                                chartsPerRow={8}
                                blacklistedCategories={[]}
                            />
                        </Widget>
                    )}

                    <ProgressProducts progresses={shipment.progresses} chartsPerRow={8} />

                    <ShipmentDetailStockMovements shipment={shipment} />
                </Stack>
            )}
        </Page>
    );
};
