import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MenuItem } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledInput,
    ControlledSelect,
    FormCard,
    FormGrid,
    IParams,
    ISaveQrCode,
    Page,
    Section,
} from '../../../shared';
import { DownloadCard } from '../../components';
import { CodeType } from '../../enums';
import { useQrCodeSchema } from '../../validators';
import { useQrCode, useSaveQrCode } from '../../hooks';

export const QrCodeEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: qrCode, isFetching } = useQrCode(id);

    const form = useForm<ISaveQrCode>({
        resolver: yupResolver(useQrCodeSchema()),
        mode: 'all',
    });
    const { mutateAsync: saveQrCode, isPending: isPendingSave } = useSaveQrCode();

    useEffect(() => {
        if (qrCode) {
            form.reset(qrCode);
        }
    }, [qrCode, form]);

    const onSubmit = useCallback(
        async (item: ISaveQrCode) => {
            await saveQrCode({ id, item });
            navigate('/admin/qr-codes');
        },
        [id, navigate, saveQrCode],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            qrCode ? <DownloadCard name={qrCode.name} code={qrCode.code} /> : <></>,
            <Button onClick={() => navigate('/admin/qr-codes')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, qrCode, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={qrCode?.name || t('newQrCode')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isPendingSave}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Section title={t('data')}>
                        <FormGrid>
                            <ControlledSelect name="type" label={t('type')} required>
                                {Object.values(CodeType).map((type) => (
                                    <MenuItem value={type} key={type}>
                                        {t(type)}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                            <ControlledInput name="name" label={t('name')} required />
                            {id && <ControlledInput name="code" label={t('code')} disabled />}
                        </FormGrid>
                    </Section>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
