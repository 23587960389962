import { Delete } from '@mui/icons-material';
import { Button, Grid, IconButton } from '@mui/material';
import { FC, Fragment } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LevelOfContainmentCode, Section } from '../../../shared';
import { AllergenTypePicker } from '../allergen-type-picker/allergen-type-picker.component';
import { ContainmentLevelPicker } from '../containment-level-picker/containment-level-picker.component';

export const ProductAllergenInformation: FC = () => {
    const allergenInformation = useFieldArray({ name: 'allergenInformation' });
    const { t } = useTranslation();
    return (
        <Section title={t('allergenInformation')}>
            <Grid container alignItems="center" spacing={2}>
                {allergenInformation.fields.map((field, index) => (
                    <Fragment key={field.id}>
                        <Grid xs={5} item>
                            <ContainmentLevelPicker name={`allergenInformation.${index}.levelOfContainmentCode`} />
                        </Grid>
                        <Grid xs={5} item>
                            <AllergenTypePicker name={`allergenInformation.${index}.allergenTypeCode`} />
                        </Grid>
                        <Grid xs={2} item>
                            <IconButton onClick={() => allergenInformation.remove(index)}>
                                <Delete />
                            </IconButton>
                        </Grid>
                    </Fragment>
                ))}
            </Grid>
            <Button
                size="small"
                onClick={() =>
                    allergenInformation.append({
                        levelOfContainmentCode: LevelOfContainmentCode.MAY_CONTAIN,
                        allergenTypeCode: '',
                    })
                }
            >
                {t('addAllergenInformation')}
            </Button>
        </Section>
    );
};
