import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, date, object, string } from 'yup';

export function useBlacklistedProductSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                organisationId: string().when('blockForAllOrganisations', {
                    is: false,
                    then: (schema) => schema.required(),
                }),
                blockForAllOrganisations: boolean(),
                categoryId: string().when(['blockByProductCode', 'partialProductName'], {
                    is: (blockByProductCode: boolean, partialProductName: string) =>
                        !blockByProductCode && partialProductName == '',
                    then: (schema) => schema.required(t('requiredCategoryIdPartialProductName')),
                }),
                partialProductName: string(),
                productCode: string().when('blockByProductCode', {
                    is: true,
                    then: (schema) => schema.required(t('requiredProductCode')),
                }),
                isPermanent: boolean(),
                startDate: date()
                    .typeError(t('invalidDate'))
                    .when('isPermanent', {
                        is: false,
                        then: (schema) => schema.typeError(t('invalidDate')).required(),
                    }),
                endDate: date()
                    .typeError(t('invalidDate'))
                    .when('isPermanent', {
                        is: false,
                        then: (schema) => schema.typeError(t('invalidDate')).required(),
                    }),
                blockByProductCode: boolean(),
            }),
        [t],
    );
}
