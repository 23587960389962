import { Filter } from '@cubejs-client/core';
import { useMemo } from 'react';

export function useFilterGenerator(items: string[], member: string) {
    return useMemo(() => {
        if (items?.includes('')) {
            if (items?.filter((cat) => !!cat).length) {
                return [
                    {
                        or: [
                            {
                                member,
                                operator: 'equals',
                                values: items.filter((cat) => !!cat),
                            } as Filter,
                            {
                                member,
                                operator: 'notSet',
                            } as Filter,
                        ],
                    },
                ];
            } else {
                return [
                    {
                        member,
                        operator: 'notSet',
                    } as Filter,
                ];
            }
        } else if (items?.length) {
            return [
                {
                    member,
                    operator: 'equals',
                    values: items.filter((cat) => !!cat),
                } as Filter,
            ];
        }
        return [];
    }, [items, member]);
}
