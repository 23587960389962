export enum PermissionKeys {
    ORGANISATIONS_READ = 'organisations-read',
    ORGANISATIONS_WRITE = 'organisations-write',
    ORGANISATION_TYPES_READ = 'organisation-types-read',
    ORGANISATION_TYPES_WRITE = 'organisation-types-write',
    DEVICES_READ = 'devices-read',
    DEVICES_WRITE = 'devices-write',
    OPERATORS_READ = 'operators-read',
    OPERATORS_WRITE = 'operators-write',
    QR_CODES_READ = 'qr-codes-read',
    QR_CODES_WRITE = 'qr-codes-write',
    SETTINGS_READ = 'settings-read',
    SETTINGS_WRITE = 'settings-write',
    SUPPLIERS_READ = 'suppliers-read',
    SUPPLIERS_WRITE = 'suppliers-write',
    ASSETS_READ = 'assets-read',
    ASSETS_WRITE = 'assets-write',
    SHIPMENTS_READ = 'shipments-read',
    SHIPMENTS_WRITE = 'shipments-write',
    CARDS_READ = 'cards-read',
    SCANS_WRITE = 'scans-write',
    PLANNINGS_READ = 'plannings-read',
    PLANNINGS_WRITE = 'plannings-write',
    BLACKLISTED_PRODUCTS_READ = 'blacklisted-products-read',
    BLACKLISTED_PRODUCTS_WRITE = 'blacklisted-products-write',
    PRODUCTS_READ = 'products-read',
    PRODUCTS_WRITE = 'products-write',
    PRODUCT_CATEGORIES_READ = 'product-categories-read',
    PRODUCT_CATEGORIES_WRITE = 'product-categories-write',
    STOCK_COUNTS_READ = 'stock-counts-read',
    STOCK_LOCATIONS_READ = 'stock-locations-read',
    STOCK_LOCATIONS_WRITE = 'stock-locations-write',
    STOCK_MOVEMENTS_READ = 'stock-movements-read',
    STOCK_MOVEMENTS_WRITE = 'stock-movements-write',
    STOCK_PRODUCTS_READ = 'stock-products-read',
    BATCHES_READ = 'batches-read',
    BATCHES_WRITE = 'batches-write',
    FEAD_DELIVERIES_READ = 'fead-deliveries-read',
    FEAD_DELIVERIES_WRITE = 'fead-deliveries-write',
}
