import { Box } from '@mui/material';
import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { ErrorFallback, ProtectedRoute, SideBar, SideNav } from '../../../shared';
import { stockSubRoutes } from '../../stock.routes';

export const StockPage: FC = () => {
    const { t } = useTranslation();
    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav
                    items={[
                        { path: '/stock/movements', text: t('stockMovements') },
                        { path: '/stock/batches', text: t('batches') },
                        { path: '/stock/products', text: t('stockProducts') },
                    ]}
                />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {stockSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ProtectedRoute requiredPermission={subRoute.requiredPermissions}>
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        {<subRoute.component />}
                                    </ErrorBoundary>
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};
