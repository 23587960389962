import { Box } from '@mui/material';
import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import { ErrorFallback, ProtectedRoute } from '../../../shared';
import { feadSubRoutes } from '../../fead.routes';

export const FeadPage: FC = () => {
    return (
        <Box sx={{ flex: 1 }}>
            <Routes>
                {feadSubRoutes.map((subRoute) => (
                    <Route
                        key={subRoute.path}
                        path={subRoute.path}
                        element={
                            <ProtectedRoute requiredPermission={subRoute.requiredPermissions}>
                                <ErrorBoundary FallbackComponent={ErrorFallback}>
                                    {<subRoute.component />}
                                </ErrorBoundary>
                            </ProtectedRoute>
                        }
                    />
                ))}
            </Routes>
        </Box>
    );
};
