import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import { FeadDeliveryEditPage, FeadDeliveriesPage } from './pages';
import { FeadPage } from './pages/fead/fead.page';

export const feadRoutes: IRouteConfig[] = [
    { path: '/fead', redirect: '/fead/deliveries' },
    { path: '/fead/*', component: FeadPage },
];

export const feadSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/deliveries', component: FeadDeliveriesPage, requiredPermissions: PermissionKeys.FEAD_DELIVERIES_READ },
    {
        path: '/deliveries/new',
        component: FeadDeliveryEditPage,
        requiredPermissions: PermissionKeys.FEAD_DELIVERIES_WRITE,
    },
    {
        path: '/deliveries/:id/edit',
        component: FeadDeliveryEditPage,
        requiredPermissions: PermissionKeys.FEAD_DELIVERIES_WRITE,
    },
];
