import React, { FC } from 'react';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { IProgressBar } from '../../models';
import { useGetProgressColor } from '../../utils';
import { renderBlacklistedLabel } from './blacklisted-label.component';
import { renderWarningLabel } from './warning-label.component';

interface Props {
    progresses: IProgressBar[];
    showLabels: boolean;
}

export const ProgressChart: FC<Props> = ({ progresses, showLabels }) => {
    const getFill = useGetProgressColor();

    return (
        <ResponsiveContainer width="100%" height={150} id="organisation-progress-categories">
            <BarChart margin={{ top: 20 }} data={progresses}>
                {showLabels && <XAxis dataKey="name" />}
                {!showLabels && <XAxis dataKey="abbreviation" stroke="black" fontSize="9px" interval={0} />}
                <YAxis label={{ value: '%', position: 'insideLeft' }} domain={[0, 100]} hide={!showLabels} />
                <Bar dataKey="percentage" isAnimationActive={false} stackId="a">
                    {showLabels && <LabelList dataKey="label" position="top" fill="black" strokeWidth={0.1} />}
                    {!showLabels && <LabelList dataKey="showWarning" content={renderWarningLabel} />}
                    {!showLabels && <LabelList dataKey="isBlacklisted" content={renderBlacklistedLabel} />}
                    {!showLabels && <LabelList dataKey="limit" position="top" fill="black" strokeWidth={0.1} />}
                    {progresses.map((progress) => (
                        <Cell
                            fill={getFill(progress)}
                            key={progress.category}
                            fontSize="11px"
                            stroke={getFill(progress)}
                            strokeWidth={2}
                        />
                    ))}
                </Bar>
                <Bar dataKey="percentageNotScanned" stackId="a" fill="#ffffff00" strokeWidth={2}>
                    {progresses.map((progress) => (
                        <Cell stroke={getFill(progress)} key={progress.category} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};
