import { CloudDownloadOutlined, DeleteOutline } from '@mui/icons-material';
import { Box, IconButton, Paper, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IAsset, RemoveModal } from '../../../shared';

interface Props {
    asset: IAsset;
    onDelete?: (id: string) => void;
    height?: string;
}

export const Asset: FC<Props> = ({ asset, onDelete, height = '100%' }) => {
    const { t } = useTranslation();

    return (
        <Paper variant="outlined" sx={{ borderStyle: 'dashed', p: 2, height: height }}>
            <Stack direction="row" alignItems="center" height="100%" spacing={1}>
                <Box sx={{ flex: 1 }}>{asset.fileName}</Box>
                <Stack direction="row">
                    <IconButton href={`/logistics-api/${asset.downloadUrl}`}>
                        <CloudDownloadOutlined />
                    </IconButton>
                    {onDelete && (
                        <RemoveModal
                            handleDelete={() => onDelete(asset.id)}
                            button={
                                <IconButton aria-label="delete">
                                    <DeleteOutline />
                                </IconButton>
                            }
                            title={t('deleteAttachmentTitle')}
                            text={t('deleteAttachmentDescription')}
                        />
                    )}
                </Stack>
            </Stack>
        </Paper>
    );
};
