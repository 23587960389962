import { ResultSet } from '@cubejs-client/core';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useColoredCategories } from '../../planning/hooks';
import { TimeGranularity } from '../enums';
import { IFilter } from '../models';
import { mapReportDate } from '../utils';
import { TimeStamp } from './use-total-weight-per-catergory-report.hook';
import { useOrganisationsMap } from '../../shared';

export interface rowItem {
    date?: string;
    'ProductCategory.name': string;
    'StockMovement.totalWeight'?: string | number;
    'Requirement.amount'?: string | number;
    key?: string;
    totalPrice?: number;
    'ProductCategory.averagePricePerKg'?: number;
    'StockMovement.organisationid'?: string;
}

export function useMapDataFoodDistributionTable(
    filter: IFilter,
    actualResultSet?: ResultSet | null,
    plannedResultSet?: ResultSet | null,
    usedTimeStamp: TimeStamp = TimeStamp.createddate,
) {
    const { t } = useTranslation();
    const categories = useColoredCategories();
    const organisations = useOrganisationsMap();

    return useMemo(() => {
        let data: rowItem[] = [];

        if (actualResultSet && plannedResultSet) {
            const actual = actualResultSet.tablePivot();
            const planned = plannedResultSet.tablePivot();

            if (filter.grouping) {
                if (filter.showPlanned) {
                    //grouping and planned
                    data = joinActualAndPlannedByDateGroupAndCategoryAndOrganisation(
                        actual,
                        planned,
                        filter.grouping,
                        t,
                        usedTimeStamp,
                    );
                } else {
                    //grouping and not planned
                    data = actual.map((item) => ({
                        ...item,
                        'ProductCategory.name': (item['ProductCategory.name'] as string) || t('unknown'),
                        date: item[
                            `${
                                usedTimeStamp === TimeStamp.shipmentdate
                                    ? 'Shipment.shipmentdate'
                                    : 'StockMovement.createddate'
                            }.${filter.grouping}`
                        ] as string,
                    }));
                }
            } else {
                if (filter.showPlanned) {
                    //no grouping and planned
                    data = joinActualAndPlannedByCategoryAndOrganisation(actual, planned, t);
                } else {
                    //no grouping and not planned
                    data =
                        (actualResultSet?.tablePivot() as unknown as rowItem[]).map((item) => ({
                            ...item,
                            'ProductCategory.name': (item['ProductCategory.name'] as string) || t('unknown'),
                        })) || [];
                }
            }
        }

        if (filter.grouping) {
            // format date
            data = mapReportDate(data, `date`, filter.grouping);
        }

        const mappedData = data
            .map((value) => ({
                ...value,
                organisation: organisations[value['StockMovement.organisationid'] || ''],
                totalPrice:
                    Math.round(
                        ((categories.find((cat) => cat.name === value['ProductCategory.name'])?.averagePricePerKg ||
                            0) *
                            Number(value['StockMovement.totalWeight'])) /
                            100,
                    ) / 10,
                'StockMovement.totalWeight': Math.round(Number(value['StockMovement.totalWeight']) / 100) / 10,
                'Requirement.amount': Math.round(Number(value['Requirement.amount']) / 100) / 10,
            }))
            .sort((a, b) =>
                a.date === b.date
                    ? a['ProductCategory.name'].localeCompare(b['ProductCategory.name'])
                    : a.date && b.date
                    ? a.date.localeCompare(b.date)
                    : 0,
            );
        return [
            ...mappedData,
            {
                key: 'total',
                'ProductCategory.name': t('total'),
                'StockMovement.totalWeight':
                    Math.round(
                        data.reduce((val1, val2) => Number(val1) + Number(val2['StockMovement.totalWeight']), 0) / 100,
                    ) / 10,
                'Requirement.amount':
                    Math.round(
                        data.reduce((val1, val2) => Number(val1) + Number(val2['Requirement.amount']), 0) / 100,
                    ) / 10,
                totalPrice: mappedData.reduce((val1, val2) => Number(val1) + Number(val2.totalPrice), 0),
            },
        ];
    }, [
        actualResultSet,
        plannedResultSet,
        filter.grouping,
        filter.showPlanned,
        t,
        usedTimeStamp,
        organisations,
        categories,
    ]);
}

const joinActualAndPlannedByDateGroupAndCategoryAndOrganisation = (
    actual: { [p: string]: string | number | boolean }[],
    planned: { [p: string]: string | number | boolean }[],
    timeGranularity: TimeGranularity,
    t: TFunction,
    usedTimeStamp: TimeStamp,
) => {
    //get unique pairs of date and category and organisation
    const uniqueCombinedData: rowItem[] = [
        ...actual.map((item) => ({
            date: item[
                `${
                    usedTimeStamp === TimeStamp.shipmentdate ? 'Shipment.shipmentdate' : 'StockMovement.createddate'
                }.${timeGranularity}`
            ] as string,
            'ProductCategory.name': item['ProductCategory.name'] as string,
            'StockMovement.organisationid': item['StockMovement.organisationid'] as string,
        })),
        ...planned.map((item) => ({
            date: item[`PlanningEntry.date.${timeGranularity}`] as string,
            'ProductCategory.name': item['ProductCategory.name'] as string,
            'StockMovement.organisationid': item['Planning.organisation'] as string,
        })),
    ].filter(
        (item, index, array) =>
            index ===
            array.findIndex(
                (found) =>
                    found.date === item.date &&
                    found['ProductCategory.name'] === item['ProductCategory.name'] &&
                    found['StockMovement.organisationid'] === item['StockMovement.organisationid'],
            ),
    );

    //find the totalWeight and plannedWeight from the original data, according to organisationid, category and date
    //return row
    return uniqueCombinedData.map((item) => ({
        'StockMovement.organisationid': item['StockMovement.organisationid'],
        'ProductCategory.name': item['ProductCategory.name'] || t('unknown'),
        date: item.date,
        'StockMovement.totalWeight':
            Number(
                actual.find(
                    (i) =>
                        i['ProductCategory.name'] === item['ProductCategory.name'] &&
                        i[
                            `${
                                usedTimeStamp === TimeStamp.shipmentdate
                                    ? 'Shipment.shipmentdate'
                                    : 'StockMovement.createddate'
                            }.${timeGranularity}`
                        ] === item['date'] &&
                        i['StockMovement.organisationid'] === item['StockMovement.organisationid'],
                )?.['StockMovement.totalWeight'],
            ) || 0,
        'Requirement.amount': Number(
            planned.find(
                (i) =>
                    i['ProductCategory.name'] === item['ProductCategory.name'] &&
                    i[`PlanningEntry.date.${timeGranularity}`] === item['date'] &&
                    i['Planning.organisation'] === item['StockMovement.organisationid'],
            )?.['Requirement.amount'] || 0,
        ),
    }));
};

const joinActualAndPlannedByCategoryAndOrganisation = (
    actual: { [p: string]: string | number | boolean }[],
    planned: { [p: string]: string | number | boolean }[],
    t: TFunction,
) => {
    //get unique pairs of category and organisation
    const uniqueCombinedData: any[] = [...actual, ...planned]
        .map((item) => ({
            'StockMovement.organisationid': item['StockMovement.organisationid'] || item['Planning.organisation'],
            'ProductCategory.name': item['ProductCategory.name'],
        }))
        .filter(
            (item, index, array) =>
                index ===
                array.findIndex(
                    (found) =>
                        found['StockMovement.organisationid'] === item['StockMovement.organisationid'] &&
                        found['ProductCategory.name'] === item['ProductCategory.name'],
                ),
        );

    //find the totalWeight and plannedWeight from the original data, according to organisationid and category
    return uniqueCombinedData.map((uniqueValue) => {
        const totalWeight =
            Number(
                actual.find(
                    (item: any) =>
                        item['StockMovement.organisationid'] === uniqueValue['StockMovement.organisationid'] &&
                        item['ProductCategory.name'] === uniqueValue['ProductCategory.name'],
                )?.['StockMovement.totalWeight'],
            ) || 0;
        const plannedWeight =
            Number(
                planned.find(
                    (item: any) =>
                        item['Planning.organisation'] === uniqueValue['StockMovement.organisationid'] &&
                        item['ProductCategory.name'] === uniqueValue['ProductCategory.name'],
                )?.['Requirement.amount'],
            ) || 0;

        //return row
        return {
            'StockMovement.organisationid': uniqueValue['StockMovement.organisationid'],
            'ProductCategory.name': uniqueValue['ProductCategory.name'] || t('unknown'),
            'StockMovement.totalWeight': totalWeight,
            'Requirement.amount': plannedWeight,
        };
    });
};
