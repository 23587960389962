import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
    IFeadDeliveryProduct,
    ISaveFeadDelivery,
    QueryKeys,
    useDelete,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
} from '../../shared';
import { feadDeliveriesClient } from '../clients';
import { IFeadDelivery, IFeadDeliveryProductForOrganisation, IFeadDeliveryQuery } from '../models';

const client = feadDeliveriesClient;
const listKey = QueryKeys.FeadDeliveries;
const itemKey = QueryKeys.FeadDelivery;

export function useFeadDeliveriesList(...args: UseListParams<IFeadDelivery, IFeadDeliveryQuery>) {
    return useList(client, listKey, ...args);
}

export function useFeadDelivery(...args: UseItemParams<IFeadDelivery>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveFeadDelivery(): UseMutationResult<
    IFeadDelivery,
    AxiosError,
    { item: ISaveFeadDelivery; id?: string; ignoreInUseWarning: boolean }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ item, id, ignoreInUseWarning }) =>
            id ? client.updateFeadDelivery(id, item, ignoreInUseWarning) : client.createOne(item),
        onSuccess: async ({ id }) => {
            await queryClient.invalidateQueries({ queryKey: [listKey] });
            await queryClient.invalidateQueries({ queryKey: [itemKey, id] });
        },
    });
}

export function useDeleteFeadDelivery() {
    return useDelete(client, listKey);
}

export function useFeadDeliveryProduct(productId?: string) {
    return useQuery<IFeadDeliveryProduct[], AxiosError>({
        queryKey: [QueryKeys.FeadDelivery, productId],
        queryFn: () => client.getFeadProduct(productId),
        enabled: !!productId,
    });
}

export function useFeadDeliveryProductsForOrganisation(organisation?: string) {
    return useQuery<IFeadDeliveryProductForOrganisation[], AxiosError>({
        queryKey: [QueryKeys.FeadDelivery, organisation],
        queryFn: () => client.getFeadProductsForOrganisation(organisation),
        enabled: !!organisation,
    });
}
