import { IProduct, IProductForm, ISaveProduct } from '../../shared';

export const productToFormMapper = (item: IProduct): IProductForm => {
    return {
        ...item,
        name: item.name === 'draft' ? '' : item.name || '',
        weight: item.isDraft && item.weight === 1 ? undefined : item.weight || undefined,
        brandName: item.brandName || '',
        regulatedName: item.regulatedName || '',
        ingredients: item.ingredients || '',
        nutritionalInformation: item.nutritionalInformation || '',
        links: item.links?.map((url) => ({ url })) || [],
        allergenInformation: item.allergenInformation || [],
        dietInformation: item.dietInformation?.map((value) => ({ value })) || [],
        nutriScore: item.nutriScore || undefined,
    };
};

export const productFromFormMapper = (item: IProductForm): ISaveProduct => {
    return {
        ...item,
        categoryId: item.category?.id || '',
        dietInformation: item.dietInformation.map(({ value }) => value),
        links: item.links.map(({ url }) => url),
        weight: item.weight ? Number(item.weight.toFixed()) : null,
        isDraft: false,
    };
};
