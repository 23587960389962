import { ChevronRight } from '@mui/icons-material';
import { Box, Divider, List, ListItem, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import React, { Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetProviders } from '../../hooks';
import { IProvider } from '../../models';

export const Login: React.FC = () => {
    const { data: providers } = useGetProviders();
    const { t } = useTranslation();

    const onClickProvider = useCallback((provider: IProvider) => {
        window.location.href = provider.url;
    }, []);

    useEffect(() => {
        if (providers?.length === 1) {
            onClickProvider(providers[0]);
        }
    }, [onClickProvider, providers]);

    return (
        <Paper
            sx={{ maxWidth: 420, width: '100%', mt: 8, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}
        >
            <Box sx={{ p: 4 }}>
                <img src={window.carity.environment.theme.logo} alt="Carity" />
                <Typography sx={{ mt: 2 }} variant="h5">
                    {t('loginTitle')}
                </Typography>
                <Typography variant="subtitle1" component="div">
                    {t('selectLoginMethod')}
                </Typography>
            </Box>
            <List>
                {providers?.map((provider) => (
                    <Fragment key={provider.name}>
                        <Divider component="li" />
                        <ListItem>
                            <ListItemButton onClick={() => onClickProvider(provider)}>
                                <ListItemText primary={provider.name} />
                                <ChevronRight />
                            </ListItemButton>
                        </ListItem>
                    </Fragment>
                ))}
            </List>
        </Paper>
    );
};
