import { useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { organisationTypesClient } from '../clients';
import { IOrganisationType } from '../models';
import { QueryKeys } from '../enums';

const client = organisationTypesClient;
const listKey = QueryKeys.OrganisationTypes;
const itemKey = QueryKeys.OrganisationType;

export function useOrganisationTypesList(...args: UseListParams<IOrganisationType>) {
    return useList(client, listKey, ...args);
}

export function useOrganisationType(...args: UseItemParams<IOrganisationType>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveOrganisationType() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteOrganisationType() {
    return useDelete(client, listKey);
}
