import { BaseClient, logisticsApi } from '../../shared';
import { IQrCode, ISaveQrCode } from '../../shared/models/qr-code.model';

class QrCodesClient extends BaseClient<IQrCode, ISaveQrCode> {
    constructor() {
        super(logisticsApi, '/qr-codes');
    }
}

export const qrCodesClient = new QrCodesClient();
