import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

export function useBatchSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                date: string().typeError(t('invalidDate')).required(),
                supplierId: string().required(),
                referenceNumber: string().optional(),
                qrCodeId: string().optional(),
            }),
        [t],
    );
}
