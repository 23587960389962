import { Security } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { FC, useState } from 'react';
import { EnrollDeviceDialog } from '../enroll-device-dialog/enroll-device-dialog.component';

interface Props {
    id: string;
}

export const EnrollDevice: FC<Props> = ({ id }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
            >
                <Security />
            </IconButton>
            {open && <EnrollDeviceDialog open={open} setOpen={setOpen} id={id} />}
        </>
    );
};
