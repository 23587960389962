import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    AdminPage,
    DeviceEditPage,
    DevicesPage,
    DraftProductsPage,
    OperatorEditPage,
    OperatorsPage,
    OrganisationEditPage,
    OrganisationsPage,
    OrganisationTypeEditPage,
    OrganisationTypesPage,
    ProductCategoriesPage,
    ProductCategoryEditPage,
    ProductEditPage,
    ProductsPage,
    QrCodeEditPage,
    QrCodesPage,
    SettingsEditPage,
    StockLocationEditPage,
    StockLocationsPage,
    SupplierEditPage,
    SuppliersPage,
    BlacklistedProductsPage,
    BlacklistedProductEditPage,
} from './pages';

export const adminRoutes: IRouteConfig[] = [
    { path: '/admin', redirect: '/admin/products' },
    { path: '/admin/*', component: AdminPage },
];

export const adminSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/products', component: ProductsPage, requiredPermissions: PermissionKeys.PRODUCTS_READ },
    { path: '/products/new', component: ProductEditPage, requiredPermissions: PermissionKeys.PRODUCTS_WRITE },
    { path: '/products/:id/edit', component: ProductEditPage, requiredPermissions: PermissionKeys.PRODUCTS_WRITE },

    { path: '/draft-products', component: DraftProductsPage, requiredPermissions: PermissionKeys.PRODUCTS_READ },
    {
        path: '/draft-products/:id/edit',
        component: ProductEditPage,
        requiredPermissions: PermissionKeys.PRODUCTS_WRITE,
    },

    {
        path: '/product-categories',
        component: ProductCategoriesPage,
        requiredPermissions: PermissionKeys.PRODUCT_CATEGORIES_READ,
    },
    {
        path: '/product-categories/new',
        component: ProductCategoryEditPage,
        requiredPermissions: PermissionKeys.PRODUCT_CATEGORIES_WRITE,
    },
    {
        path: '/product-categories/:id/edit',
        component: ProductCategoryEditPage,
        requiredPermissions: PermissionKeys.PRODUCT_CATEGORIES_WRITE,
    },

    {
        path: '/blacklisted-products',
        component: BlacklistedProductsPage,
        requiredPermissions: PermissionKeys.BLACKLISTED_PRODUCTS_READ,
    },
    {
        path: '/blacklisted-products/new',
        component: BlacklistedProductEditPage,
        requiredPermissions: PermissionKeys.BLACKLISTED_PRODUCTS_WRITE,
    },
    {
        path: '/blacklisted-products/:id/edit',
        component: BlacklistedProductEditPage,
        requiredPermissions: PermissionKeys.BLACKLISTED_PRODUCTS_WRITE,
    },

    { path: '/devices', component: DevicesPage, requiredPermissions: PermissionKeys.DEVICES_READ },
    { path: '/devices/new', component: DeviceEditPage, requiredPermissions: PermissionKeys.DEVICES_WRITE },
    { path: '/devices/:id/edit', component: DeviceEditPage, requiredPermissions: PermissionKeys.DEVICES_WRITE },

    { path: '/operators', component: OperatorsPage, requiredPermissions: PermissionKeys.OPERATORS_READ },
    { path: '/operators/new', component: OperatorEditPage, requiredPermissions: PermissionKeys.OPERATORS_WRITE },
    { path: '/operators/:id/edit', component: OperatorEditPage, requiredPermissions: PermissionKeys.OPERATORS_WRITE },

    {
        path: '/organisation-types',
        component: OrganisationTypesPage,
        requiredPermissions: PermissionKeys.ORGANISATION_TYPES_READ,
    },
    {
        path: '/organisation-types/new',
        component: OrganisationTypeEditPage,
        requiredPermissions: PermissionKeys.ORGANISATION_TYPES_WRITE,
    },
    {
        path: '/organisation-types/:id/edit',
        component: OrganisationTypeEditPage,
        requiredPermissions: PermissionKeys.ORGANISATION_TYPES_WRITE,
    },

    { path: '/organisations', component: OrganisationsPage, requiredPermissions: PermissionKeys.ORGANISATIONS_READ },
    {
        path: '/organisations/new',
        component: OrganisationEditPage,
        requiredPermissions: PermissionKeys.ORGANISATIONS_WRITE,
    },
    {
        path: '/organisations/:id/edit',
        component: OrganisationEditPage,
        requiredPermissions: PermissionKeys.ORGANISATIONS_WRITE,
    },

    { path: '/suppliers', component: SuppliersPage, requiredPermissions: PermissionKeys.SUPPLIERS_READ },
    { path: '/suppliers/new', component: SupplierEditPage, requiredPermissions: PermissionKeys.SUPPLIERS_WRITE },
    { path: '/suppliers/:id/edit', component: SupplierEditPage, requiredPermissions: PermissionKeys.SUPPLIERS_WRITE },

    {
        path: '/stock-locations',
        component: StockLocationsPage,
        requiredPermissions: PermissionKeys.STOCK_LOCATIONS_READ,
    },
    {
        path: '/stock-locations/new',
        component: StockLocationEditPage,
        requiredPermissions: PermissionKeys.STOCK_LOCATIONS_WRITE,
    },
    {
        path: '/stock-locations/:id/edit',
        component: StockLocationEditPage,
        requiredPermissions: PermissionKeys.STOCK_LOCATIONS_WRITE,
    },

    { path: '/qr-codes', component: QrCodesPage, requiredPermissions: PermissionKeys.QR_CODES_READ },
    { path: '/qr-codes/new', component: QrCodeEditPage, requiredPermissions: PermissionKeys.QR_CODES_WRITE },
    { path: '/qr-codes/:id/edit', component: QrCodeEditPage, requiredPermissions: PermissionKeys.QR_CODES_WRITE },

    { path: '/settings', component: SettingsEditPage, requiredPermissions: PermissionKeys.SETTINGS_READ },
];
