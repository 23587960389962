import { ListAltTwoTone, RemoveShoppingCartOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IOperator, IStockLocation } from '../../../admin/models';
import {
    formatDateTimeString,
    IProduct,
    MeasurementUnit,
    PermissionKeys,
    RemoveModal,
    RowActions,
    TableComponent,
    useHasPermission,
    Widget,
} from '../../../shared';
import { useStockMovementDeallocateShipment } from '../../hooks';
import { IShipment } from '../../models';
import { useDeleteStockMovement } from '../../../stock/hooks';

interface Props {
    shipment: IShipment;
}
export const ShipmentDetailStockMovements: FC<Props> = ({ shipment }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SHIPMENTS_WRITE);

    const { mutateAsync: deleteStockMovement } = useDeleteStockMovement();
    const { mutateAsync: stockMovementDeallocateShipment } = useStockMovementDeallocateShipment();

    const onDeleteStockMovement = useCallback(
        async (stockMovement: string) => {
            await deleteStockMovement(stockMovement);
        },
        [deleteStockMovement],
    );

    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: t('createdDate'),
            valueFormatter: ({ value }) => formatDateTimeString(value as string),
            flex: 1,
            sortable: false,
            minWidth: 150,
        },
        {
            field: 'type',
            headerName: t('type'),
            valueFormatter: ({ value }) => t(value as string),
            flex: 1,
            sortable: false,
            minWidth: 100,
        },
        {
            field: 'operator',
            headerName: t('operator'),
            valueFormatter: ({ value }) => (value as IOperator)?.name,
            flex: 1,
            sortable: false,
            minWidth: 150,
        },
        {
            field: 'supplier',
            headerName: t('supplier'),
            flex: 1,
            sortable: false,
            minWidth: 150,
            renderCell: ({ row }: GridCellParams) => (row.batch ? row.batch.supplier?.name : row.supplier?.name),
        },
        {
            field: 'batch',
            headerName: t('batch'),
            valueFormatter: ({ value }) => value?.referenceNumber,
            flex: 1,
            minWidth: 150,
            sortable: false,
        },
        {
            field: 'stockLocation',
            headerName: t('stockLocation'),
            valueFormatter: ({ value }) => (value as IStockLocation)?.name,
            flex: 1,
            sortable: false,
            minWidth: 150,
        },
        {
            field: 'product',
            headerName: t('product'),
            valueFormatter: ({ value }) => (value as IProduct)?.name,
            flex: 1,
            sortable: false,
            minWidth: 200,
        },
        {
            field: 'amount',
            headerName: t('amount'),
            minWidth: 100,
            flex: 1,
            renderCell: ({ row }: GridCellParams) => (
                <>
                    {row.amount}{' '}
                    {!row.product ? '' : row.product?.measurementUnit === MeasurementUnit.GRAM ? t('gram') : t('PIECE')}
                </>
            ),
            sortable: false,
        },
        {
            field: 'totalWeight',
            headerName: t('totalWeight'),
            minWidth: 150,
            flex: 1,
            renderCell: ({ row }: GridCellParams) => <>{row.product && row.amount * (row.product.weight || 1)}</>,
            sortable: false,
        },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id: stockMovementId } }: GridCellParams) =>
                hasWritePermission && (
                    <>
                        <RowActions
                            onEdit={() =>
                                navigate(
                                    `/stock/movements/${stockMovementId}/edit?redirect=/planning/shipments/${shipment.id}/detail`,
                                )
                            }
                            onDelete={() => onDeleteStockMovement(stockMovementId)}
                            deleteWarningTitle={t('stockMovementDeleteWarningTitle')}
                            deleteWarningText={t('stockMovementDeleteWarningText')}
                        />
                        <RemoveModal
                            handleDelete={() =>
                                stockMovementDeallocateShipment({
                                    shipment: shipment.id,
                                    stockMovement: stockMovementId,
                                })
                            }
                            button={
                                <IconButton>
                                    <RemoveShoppingCartOutlined />
                                </IconButton>
                            }
                            title={t('shipmentUnlinkStockMovementTitle')}
                            text={t('shipmentUnlinkStockMovementText')}
                        />
                    </>
                ),
        },
    ];

    return (
        <Widget hasTable title={t('stockMovements')} icon={<ListAltTwoTone color="primary" />}>
            <TableComponent
                rows={shipment.stockMovements}
                columns={columns}
                onRowClick={(row) =>
                    hasWritePermission &&
                    navigate(`/stock/movements/${row.id}/edit?redirect=/planning/shipments/${shipment.id}/detail`)
                }
                mode="client"
                pageSizeOptions={[100]}
                hideFooter={shipment.stockMovements.length <= 100}
            />
        </Widget>
    );
};
