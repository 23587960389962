import { Search as SearchIcon } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    Page,
    PermissionKeys,
    RowActions,
    Search,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParamsContext,
} from '../../../shared';
import { useDeletePlanning, usePlanningsList } from '../../hooks';
import { CreatePlanningDialog } from '../../components';

export const PlanningsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.PLANNINGS_WRITE);
    const [openCreatePlanningDialog, setOpenCreatePlanningDialog] = useState(false);

    const {
        debouncedQueryParams: { search: debouncedSearch },
        queryParams: { page, pageSize, sort, search },
        setPage,
        setPageSize,
        setSort,
        setSearch,
    } = useQueryParamsContext('plannings', {
        sort: [{ field: 'organisation', sort: 'asc' }],
    });

    const { mutateAsync: deletePlanning } = useDeletePlanning();

    const { isPending, data } = usePlanningsList({
        page: page,
        pageSize: pageSize,
        sortBy: 'organisation.name',
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    const columns: GridColDef[] = [
        {
            field: 'organisation',
            headerName: t('organisation'),
            valueFormatter: ({ value }) => value?.name,
            minWidth: 150,
            flex: 1,
        },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/planning/plannings/${id}/detail`);
                        }}
                    >
                        <SearchIcon />
                    </IconButton>

                    {hasWritePermission && (
                        <RowActions
                            onEdit={undefined}
                            onDelete={() => deletePlanning(id)}
                            deleteWarningTitle={t('removePlanning')}
                            deleteWarningText={t('removePlanningWarning')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('plannings')}
            actions={[
                <Search search={search} onSearch={setSearch} />,
                hasWritePermission && (
                    <Button
                        onClick={() => setOpenCreatePlanningDialog(true)}
                        color="primary"
                        variant="contained"
                        data-testid="new-shipment"
                    >
                        {t('newPlanning')}
                    </Button>
                ),
            ]}
        >
            <TableComponent
                rows={data?.data || []}
                columns={columns}
                rowCount={data?.data.length}
                paginationModel={{ page: page ? page - 1 : 0, pageSize: pageSize || 10 }}
                onPaginationModelChange={(value) => {
                    setPage(value?.page + 1);
                    setPageSize(value?.pageSize || 5);
                }}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => navigate(`/planning/plannings/${row.id}/detail`)}
            />

            <CreatePlanningDialog
                open={openCreatePlanningDialog}
                onClose={() => setOpenCreatePlanningDialog(false)}
                plannings={data?.data || []}
            />
        </Page>
    );
};
