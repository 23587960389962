import { Day } from '../enums';
import { IPatternRequirementForm, IFormWeekEntry, IPlanningPatternForm, ISavePattern } from '../models';
import { mapDateToRequiredApiDate } from '../../shared';

export const mapPatternFormToDto = (form: IPlanningPatternForm): ISavePattern => {
    const mapRequirements = (requirements: IPatternRequirementForm[]) => {
        return (
            requirements.map((requirement) => ({
                ...requirement,
                productId: requirement.product?.id,
                categoryId: requirement.categoryId || undefined,
            })) || []
        );
    };

    return {
        ...form,
        amountOfWeeks: form.weeks.length,
        startDate: mapDateToRequiredApiDate(form.startDate),
        endDate: mapDateToRequiredApiDate(form.endDate),
        entries: form.weeks.reduce(
            (entries, week, weekIndex) => {
                const result = [
                    ...entries,
                    { dayOfWeek: 1, week: weekIndex + 1, requirements: mapRequirements(week.monday.requirements) },
                    { dayOfWeek: 2, week: weekIndex + 1, requirements: mapRequirements(week.tuesday.requirements) },
                    { dayOfWeek: 3, week: weekIndex + 1, requirements: mapRequirements(week.wednesday.requirements) },
                    { dayOfWeek: 4, week: weekIndex + 1, requirements: mapRequirements(week.thursday.requirements) },
                    { dayOfWeek: 5, week: weekIndex + 1, requirements: mapRequirements(week.friday.requirements) },
                    { dayOfWeek: 6, week: weekIndex + 1, requirements: mapRequirements(week.saturday.requirements) },
                    { dayOfWeek: 7, week: weekIndex + 1, requirements: mapRequirements(week.sunday.requirements) },
                ].filter((entry) => entry.requirements.length);

                Object.keys(week).forEach((day) => {
                    if (week[day as keyof IFormWeekEntry].isAppointmentOnly) {
                        result.push({
                            dayOfWeek: Number(Day[day as keyof typeof Day]),
                            week: weekIndex + 1,
                            requirements: [],
                        });
                    }
                });
                return result;
            },
            [] as ISavePattern['entries'],
        ),
    };
};
