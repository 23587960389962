import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { date, object } from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { ControlledDatePicker, FormGrid, mapDateToApiDate, Section, Widget } from '../../../shared';
import { Button, Stack } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

type FormData = { shipmentDate: Date };

export const ShipmentCompleteDatePage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const schema = object().shape({
        shipmentDate: date().typeError(t('invalidDate')).required(),
    });

    const form = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            shipmentDate: location?.search ? new Date(location?.search?.split('=').slice(1).toString()) : new Date(),
        },
        mode: 'all',
    });

    const onSubmit = async (item: FormData) => {
        navigate(`/planning/shipments/shipments-complete-wizard/${mapDateToApiDate(item.shipmentDate)}/shipments`);
    };

    return (
        <FormProvider {...form}>
            <Widget
                footer={
                    <Stack direction="row" justifyContent="space-between">
                        <Button startIcon={<ChevronLeft />} onClick={() => navigate('/planning/shipments')}>
                            {t('cancel')}
                        </Button>
                        <Button
                            endIcon={<ChevronRight />}
                            variant="contained"
                            color="primary"
                            onClick={form.handleSubmit(onSubmit)}
                        >
                            {t('next')}
                        </Button>
                    </Stack>
                }
            >
                <Section title={t('completeShipmentsDate')}>
                    <FormGrid>
                        <ControlledDatePicker name="shipmentDate" label="" required />
                    </FormGrid>
                </Section>
            </Widget>
        </FormProvider>
    );
};
