import { Box } from '@mui/material';
import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { ErrorFallback, ProtectedRoute, SideBar, SideNav } from '../../../shared';
import { planningSubRoutes } from '../../planning.routes';

export const PlanningPage: FC = () => {
    const { t } = useTranslation();
    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav
                    items={[
                        { path: '/planning/dashboard', text: t('dashboard') },
                        { path: '/planning/shipments', text: t('shipments') },
                        { path: '/planning/plannings', text: t('plannings') },
                    ]}
                />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {planningSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ProtectedRoute requiredPermission={subRoute.requiredPermissions}>
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        {<subRoute.component />}
                                    </ErrorBoundary>
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};
