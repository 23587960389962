import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    DashboardPage,
    PlanningDetailPage,
    PlanningPage,
    PlanningsPage,
    ShipmentDetailPage,
    ShipmentsCompleteWizardPage,
    ShipmentsPage,
    ShipmentWizardPage,
} from './pages';

export const planningRoutes: IRouteConfig[] = [
    { path: '/planning', redirect: '/planning/dashboard' },
    { path: '/planning/*', component: PlanningPage },
];

export const planningSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/dashboard', component: DashboardPage },
    { path: '/shipments', component: ShipmentsPage, requiredPermissions: PermissionKeys.SHIPMENTS_READ },
    {
        path: '/shipments/:id/detail',
        component: ShipmentDetailPage,
        requiredPermissions: PermissionKeys.SHIPMENTS_READ,
    },
    { path: '/shipments/new/*', component: ShipmentWizardPage, requiredPermissions: PermissionKeys.SHIPMENTS_WRITE },
    { path: '/shipments/:id/*', component: ShipmentWizardPage, requiredPermissions: PermissionKeys.SHIPMENTS_WRITE },
    {
        path: '/shipments/shipments-complete-wizard/*',
        component: ShipmentsCompleteWizardPage,
        requiredPermissions: PermissionKeys.SHIPMENTS_WRITE,
    },

    { path: '/plannings', component: PlanningsPage, requiredPermissions: PermissionKeys.PLANNINGS_READ },
    {
        path: '/plannings/:id/detail',
        component: PlanningDetailPage,
        requiredPermissions: PermissionKeys.PLANNINGS_READ,
    },
];
