import {
    IBatch,
    IBatchQuery,
    QueryKeys,
    useDelete,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
    useSave,
} from '../../shared';
import { batchesClient } from '../clients';

const client = batchesClient;
const listKey = QueryKeys.Batches;
const itemKey = QueryKeys.Batch;

export function useBatchesList(...args: UseListParams<IBatch, IBatchQuery>) {
    return useList(client, listKey, ...args);
}

export function useBatch(...args: UseItemParams<IBatch>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveBatch() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteBatch() {
    return useDelete(client, listKey);
}
