import { yupResolver } from '@hookform/resolvers/yup';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, MenuItem, Stack } from '@mui/material';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { date, object, string } from 'yup';
import {
    ControlledDatePicker,
    ControlledSelect,
    FormGrid,
    IParams,
    Loading,
    Section,
    Widget,
    IOrganisation,
    useOrganisationsList,
} from '../../../shared';
import { shipmentDataFromFormMapper, shipmentDataToFormMapper } from '../../mappers';
import { IShipmentDataForm } from '../../models';
import { useSaveShipment, useShipment } from '../../hooks';

const useSchema = () => {
    const { t } = useTranslation();

    return object().shape({
        shipmentDate: date().typeError(t('invalidDate')).required(),
        receiverId: string().required(),
    });
};

export const ShipmentDataPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();

    const { data: organisations, isPending: isOrganisationsLoading } = useOrganisationsList({ pageSize: 100 });
    const { mutateAsync: saveShipment, isPending: isPendingSave } = useSaveShipment();
    const { data: shipment } = useShipment(id);

    const isPending = isOrganisationsLoading;

    const schema = useSchema();
    const form = useForm<IShipmentDataForm>({
        resolver: yupResolver(schema),
        mode: 'all',
    });

    const onSubmit = async (item: IShipmentDataForm) => {
        const result = await saveShipment({
            id,
            item: shipmentDataFromFormMapper(item, shipment),
        });
        navigate(`/planning/shipments/${result.id}/content`);
    };

    useEffect(() => {
        if (shipment) {
            form.reset(shipmentDataToFormMapper(shipment));
        }
    }, [shipment, form]);

    return (
        <FormProvider {...form}>
            <Widget
                footer={
                    <Stack direction="row" justifyContent="space-between">
                        <Button startIcon={<ChevronLeft />} onClick={() => navigate('/planning/shipments')}>
                            {t('cancel')}
                        </Button>
                        <Button
                            endIcon={<ChevronRight />}
                            variant="contained"
                            color="primary"
                            onClick={form.handleSubmit(onSubmit)}
                            disabled={isPendingSave}
                        >
                            {t('next')}
                        </Button>
                    </Stack>
                }
            >
                {isPending ? (
                    <Loading />
                ) : (
                    <Section title={t('data')}>
                        <FormGrid>
                            <ControlledDatePicker name="shipmentDate" label={t('shipmentDate')} required />

                            <ControlledSelect name="receiverId" label={t('receiver')} required>
                                {(organisations?.data || []).map((organisation: IOrganisation) => (
                                    <MenuItem value={organisation.id} key={organisation.id}>
                                        {organisation.name}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        </FormGrid>
                    </Section>
                )}
            </Widget>
        </FormProvider>
    );
};
