import { BaseClient, logisticsApi } from '../../shared';
import { IOperator, ISaveOperator } from '../models';

class OperatorsClient extends BaseClient<IOperator, ISaveOperator> {
    constructor() {
        super(logisticsApi, '/operators');
    }
}

export const operatorsClient = new OperatorsClient();
