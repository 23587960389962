import { List, ListItem, ListItemText, ListSubheader } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { transparentize } from 'polished';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface IListItem {
    path: string;
    text: string;
}

interface ISubTitle {
    text: string;
    key: string;
}

interface Props {
    items: (ISubTitle | IListItem)[];
}

function isListItem(item: IListItem | ISubTitle): item is IListItem {
    return Boolean((item as IListItem).path);
}

const useStyles = makeStyles((theme) =>
    createStyles({
        listItem: {
            borderRadius: theme.shape.borderRadius,

            '&:hover, &:focus': {
                background: transparentize(0.95, theme.palette.primary.main),
                color: theme.palette.primary.main,
            },

            '&.Mui-selected': {
                background: transparentize(0.9, theme.palette.primary.main),
                color: theme.palette.primary.main,

                '& .MuiListItemText-primary': {
                    fontWeight: 'bold',
                },

                '&:hover, &:focus': {
                    background: transparentize(0.9, theme.palette.primary.main),
                },
            },
        },
        listSubheader: {
            fontVariant: 'all-small-caps',
        },
    }),
);

export const SideNav: FC<Props> = ({ items }) => {
    const classes = useStyles();
    const location = useLocation();

    return (
        <List component="div" sx={{ p: 3 }}>
            {items.map((item) =>
                isListItem(item) ? (
                    <ListItem
                        key={item.path}
                        className={classes.listItem}
                        button
                        selected={location.pathname.startsWith(item.path)}
                        component={Link}
                        to={item.path}
                    >
                        <ListItemText primary={item.text} />
                    </ListItem>
                ) : (
                    <ListSubheader className={classes.listSubheader} key={item.key}>
                        {item.text}
                    </ListSubheader>
                ),
            )}
        </List>
    );
};
