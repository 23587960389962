import { Drawer } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FC, ReactNode } from 'react';
import { useShowDrawer } from '../../contexts';

const useStyles = makeStyles((theme) =>
    createStyles({
        drawerContainer: {
            padding: `${theme.spacing(7)} 0`,
        },
    }),
);

type Props = {
    children?: ReactNode;
};

export const SideBar: FC<Props> = ({ children }) => {
    const classes = useStyles();

    const { showDrawer, setShowDrawer } = useShowDrawer();
    const handleDrawerToggle = () => {
        setShowDrawer(!showDrawer);
    };

    return (
        <>
            <Drawer
                sx={{
                    width: 240,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 240,
                        boxSizing: 'border-box',
                    },
                    display: { xs: 'block', xl: 'none' },
                }}
                variant="temporary"
                open={showDrawer}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <div className={classes.drawerContainer} onClick={handleDrawerToggle}>
                    {children}
                </div>
            </Drawer>

            <Drawer
                sx={{
                    width: 240,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 240,
                        boxSizing: 'border-box',
                    },
                    display: { xs: 'none', xl: 'block' },
                }}
                variant="persistent"
                open
            >
                <div className={classes.drawerContainer}>{children}</div>
            </Drawer>
        </>
    );
};
