import { BaseClient, logisticsApi } from '../../shared';
import { ISaveShipment, IShipment, IShipmentDashboard, IShipmentDashboardQuery } from '../models';

class ShipmentsClient extends BaseClient<IShipment, ISaveShipment> {
    constructor() {
        super(logisticsApi, '/shipments');
    }

    public async uploadAsset(id: string, file: File): Promise<void> {
        const formData = new FormData();
        formData.append('asset', file);
        await this.api.post<IShipment>(`${this.basePath}/${id}/assets`, formData);
    }

    public async deleteAsset(id: string, assetId: string): Promise<void> {
        await this.api.delete(`${this.basePath}/${id}/assets/${assetId}`);
    }

    public async notify(id: string): Promise<void> {
        await this.api.post(`${this.basePath}/${id}/notify`);
    }

    public async getShipmentDashboard(params: IShipmentDashboardQuery): Promise<IShipmentDashboard> {
        return (await this.api.get<IShipmentDashboard>(`${this.basePath}/dashboard`, { params })).data;
    }

    public async completeShipments(shipments: string[], sendMails: boolean): Promise<void> {
        await this.api.post(`${this.basePath}/complete-shipments`, { shipments, sendMails });
    }
}

export const shipmentsClient = new ShipmentsClient();
