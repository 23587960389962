import WarningIcon from '@mui/icons-material/Warning';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    open: boolean;
    onConfirm?: () => void;
    onClose: () => void;
    title: string | ReactNode;
    text: string | ReactNode;
}

export const WarningDialog: FC<Props> = ({ open, onConfirm, onClose, title, text }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Grid container direction="row" alignItems="center">
                    <WarningIcon color="secondary" style={{ marginRight: '0.5em' }} />
                    {title}
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {onConfirm ? (
                    <>
                        <Button onClick={onConfirm}>{t('yes')}</Button>
                        <Button variant="contained" onClick={onClose}>
                            {t('no')}
                        </Button>
                    </>
                ) : (
                    <Button onClick={onClose} color="secondary" autoFocus variant="contained" aria-label="confirm">
                        {t('ok')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
