import { FactCheck, WarningAmber } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { formatDateString, IOrganisation } from '../../../shared';
import { ShipmentStatus } from '../../enums';
import { useTranslation } from 'react-i18next';

interface Props {
    organisation?: IOrganisation;
    shipmentDate?: string;
    shipmentStatus?: ShipmentStatus;
    shipmentAmountOfDrafts?: number;
}

export const ProgressCategoriesHeader: FC<Props> = ({
    organisation,
    shipmentDate,
    shipmentStatus,
    shipmentAmountOfDrafts,
}) => {
    const { t } = useTranslation();

    return (
        <Stack direction="row" mb={1.5}>
            <Stack direction="row" sx={{ flexGrow: 1 }} alignItems="center" justifyContent="center">
                {shipmentStatus === ShipmentStatus.COMPLETED && (
                    <FactCheck fontSize="small" sx={{ color: 'green', mr: 1 }} />
                )}
                <Typography variant="subtitle2">
                    {organisation?.name} - {formatDateString(shipmentDate)}
                </Typography>
            </Stack>

            {!!shipmentAmountOfDrafts && (
                <Tooltip title={t('amountOfDrafts', { count: shipmentAmountOfDrafts })} arrow>
                    <Stack direction="row" sx={{ color: 'orange' }}>
                        <WarningAmber fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography variant="body1">{shipmentAmountOfDrafts}</Typography>
                    </Stack>
                </Tooltip>
            )}
        </Stack>
    );
};
