import { IProductCategory } from '../../admin/models';
import { mapDateToApiDate } from '../../shared';
import { IPlanningEntry, IPlanningEntryForm, ISavePlanningEntry } from '../models';

export const planningEntryToFormMapper = (
    planningEntry: IPlanningEntry,
    categories: IProductCategory[],
): IPlanningEntryForm => {
    return {
        ...planningEntry,
        date: new Date(planningEntry.date),
        requirements: [
            ...(categories.map((cat) => ({
                categoryId: cat.id,
                amount: planningEntry.requirements.find((req) => req.categoryId === cat.id)?.amount || 0,
                isCategory: true,
            })) || []),
            ...planningEntry.requirements
                .filter((req) => req.productId)
                .map((product) => ({
                    ...product,
                    amount: product.amount || 0,
                    isCategory: false,
                })),
        ],
    };
};

export const planningEntryFromFormMapper = (
    item: IPlanningEntryForm,
    updateFutureEntries: boolean,
): ISavePlanningEntry => {
    return {
        ...item,
        requirements: item.requirements
            .filter((req) => req.amount)
            .map((item) => ({
                ...item,
                productId: item.product?.id,
            })),

        date: mapDateToApiDate(item.date) || '',
        updateFutureEntries,
    };
};
