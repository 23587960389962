import React, { FC } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    formatDateString,
    IParams,
    Loading,
    Page,
    PermissionKeys,
    RemoveModal,
    useHasPermission,
} from '../../../shared';
import { useBatch, useDeleteBatch } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { BatchDetail, BatchStockMovements } from '../../components';
import { Button, Stack } from '@mui/material';

export const BatchesDetailPage: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.BATCHES_WRITE);

    const { data: batch } = useBatch(id);
    const { mutateAsync: deleteBatch } = useDeleteBatch();

    return batch ? (
        <Page
            title={t('detailBatch', {
                date: formatDateString(batch.date),
                supplier: batch.supplier.name,
                reference: batch.referenceNumber,
                interpolation: { escapeValue: false },
            })}
            onBack={() => navigate(-1)}
            actions={[
                hasWritePermission && (
                    <RemoveModal
                        handleDelete={() => {
                            deleteBatch(id);
                            navigate(`/stock/batches`);
                        }}
                        button={<Button>{t('delete')} </Button>}
                        title={t('batchDeleteWarningTitle')}
                        text={t('batchDeleteWarningText')}
                    />
                ),
                <Button
                    component={Link}
                    to={`/stock/batches/${id}/edit`}
                    color="primary"
                    variant="contained"
                    data-testid="edit-batch"
                >
                    {t('editBatch')}
                </Button>,
            ]}
        >
            <Stack spacing={2}>
                <BatchDetail batch={batch} />
                <BatchStockMovements />
            </Stack>
        </Page>
    ) : (
        <Loading />
    );
};
