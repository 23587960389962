import { MenuItem } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledNumberInput,
    ControlledSelect,
    FormGrid,
    IProductForm,
    MeasurementUnit,
    Section,
} from '../../../shared';

export const ProductMeasurement: FC = () => {
    const { control, setValue, trigger } = useFormContext<IProductForm>();
    const { t } = useTranslation();
    const measurementUnit = useWatch<IProductForm>({ name: 'measurementUnit', control });

    useEffect(() => {
        if (measurementUnit === MeasurementUnit.GRAM) {
            setValue('weight', undefined);
            trigger('weight');
        }
    }, [measurementUnit, setValue, trigger]);

    return (
        <Section title={t('measurementUnit')}>
            <FormGrid>
                <ControlledSelect label={t('measurementUnit')} name="measurementUnit" required>
                    <MenuItem value={MeasurementUnit.GRAM}>{t(MeasurementUnit.GRAM)}</MenuItem>
                    <MenuItem value={MeasurementUnit.PIECE}>{t(MeasurementUnit.PIECE)}</MenuItem>
                </ControlledSelect>
                <ControlledNumberInput
                    name="weight"
                    label={t('weight')}
                    required={measurementUnit === MeasurementUnit.PIECE}
                    disabled={measurementUnit !== MeasurementUnit.PIECE}
                />
            </FormGrid>
        </Section>
    );
};
