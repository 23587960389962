import { useProductCategoriesList } from '../../admin/hooks';
import {
    amber,
    blue,
    cyan,
    deepOrange,
    deepPurple,
    green,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
} from '@mui/material/colors';

const COLORS = [
    red['700'],
    pink['700'],
    purple['700'],
    deepPurple['700'],
    indigo['700'],
    blue['700'],
    lightBlue['700'],
    cyan['700'],
    teal['700'],
    green['700'],
    lightGreen['700'],
    lime['700'],
    yellow['700'],
    amber['700'],
    orange['700'],
    deepOrange['700'],
];

export function useAllCategories() {
    const { data: categories } = useProductCategoriesList({ pageSize: 100 });
    return categories?.data || [];
}

export function useColoredCategories() {
    const categories = useAllCategories();

    return (
        categories?.map((category, idx) => ({
            ...category,
            color: COLORS[idx % COLORS.length],
        })) || []
    );
}
