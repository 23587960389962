import { IOrganisation, IOrganisationForm, ISaveOrganisation } from '../../shared';

export const organisationToFormMapper = (item: IOrganisation): IOrganisationForm => {
    return {
        ...item,
        name: item.name,
        type: item.type.id,
    };
};

export const organisationFromFormMapper = (item: IOrganisationForm): ISaveOrganisation => {
    return {
        ...item,
        address: {
            street: item.address?.street || '',
            number: item.address?.number || '',
            postalCode: item.address?.postalCode || '',
            city: item.address?.city || '',
            country: item.address?.country || '',
        },
    };
};
