import { Filter } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { addDays, subDays } from 'date-fns';
import { useMemo } from 'react';
import { useAllCategories } from '../../planning/hooks';
import { StockMovementType } from '../../stock/enums';
import { cubejsApi } from '../clients';
import { IFilter } from '../models';
import { useFilterGenerator } from '../utils';
import { mapDateStringToApiDate, mapDateToApiDate } from '../../shared';

export enum TimeStamp {
    'shipmentdate',
    'createddate',
}

export const useTotalWeightPerCategoryReport = (
    filter: IFilter,
    stockMovementTypes: StockMovementType[],
    usedTimeStamp: TimeStamp = TimeStamp.createddate,
    differentiateOrganisations = false,
) => {
    const categories = useAllCategories();
    const categoryIds = useMemo(() => categories.map(({ id }) => id), [categories]);

    return useCubeQuery(
        {
            measures: ['StockMovement.totalWeight'],
            order: {
                'ProductCategory.name': 'asc',
            },
            filters: [
                {
                    member: 'StockMovement.deleteddate',
                    operator: 'notSet',
                },
                ...(filter.startDate ? getStartDateFilter(filter.startDate, usedTimeStamp) : []),
                ...(filter.endDate ? getEndDateFilter(filter.endDate, usedTimeStamp) : []),
                {
                    member: 'StockMovement.type',
                    operator: 'equals',
                    values: stockMovementTypes,
                },
                ...useFilterGenerator(filter.suppliers || [], 'StockMovement.supplierid'),
                ...useFilterGenerator(filter.organisations || [], 'StockMovement.organisationid'),
                ...useFilterGenerator(filter.productCategories || [...categoryIds, ''], 'ProductCategory.id'),
            ],
            dimensions: [
                ...(filter.showTotalCategories ? [] : ['ProductCategory.name']),
                ...(differentiateOrganisations ? ['StockMovement.organisationid'] : []),
            ],
            timeDimensions: [
                {
                    dimension:
                        usedTimeStamp === TimeStamp.shipmentdate
                            ? 'Shipment.shipmentdate'
                            : 'StockMovement.createddate',
                    granularity: filter.grouping || undefined,
                },
            ],
        },
        { cubejsApi, skip: categories.length === 0 },
    );
};

const getStartDateFilter = (startDate: string, usedTimeStamp: TimeStamp) => {
    // shipment date is without timestamp and therefore not included in afterDate, createdDate is included because it has a timestamp
    return [
        {
            member: usedTimeStamp === TimeStamp.shipmentdate ? 'Shipment.shipmentdate' : 'StockMovement.createddate',
            operator: 'afterDate',
            values: [
                usedTimeStamp === TimeStamp.shipmentdate
                    ? mapDateToApiDate(subDays(new Date(startDate), 1))
                    : mapDateStringToApiDate(startDate),
            ],
        } as Filter,
    ];
};

const getEndDateFilter = (endDate: string, usedTimeStamp: TimeStamp) => {
    // end date is not included in cube
    return [
        {
            member: usedTimeStamp === TimeStamp.shipmentdate ? 'Shipment.shipmentdate' : 'StockMovement.createddate',
            operator: 'beforeDate',
            values: [mapDateToApiDate(addDays(new Date(endDate), 1))],
        } as Filter,
    ];
};
