import { ChevronLeft, ChevronRight, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import {
    addDays,
    addMonths,
    addWeeks,
    endOfMonth,
    endOfWeek,
    format,
    startOfMonth,
    startOfWeek,
    subMonths,
    subWeeks,
} from 'date-fns';
import { transparentize } from 'polished';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MeasurementUnit, PermissionKeys, RemoveModal, useEntityMap, useHasPermission } from '../../../shared';
import { IFormWeekEntry, IPlanningEntry } from '../../models';
import { usePlanningEntries } from '../../hooks';
import { useProductCategoriesList } from '../../../admin/hooks';

enum CalendarView {
    MONTH = 'month',
    WEEK = 'week',
    WORKWEEK = 'workweek',
}

interface Props {
    planningId: string;
    defaultView?: CalendarView;
    addPlanningEntry: (date: string) => void;
    editPlanningEntry: (entry: IPlanningEntry) => void;
    deletePlanningEntry: (entry: IPlanningEntry) => void;
    date: Date;
    setDate: (date: Date) => void;
}

const WORKWEEK_DAYS: Array<keyof IFormWeekEntry> = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
const DAYS: Array<keyof IFormWeekEntry> = [...WORKWEEK_DAYS, 'saturday', 'sunday'];

const useStyles = makeStyles(({ palette, spacing }) =>
    createStyles({
        calendar: {
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            background: palette.divider,
            padding: 1,
            gap: 1,

            '&.workweek': {
                gridTemplateColumns: 'repeat(5, 1fr)',
            },
        },
        calendarHeader: {
            padding: spacing(1),
            background: palette.background.default,
            fontWeight: 'bold',
        },
        calendarCell: {
            minHeight: 150,
            background: palette.common.white,
        },
        calendarEntry: {
            background: transparentize(0.95, palette.primary.main),
            borderLeft: 'solid 2px',
            borderLeftColor: palette.primary.main,
            color: palette.primary.main,
            padding: spacing(1),
            fontSize: 14,
        },
    }),
);

export const Calendar: FC<Props> = ({
    planningId,
    defaultView = CalendarView.WORKWEEK,
    addPlanningEntry,
    editPlanningEntry,
    deletePlanningEntry,
    date,
    setDate,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.PLANNINGS_WRITE);

    const [view, setView] = useState<CalendarView>(defaultView);
    const days = view === 'workweek' ? WORKWEEK_DAYS : DAYS;

    const dates = useMemo(() => {
        let currentDate = date,
            endOfCalendar;

        if (view === CalendarView.WEEK) {
            endOfCalendar = endOfWeek(date, { weekStartsOn: 1 });
        } else if (view === CalendarView.WORKWEEK) {
            endOfCalendar = addDays(date, 5);
        } else {
            endOfCalendar = endOfWeek(endOfMonth(endOfWeek(date, { weekStartsOn: 1 })), { weekStartsOn: 1 });
        }

        const dates = [];
        while (currentDate < endOfCalendar) {
            dates.push({
                date: format(currentDate, 'yyyy-MM-dd'),
                formatted: format(currentDate, 'dd/MM'),
            });
            currentDate = addDays(currentDate, 1);
        }
        return dates;
    }, [view, date]);

    const { data: entries } = usePlanningEntries(planningId, {
        startDate: dates[0].date,
        endDate: dates[dates.length - 1].date,
    });

    const datesWithEntries = useMemo(
        () => dates.map((date) => ({ ...date, entries: entries?.filter((entry) => entry.date === date.date) || [] })),
        [dates, entries],
    );
    const { data: categories } = useProductCategoriesList({ pageSize: 100 });
    const categoriesMap = useEntityMap(categories);

    const onChangeView = useCallback(
        (view: CalendarView) => {
            setView(view);
            setDate(
                view === CalendarView.MONTH
                    ? startOfWeek(startOfMonth(date), { weekStartsOn: 1 })
                    : startOfWeek(date, { weekStartsOn: 1 }),
            );
        },
        [date, setDate],
    );

    const onNext = useCallback(() => {
        if (view === CalendarView.MONTH) {
            setDate(
                startOfWeek(addMonths(startOfMonth(endOfWeek(date, { weekStartsOn: 1 })), 1), {
                    weekStartsOn: 1,
                }),
            );
        } else {
            setDate(addWeeks(date, 1));
        }
    }, [view, setDate, date]);

    const onPrevious = useCallback(() => {
        if (view === CalendarView.MONTH) {
            setDate(
                startOfWeek(subMonths(startOfMonth(endOfWeek(date, { weekStartsOn: 1 })), 1), {
                    weekStartsOn: 1,
                }),
            );
        } else {
            setDate(subWeeks(date, 1));
        }
    }, [view, setDate, date]);

    return (
        <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton size="small" onClick={onPrevious}>
                        <ChevronLeft />
                    </IconButton>
                    <Typography variant="button">
                        {dates[0].formatted} - {dates[dates.length - 1].formatted}
                    </Typography>
                    <IconButton size="small" onClick={onNext}>
                        <ChevronRight />
                    </IconButton>
                </Stack>
                <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={view}
                    exclusive
                    onChange={(e, value) => onChangeView(value)}
                >
                    <ToggleButton value="workweek">{t('workweek')}</ToggleButton>
                    <ToggleButton value="week">{t('week')}</ToggleButton>
                    <ToggleButton value="month">{t('month')}</ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Box className={`${classes.calendar} ${view}`}>
                {days.map((day) => (
                    <Box className={classes.calendarHeader} key={day}>
                        {t(day)}
                    </Box>
                ))}
                {datesWithEntries.map((date) => (
                    <Box
                        className={classes.calendarCell}
                        key={date.date}
                        onDoubleClick={() => hasWritePermission && addPlanningEntry(date.date)}
                    >
                        <Typography sx={{ fontSize: 12, pt: 1, pl: 1 }} color="text.secondary">
                            {date.formatted}
                        </Typography>
                        <Box sx={{ p: 1 }}>
                            {date.entries.map((entry) => (
                                <Box className={classes.calendarEntry} sx={{ mb: 1 }}>
                                    <Stack direction="row" justifyContent="space-between">
                                        {view === CalendarView.MONTH ? (
                                            <Typography>{t('appointment')}</Typography>
                                        ) : (
                                            <Stack>
                                                {entry?.requirements?.map((requirement) => (
                                                    <Typography key={requirement.id}>
                                                        {requirement.categoryId ? (
                                                            <>
                                                                {categoriesMap[requirement.categoryId]}:{' '}
                                                                {requirement.amount}gr
                                                            </>
                                                        ) : (
                                                            <>{`${requirement.product?.name}: ${requirement.amount}${
                                                                requirement.product?.measurementUnit ===
                                                                MeasurementUnit.GRAM
                                                                    ? 'gr'
                                                                    : 'st'
                                                            }`}</>
                                                        )}
                                                    </Typography>
                                                ))}
                                                {entry.requirements.length === 0 && (
                                                    <Typography>{t('appointmentWithoutRequirements')}</Typography>
                                                )}
                                            </Stack>
                                        )}
                                        {hasWritePermission && (
                                            <Stack direction="row">
                                                {!(
                                                    entry.patternEntry &&
                                                    entry.requirements?.find((r) => r.product?.requiresReservation)
                                                ) && (
                                                    <Box>
                                                        <IconButton
                                                            onClick={() => {
                                                                if (entry) {
                                                                    editPlanningEntry(entry);
                                                                }
                                                            }}
                                                            size="small"
                                                        >
                                                            <EditOutlined />
                                                        </IconButton>
                                                    </Box>
                                                )}
                                                <Box>
                                                    <RemoveModal
                                                        handleDelete={() => {
                                                            if (entry) {
                                                                deletePlanningEntry(entry);
                                                            }
                                                        }}
                                                        button={
                                                            <IconButton aria-label="delete">
                                                                <DeleteOutline />
                                                            </IconButton>
                                                        }
                                                        title={t('deletePlanningEntryWarningTitle')}
                                                        text={t('deletePlanningEntryWarningText')}
                                                    />
                                                </Box>
                                            </Stack>
                                        )}
                                    </Stack>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>
        </Stack>
    );
};
