import { ChartPivotRow, ResultSet } from '@cubejs-client/core';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useColoredCategories } from '../../planning/hooks';
import { IFilter } from '../models';
import { mapReportDate } from '../utils';

export function useMapDataFoodDistributionBarChart(
    filter: IFilter,
    usedCategories: string[],
    actualResultSet?: ResultSet,
    plannedResultSet?: ResultSet,
) {
    const { t } = useTranslation();
    const categories = useColoredCategories();

    return useMemo(() => {
        const chartActual = actualResultSet?.chartPivot() || [];
        const chartPlanned = plannedResultSet?.chartPivot() || [];

        //extra check for new resultSet with grouping is loaded
        if (filter.grouping && !chartActual?.some(({ x }) => categories.some(({ name }) => name === x))) {
            if (filter.showPlanned) {
                //grouped and planned
                return (
                    mapReportDate(
                        joinActualAndPlannedByDateGroupAndCategory(chartActual, chartPlanned, usedCategories, t),
                        'x',
                        filter.grouping,
                    ) || []
                );
            }
            //grouped and not planned
            const result =
                chartActual?.map((value) => ({
                    ...value,
                    [`${t('unknown')},StockMovement.totalWeight`]: value['StockMovement.totalWeight'],
                })) || [];
            return mapReportDate(result, 'x', filter.grouping);
        } else {
            if (filter.showPlanned) {
                //not grouped and planned
                return joinActualAndPlannedByCategory(chartActual, chartPlanned, usedCategories, t, filter);
            }

            //not grouped and not planned
            return actualResultSet
                ?.chartPivot()
                .map((value) =>
                    value.x == '∅' || value.x == ''
                        ? { ...value, x: value.x == '∅' ? t('unknown') : t('allCategories') }
                        : value,
                );
        }
    }, [actualResultSet, plannedResultSet, filter, categories, usedCategories, t]);
}

const joinActualAndPlannedByDateGroupAndCategory = (
    chartActual: ChartPivotRow[],
    chartPlanned: ChartPivotRow[],
    usedCategories: string[],
    t: TFunction,
): ChartPivotRow[] => {
    const result: any = {};

    chartActual?.forEach((act) => {
        result[act.x] = {
            ...usedCategories.reduce((r: any, cat) => ((r[`${cat},StockMovement.totalWeight`] = 0), r), {}),
            ...usedCategories.reduce((r: any, cat) => ((r[`${cat},Requirement.amount`] = 0), r), {}),
            ...act,
            [`${t('unknown')},StockMovement.totalWeight`]: act['StockMovement.totalWeight'] || 0,
        };
    });
    chartPlanned?.forEach((act) => {
        result[act.x] = {
            ...usedCategories.reduce((r: any, cat) => ((r[`${cat},StockMovement.totalWeight`] = 0), r), {}),
            ...usedCategories.reduce((r: any, cat) => ((r[`${cat},Requirement.amount`] = 0), r), {}),
            ...result[act.x],
            ...act,
            [`${t('unknown')},Requirement.amount`]: act['Requirement.amount'] || 0,
        };
    });
    return Object.values(result);
};

const joinActualAndPlannedByCategory = (
    chartActual: any[],
    chartPlanned: any[],
    usedCategories: string[],
    t: TFunction,
    filter: IFilter,
) => {
    if (filter.showTotalCategories) {
        const actualWeight =
            Number(chartActual?.find((item: any) => item.x === '')?.['StockMovement.totalWeight']) || 0;
        const plannedWeight = Number(chartPlanned?.find((item: any) => item.x === '')?.['Requirement.amount']) || 0;
        return [
            { x: t('allCategories'), 'StockMovement.totalWeight': actualWeight, 'Requirement.amount': plannedWeight },
        ];
    }
    return usedCategories.map((category) => {
        const actualWeight =
            Number(
                chartActual
                    ?.map((value) => (value.x == '∅' || value.x == '' ? { ...value, x: t('unknown') } : value))
                    ?.find((item: any) => item.x === category)?.['StockMovement.totalWeight'],
            ) || 0;
        const plannedWeight =
            Number(
                chartPlanned
                    ?.map((value) => (value.x == '∅' || value.x == '' ? { ...value, x: t('unknown') } : value))
                    ?.find((item: any) => item.x === category)?.['Requirement.amount'],
            ) || 0;

        return {
            x: category,
            'StockMovement.totalWeight': actualWeight,
            'Requirement.amount': plannedWeight,
        };
    }) as any[];
};
