import {
    IQrCode,
    IQrCodeQuery,
    QueryKeys,
    useDelete,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
    useSave,
} from '../../shared';
import { qrCodesClient } from '../clients';

const client = qrCodesClient;
const listKey = QueryKeys.QrCodes;
const itemKey = QueryKeys.QrCode;

export function useQrCodesList(...args: UseListParams<IQrCode, IQrCodeQuery>) {
    return useList(client, listKey, ...args);
}

export function useQrCode(...args: UseItemParams<IQrCode>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveQrCode() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteQrCode() {
    return useDelete(client, listKey);
}
