import { ShipmentStatus } from '../enums';
import { ISaveShipment, IShipment, IShipmentDataForm } from '../models';
import { format } from 'date-fns';

export const shipmentDataToFormMapper = (item: IShipment): IShipmentDataForm => {
    return {
        ...item,
        receiverId: item.receiver.id,
        shipmentDate: new Date(item.shipmentDate),
    };
};

export const shipmentDataFromFormMapper = (item: IShipmentDataForm, originalShipment?: IShipment): ISaveShipment => {
    return {
        ...item,
        shipmentDate: format(item.shipmentDate, 'yyyy-MM-dd'),
        stockMovements: originalShipment?.stockMovements.map(({ id }) => id),
        status: originalShipment?.status || ShipmentStatus.DRAFT,
    };
};
