export enum StockMovementType {
    // From supplier to stock location
    IN_STOCK = 'IN_STOCK',

    // From stock location to organisation
    OUT_STOCK = 'OUT_STOCK',

    // Directly from supplier to organisation
    DISTRIBUTION = 'DISTRIBUTION',
}
