import { Box, MenuItem } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    checkDifferentFilterAndFormValues,
    ControlledDatePicker,
    ControlledSelect,
    FormGrid,
    IOrganisation,
    Options,
    OptionsToggle,
    useOrganisationsList,
} from '../../../shared';
import { ShipmentMailStatus, ShipmentStatus } from '../../enums';
import { IShipmentQuery } from '../../models';

interface Props {
    filter: Record<string, any>;
    onChange: (filter: IShipmentQuery & { options: Options }) => void;
}

export const ShipmentsFilter: FC<Props> = ({ filter, onChange }) => {
    const { t } = useTranslation();
    const form = useForm<IShipmentQuery>({ mode: 'onChange' });

    const defaultOptions: Options = useMemo(() => {
        return {
            filter: { type: 'title', label: t('filter') },
            shipmentDate: { label: t('shipmentDate'), active: false },
            organisation: { label: t('organisation'), active: false },
            status: { label: t('status'), active: false },
            mailStatus: { label: t('mailStatus'), active: false },
        };
    }, [t]);

    const filterOptions: Options = useMemo(() => {
        return filter.options || defaultOptions;
    }, [defaultOptions, filter.options]);

    const setOptions = useCallback((options: Options) => onChange({ ...filter, options }), [filter, onChange]);

    const { data: organisations } = useOrganisationsList({ pageSize: 100 });

    const formValues = form.watch();

    useEffect(() => {
        form.reset(filter);
    }, [filter, form, form.reset]);

    useEffect(() => {
        const { shipmentDate, status, organisation, mailStatus } = filterOptions;
        if (!shipmentDate.active) {
            form.setValue('shipmentDate', undefined);
        }

        if (!status.active) {
            form.setValue('status', undefined);
        }

        if (!organisation.active) {
            form.setValue('receiverId', undefined);
        }

        if (!mailStatus.active) {
            form.setValue('mailStatus', undefined);
        }
    }, [filterOptions, form]);

    useEffect(() => {
        if (!!Object.keys(formValues).length && checkDifferentFilterAndFormValues(formValues, filter)) {
            onChange({ ...formValues, options: filterOptions });
        }
    }, [formValues, filter, filterOptions, onChange]);

    return (
        <FormProvider {...form}>
            <Box>
                <FormGrid alignItems="center">
                    <OptionsToggle options={filterOptions} onChange={setOptions} />

                    {filterOptions.shipmentDate.active && (
                        <ControlledDatePicker name="shipmentDate" label={t('shipmentDate')} size="small" />
                    )}

                    {filterOptions.organisation.active && (
                        <ControlledSelect name="receiverId" label={t('organisation')} size="small">
                            <MenuItem value="">{t('all')}</MenuItem>
                            {(organisations?.data || []).map((organisation: IOrganisation) => (
                                <MenuItem value={organisation.id} key={organisation.id}>
                                    {organisation.name}
                                </MenuItem>
                            ))}
                        </ControlledSelect>
                    )}

                    {filterOptions.status.active && (
                        <ControlledSelect name="status" label={t('status')} size="small">
                            <MenuItem value="">{t('all')}</MenuItem>
                            {Object.values(ShipmentStatus).map((status) => (
                                <MenuItem value={status} key={status}>
                                    {t(status)}
                                </MenuItem>
                            ))}
                        </ControlledSelect>
                    )}

                    {filterOptions.mailStatus.active && (
                        <ControlledSelect name="mailStatus" label={t('mailStatus')} size="small">
                            <MenuItem value="">{t('all')}</MenuItem>
                            {Object.values(ShipmentMailStatus).map((status) => (
                                <MenuItem value={status} key={status}>
                                    {t(status)}
                                </MenuItem>
                            ))}
                        </ControlledSelect>
                    )}
                </FormGrid>
            </Box>
        </FormProvider>
    );
};
