import { QueryKeys, useItem, UseItemParams, useList, UseListParams } from '../../shared';
import { stockProductsClient } from '../clients';
import { IStockProduct } from '../models';

const client = stockProductsClient;
const listKey = QueryKeys.StockProducts;
const itemKey = QueryKeys.StockProduct;

export function useStockProductsList(...args: UseListParams<IStockProduct>) {
    return useList(client, listKey, ...args);
}

export function useStockProduct(...args: UseItemParams<IStockProduct>) {
    return useItem(client, itemKey, ...args);
}
