import { formatDateStringToMonth, formatDateStringToQuarter, formatDateStringToYear } from '../../shared';
import { TimeGranularity } from '../enums';

export const mapReportDate = (data: any[], attribute: string, granularity: TimeGranularity) => {
    const granularityFunctions = {
        [TimeGranularity.MONTH]: formatDateStringToMonth,
        [TimeGranularity.QUARTER]: formatDateStringToQuarter,
        [TimeGranularity.YEAR]: formatDateStringToYear,
    };

    return data.map((item: any) => ({
        ...item,
        [attribute]: granularityFunctions[granularity](item[attribute] as string),
    }));
};
