import { Warning } from '@mui/icons-material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';
import { IBlacklistedProduct } from '../../../admin/models';
import { formatDateString } from '../../../shared';

interface Props {
    show: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    blacklistProducts: IBlacklistedProduct[];
}

export const BlacklistedProductWarning: FC<Props> = ({ show, onConfirm, onCancel, blacklistProducts }) => {
    const { t } = useTranslation();
    return (
        <Dialog open={show}>
            <DialogTitle>
                <Stack direction="row" alignItems="center">
                    <Warning color="warning" /> {t('blackListedProductWarningTitle')}
                </Stack>
            </DialogTitle>
            <DialogContent>
                {t('blackListedProductWarningTextPart1')}
                <br />
                <List>
                    {blacklistProducts.map((product) => (
                        <ListItem>
                            <ListItemText>
                                <Typography>
                                    {product?.blockForAllOrganisations
                                        ? t('allOrganisations')
                                        : product.organisation?.name || ''}
                                </Typography>

                                {product?.category && `${t('category')}: ${product?.category?.name} \n`}
                                {product?.partialProductName &&
                                    `${t('partialProductName')}: ${product?.partialProductName} \n`}
                                {product?.productCode && `${t('productCode')}: ${product?.productCode} \n`}

                                <Typography>
                                    {t('timePeriod')}:
                                    {product?.isPermanent
                                        ? ` ${t('isPermanent')}`
                                        : ` ${formatDateString(product?.startDate)} - ${formatDateString(
                                              product?.endDate,
                                          )}`}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
                <b>{t('blackListedProductWarningTextPart2')}</b>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm}>{t('yes')}</Button>
                <Button variant="contained" onClick={onCancel}>
                    {t('no')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
