import { BaseClient, logisticsApi } from '../../shared';
import { ISaveSupplier, ISupplier } from '../../shared/models/supplier.model';

class SuppliersClient extends BaseClient<ISupplier, ISaveSupplier> {
    constructor() {
        super(logisticsApi, '/suppliers');
    }
}

export const suppliersClient = new SuppliersClient();
