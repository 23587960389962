import { TextField } from '@mui/material';
import { ComponentProps, FC } from 'react';
import { Controller } from 'react-hook-form';

type Props = ComponentProps<typeof TextField> & {
    name: string;
};

export const ControlledInput: FC<Props> = ({ name, ...textFieldProps }) => {
    return (
        <Controller
            name={name}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    id={`${name}-field`}
                    {...textFieldProps}
                />
            )}
        />
    );
};
