import { useTranslation } from 'react-i18next';

export const PageNotFound = () => {
    const { t } = useTranslation();
    return (
        <div>
            <img src="https://i.imgur.com/oCkEbrA.png" alt="" />
            <div id="info">
                <h3>{t('pageNotFoundErr')}</h3>
            </div>
        </div>
    );
};
