import { QueryKeys } from '../../shared';
import { planningsClient } from '../clients';
import { IPlanningEntry, IPlanningEntryQuery, ISavePlanningEntry } from '../models';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export function usePlanningEntries(
    planningId: string,
    query: IPlanningEntryQuery,
): UseQueryResult<IPlanningEntry[], AxiosError> {
    return useQuery<IPlanningEntry[], AxiosError>({
        queryKey: [QueryKeys.Planning, planningId, query],
        queryFn: () => planningsClient.getPlanningEntries(planningId, query),
        enabled: !!query.startDate && !!query.endDate,
    });
}

export function useSavePlanningEntry(): UseMutationResult<
    IPlanningEntry,
    AxiosError,
    { planning: string; entry: ISavePlanningEntry }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ planning, entry }) =>
            entry.id
                ? planningsClient.updatePlanningEntry(planning, entry)
                : planningsClient.createPlanningEntry(planning, entry),

        onSuccess: async (r, { planning }) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Planning, planning] });
        },
    });
}

export function useDeletePlanningEntry(): UseMutationResult<
    IPlanningEntry,
    AxiosError,
    { planning: string; entry: string; includeFutureEntries: boolean }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ planning, entry, includeFutureEntries }) =>
            planningsClient.deletePlanningEntry(planning, entry, includeFutureEntries),
        onSuccess: async (r, { planning }) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Planning, planning] });
        },
    });
}
