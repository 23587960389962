import { BarChartTwoTone, PieChartTwoTone } from '@mui/icons-material';
import { startOfMonth } from 'date-fns';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Widget } from '../../../shared';
import { StockMovementType } from '../../../stock/enums';
import {
    FoodDistributionBarChart,
    FoodDistributionPieChart,
    FoodDistributionTable,
    ReportFilter,
} from '../../components';
import { Report } from '../../enums/report.enum';
import { TimeStamp, usePlannedWeightPerCategoryReport, useTotalWeightPerCategoryReport } from '../../hooks';
import { IFilter } from '../../models';

export const ReportFoodDistributionRedistributed: FC = () => {
    const { t } = useTranslation();

    const [filter, setFilter] = useState<IFilter>({
        startDate: startOfMonth(new Date()).toString(),
        showTotalCategories: false,
        showPlanned: false,
    });

    const { resultSet: actualResultSet, isLoading: actualIsLoading } = useTotalWeightPerCategoryReport(
        filter,
        [StockMovementType.DISTRIBUTION, StockMovementType.OUT_STOCK],
        TimeStamp.shipmentdate,
    );
    const { resultSet: plannedResultSet, isLoading: plannedIsLoading } = usePlannedWeightPerCategoryReport(filter);

    return (
        <Page
            title={t('reportFoodDistributionRedistributed')}
            filter={<ReportFilter filter={filter} onChange={setFilter} report={Report.REDISTRIBUTED} />}
            loading={actualIsLoading || plannedIsLoading}
        >
            {!actualIsLoading && !plannedIsLoading && actualResultSet && plannedResultSet && (
                <>
                    <Widget title={t('barChart')} icon={<BarChartTwoTone color="primary" />}>
                        <FoodDistributionBarChart
                            actualResultSet={actualResultSet}
                            plannedResultSet={plannedResultSet}
                            filter={filter}
                        />
                    </Widget>
                    <br />
                    {!filter.grouping && !filter.showTotalCategories && (
                        <Widget title={t('pieChart')} icon={<PieChartTwoTone color="primary" />}>
                            <FoodDistributionPieChart resultSet={actualResultSet} />
                        </Widget>
                    )}
                    <br />
                    <FoodDistributionTable
                        filter={filter}
                        usedTimeStamp={TimeStamp.shipmentdate}
                        stockMovementTypes={[StockMovementType.DISTRIBUTION, StockMovementType.OUT_STOCK]}
                        differentiateOrganisations
                    />
                </>
            )}
        </Page>
    );
};
