import React, { ComponentProps, FC } from 'react';
import { useWatch } from 'react-hook-form';
import { ControlledNumberInput, MeasurementUnit } from '../../../shared';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = ComponentProps<typeof TextField> & {
    path: string;
    disabledWhenProductRequiresReservation?: boolean;
};

export const EntryAmount: FC<Props> = ({ path, disabledWhenProductRequiresReservation = false, ...props }) => {
    const { t } = useTranslation();
    const watchIsCategory = useWatch({ name: `${path}.isCategory` });
    const watchProduct = useWatch({ name: `${path}.product` });

    return (
        <>
            {disabledWhenProductRequiresReservation && watchProduct?.requiresReservation ? (
                <TextField value={t('automaticDistribution')} sx={{ fontSize: '10px' }} size="small" disabled />
            ) : (
                <ControlledNumberInput
                    size="small"
                    name={`${path}.amount`}
                    {...props}
                    InputProps={{
                        endAdornment: (
                            <Box sx={{ color: 'grey', fontSize: '0.8rem' }}>
                                {`${
                                    watchIsCategory || watchProduct?.measurementUnit == MeasurementUnit.GRAM
                                        ? ` ${t('GRAM')}`
                                        : ` ${t('PIECE')}`
                                }`}
                            </Box>
                        ),
                    }}
                />
            )}
        </>
    );
};
