import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Stack,
    TextField,
} from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDevice, useStartEnrollment } from '../../hooks';

interface Props {
    id: string;
    open: boolean;
    setOpen: (open: boolean) => void;
}

enum EnrollmentStep {
    Start,
    Complete,
}

export const EnrollDeviceDialog: FC<Props> = ({ id, open, setOpen }) => {
    const { t } = useTranslation();
    const [step, setStep] = useState(EnrollmentStep.Start);
    const { data: device } = useDevice(id);
    const { mutateAsync: startEnrollment, data: enrollment, isPending } = useStartEnrollment();

    const onStart = useCallback(async () => {
        if (device) {
            await startEnrollment({ deviceCode: device.code });
            setStep(EnrollmentStep.Complete);
        }
    }, [device, startEnrollment]);

    const onCancel = useCallback(() => {
        setStep(EnrollmentStep.Start);
        setOpen(false);
    }, [setOpen]);

    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>
                <Grid container direction="row" alignItems="center">
                    {t('enrollmentTitle')}
                </Grid>
            </DialogTitle>
            <DialogContent sx={{ width: 400 }}>
                {isPending && <CircularProgress />}
                {!isPending && step === EnrollmentStep.Start && (
                    <Stack spacing={3}>
                        {device?.enrollment?.completed && (
                            <Alert severity="warning">{t('enrollmentAlreadyCompleted')}</Alert>
                        )}
                        <DialogContentText>{t('enrollmentStartSummary')}</DialogContentText>
                    </Stack>
                )}
                {!isPending && step === EnrollmentStep.Complete && (
                    <Stack spacing={3}>
                        <DialogContentText>{t('enrollmentCompleteSummary')}</DialogContentText>
                        <TextField
                            label={t('enrollmentDeviceCode')}
                            value={enrollment?.deviceCode}
                            fullWidth
                            disabled
                        />
                        <TextField label={t('enrollmentPublicKey')} value={enrollment?.publicKey} fullWidth disabled />
                    </Stack>
                )}
            </DialogContent>
            <DialogActions>
                {step === EnrollmentStep.Start && (
                    <Button
                        onClick={onStart}
                        color="secondary"
                        autoFocus
                        variant="contained"
                        aria-label="confirm-delete"
                    >
                        {t('startEnrollment')}
                    </Button>
                )}
                <Button onClick={onCancel} color="secondary">
                    {t(step === EnrollmentStep.Start ? 'cancel' : 'ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
