import { Box, Button, Link } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    useMapDataFoodDistributionTable,
    rowItem,
    TimeStamp,
    usePlannedWeightPerCategoryReport,
    useTotalWeightPerCategoryReport,
} from '../../hooks';
import { IFilter } from '../../models';
import { Loading, Widget } from '../../../shared';
import { ListAltTwoTone } from '@mui/icons-material';
import { useCsvExport } from '../../utils';
import { StockMovementType } from '../../../stock/enums';

interface Props {
    filter: IFilter;
    usedTimeStamp: TimeStamp;
    stockMovementTypes: StockMovementType[];
    differentiateOrganisations?: boolean;
}

export const FoodDistributionTable: FC<Props> = ({
    filter,
    usedTimeStamp,
    stockMovementTypes,
    differentiateOrganisations,
}) => {
    const { t } = useTranslation();

    const { resultSet: actualResultSet, isLoading: actualIsLoading } = useTotalWeightPerCategoryReport(
        filter,
        stockMovementTypes,
        usedTimeStamp,
        differentiateOrganisations,
    );
    const { resultSet: plannedResultSet, isLoading: plannedIsLoading } = usePlannedWeightPerCategoryReport(
        filter,
        differentiateOrganisations,
    );
    const dataSource = useMapDataFoodDistributionTable(filter, actualResultSet, plannedResultSet, usedTimeStamp);

    const columns = [
        ...(filter?.grouping
            ? [
                  {
                      field: 'date',
                      headerName: t(filter.grouping),
                      flex: 1,
                      sortable: false,
                      filterable: false,
                  },
              ]
            : []),
        ...(differentiateOrganisations
            ? [
                  {
                      field: 'organisation',
                      headerName: t(`organisation`),
                      flex: 1,
                      sortable: false,
                      filterable: false,
                  },
              ]
            : []),
        ...(filter.showTotalCategories
            ? []
            : [
                  {
                      field: 'ProductCategory.name',
                      headerName: t(`report.productcategory.name`),
                      flex: 1,
                      sortable: false,
                      filterable: false,
                  },
                  {
                      field: 'totalPrice',
                      headerName: t(`totalPrice`),
                      flex: 1,
                      sortable: false,
                      filterable: false,
                  },
              ]),
        {
            field: 'StockMovement.totalWeight',
            headerName: t(`report.stockmovement.totalweight`),
            flex: 1,
            sortable: false,
            filterable: false,
        },
        ...(filter?.showPlanned
            ? [
                  {
                      field: 'Requirement.amount',
                      headerName: t(`planned`),
                      flex: 1,
                      sortable: false,
                      filterable: false,
                  },
              ]
            : []),
    ];

    const csvExport = useCsvExport(columns || [], dataSource);

    return (
        <Widget
            title={t('data')}
            icon={<ListAltTwoTone color="primary" />}
            hasTable
            actions={
                <Button variant="contained" href={csvExport} component={Link} download={t(`report.foodDistribution`)}>
                    {t('export')}
                </Button>
            }
        >
            <Box sx={{ '& .row-total': { fontWeight: 'bold' } }}>
                {actualIsLoading || plannedIsLoading ? (
                    <Loading />
                ) : (
                    <DataGrid
                        autoHeight
                        columns={columns || []}
                        rows={dataSource || []}
                        getRowId={(row: rowItem) =>
                            row['StockMovement.organisationid'] + row['ProductCategory.name'] + row['date'] || ''
                        }
                        hideFooter={dataSource?.length <= 100}
                        getRowClassName={(params) => `row-${params.row.key}`}
                    />
                )}
            </Box>
        </Widget>
    );
};
