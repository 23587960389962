import { Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    search: string;
    onSearch: (search: string) => void;
}

export const Search: FC<Props> = ({ search, onSearch }) => {
    const { t } = useTranslation();
    return (
        <TextField
            value={search}
            onChange={(e) => onSearch(e.target.value)}
            label={t('search')}
            size="small"
            data-testid="search-input"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};
