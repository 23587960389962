import { Button, Stack, Typography } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    formatDateString,
    mapDateStringToApiDate,
    Page,
    PermissionKeys,
    RowActions,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParams,
} from '../../../shared';
import { BlacklistedProductsFilter } from '../../components';
import { useBlackListedProductsList, useDeleteBlackListedProduct } from '../../hooks';
import { IBlacklistedProductQuery } from '../../models';

export const BlacklistedProductsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.BLACKLISTED_PRODUCTS_WRITE);
    const [filter, setFilter] = useState<IBlacklistedProductQuery>({});

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'createdDate', sort: 'desc' }],
    });

    const { data, isPending } = useBlackListedProductsList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        organisationId: filter.organisationId || undefined,
        categoryId: filter.categoryId || undefined,
        partialProductName: filter.partialProductName || undefined,
        productCode: filter.productCode || undefined,
        isPermanent: !filter.isPermanent ? undefined : filter?.isPermanent,
        startDate: mapDateStringToApiDate(filter.startDate) || undefined,
        endDate: mapDateStringToApiDate(filter.endDate) || undefined,
    });

    const { mutateAsync: deleteBlacklistedProduct } = useDeleteBlackListedProduct();

    const columns: GridColDef[] = [
        {
            field: 'organisationId',
            headerName: t('organisation'),
            minWidth: 150,
            flex: 1,
            renderCell: ({ row: { blockForAllOrganisations, organisation } }: GridCellParams) =>
                blockForAllOrganisations ? t('allOrganisations') : organisation?.name,
            sortable: false,
        },
        {
            field: 'category',
            headerName: t('category'),
            minWidth: 150,
            flex: 0.5,
            valueFormatter: ({ value }) => value?.name,
            sortable: false,
        },
        {
            field: 'partialProductName',
            headerName: t('partialProductName'),
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
        {
            field: 'productCode',
            headerName: t('productCode'),
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
        {
            field: 'isPermanent',
            headerName: t('isPermanent'),
            minWidth: 150,
            flex: 0.5,
            sortable: false,
            valueFormatter: ({ value }) => (value ? t('yes') : t('no')),
        },
        {
            field: 'startDate',
            headerName: t('startDate'),
            minWidth: 150,
            flex: 0.5,
            sortable: false,
            valueFormatter: ({ value }) => formatDateString(value),
        },
        {
            field: 'endDate',
            headerName: t('endDate'),
            minWidth: 150,
            flex: 0.5,
            sortable: false,
            valueFormatter: ({ value }) => formatDateString(value),
        },
        {
            field: 'actions',
            headerName: ' ',
            width: 100,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/admin/blacklisted-products/${row?.id}/edit`)}
                            onDelete={() => deleteBlacklistedProduct(row?.id)}
                            deleteWarningTitle={t('blacklistedProductDeleteWarningTitle')}
                            deleteWarningText={
                                <Stack sx={{ '*': { fontWeight: 'bold' } }}>
                                    {t('blacklistedProductDeleteWarningText')}
                                    <Typography>
                                        {row?.blockForAllOrganisations ? t('allOrganisations') : row.organisation?.name}
                                    </Typography>
                                    {row?.category && row?.partialProductName && (
                                        <Typography>
                                            {row?.category?.name} - {row?.partialProductName}
                                        </Typography>
                                    )}
                                    {row?.category && !row?.partialProductName && (
                                        <Typography>{row?.category?.name}</Typography>
                                    )}

                                    {!row?.category && row?.partialProductName && (
                                        <Typography>{row?.partialProductName}</Typography>
                                    )}

                                    {row?.productCode && <Typography>{row?.productCode}</Typography>}
                                    <Typography>
                                        {row?.isPermanent
                                            ? `${t('isPermanent')}`
                                            : `${formatDateString(row?.startDate)} - ${formatDateString(row?.endDate)}`}
                                    </Typography>
                                </Stack>
                            }
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('blacklistedProducts')}
            filter={<BlacklistedProductsFilter filter={filter} onChange={setFilter} />}
            actions={[
                hasWritePermission && (
                    <Button
                        component={Link}
                        to="/admin/blacklisted-products/new"
                        color="primary"
                        variant="contained"
                        data-testid="new-blacklistedProduct"
                    >
                        {t('newBlacklistedProduct')}
                    </Button>
                ),
            ]}
        >
            <TableComponent
                rows={data?.data || []}
                columns={columns}
                rowCount={data?.pagination.size || 0}
                paginationModel={{ page: page ? page - 1 : 0, pageSize }}
                onPaginationModelChange={(value) => {
                    setPage(value?.page + 1);
                    setPageSize(value?.pageSize || 5);
                }}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => hasWritePermission && navigate(`/admin/blacklisted-products/${row.id}/edit`)}
            />
        </Page>
    );
};
