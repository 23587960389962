import { useMemo } from 'react';
import { object, string } from 'yup';

export function useOrganisationTypeSchema() {
    return useMemo(
        () =>
            object().shape({
                name: string().required(),
            }),
        [],
    );
}
