import { logisticsApi } from '../../shared';
import { IStartEnrollment, IStartEnrollmentResponse } from '../models';

class EnrollmentClient {
    public async startEnrollment(enrollment: IStartEnrollment): Promise<IStartEnrollmentResponse> {
        const { data } = await logisticsApi.post<IStartEnrollmentResponse>(`/enrollments`, enrollment);
        return data;
    }
}

export const enrollmentClient = new EnrollmentClient();
