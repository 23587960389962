import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, boolean, mixed, number, object, string } from 'yup';
import { IAllergen, IDietForm, ILinkForm, LevelOfContainmentCode, MeasurementUnit } from '../../shared';
import { IProductCategory } from '../models';

export function useProductSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                name: string().required(),
                measurementUnit: mixed<MeasurementUnit>()
                    .oneOf(Object.values(MeasurementUnit), t('required'))
                    .required(t('required')),
                category: mixed<IProductCategory>().required().optional(),
                weight: number()
                    .transform((value) => {
                        if (value) {
                            return value;
                        } else {
                            return undefined;
                        }
                    })
                    .typeError(t('invalidNumber'))
                    .min(0, t('negativeNumber'))
                    .when('measurementUnit', {
                        is: 'PIECE',
                        then: (schema) => schema.required(t('required')),
                    }),
                brandName: string().optional(),
                regulatedName: string().optional(),
                ingredients: string().optional(),
                nutritionalInformation: string().optional(),
                links: array()
                    .of(object<ILinkForm>().shape({ url: string().required() }))
                    .required(),
                dietInformation: array()
                    .of(object<IDietForm>().shape({ value: string().required() }))
                    .required(),
                allergenInformation: array()
                    .of(
                        object<IAllergen>().shape({
                            id: string().optional(),
                            allergenTypeCode: string().required(),
                            levelOfContainmentCode: mixed<LevelOfContainmentCode>()
                                .oneOf(Object.values(LevelOfContainmentCode))
                                .required(),
                        }),
                    )
                    .required(),
                nutriScore: string().optional(),
                requiresReservation: boolean().required(),
            }),
        [t],
    );
}
