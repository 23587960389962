import { Autocomplete, TextField } from '@mui/material';
import { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete/Autocomplete';
import { Controller } from 'react-hook-form';

interface Props<T, Multiple extends boolean | undefined>
    extends Omit<AutocompleteProps<T, Multiple, undefined, undefined>, 'renderInput'> {
    name: string;
    label?: string;
    defaultValue: any;
    minWidth?: number;
    required?: boolean;
}

export function ControlledAutocomplete<T, Multiple extends boolean | undefined = undefined>({
    name,
    label,
    defaultValue,
    minWidth = 227,
    required = true,
    ...autoCompleteProps
}: Props<T, Multiple>) {
    return (
        <Controller
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Autocomplete
                    {...autoCompleteProps}
                    id={`${name}-field`}
                    sx={{ minWidth: minWidth }}
                    autoComplete={false}
                    onChange={(e, data) => onChange(data)}
                    value={value}
                    filterOptions={createFilterOptions({
                        ignoreCase: true,
                    })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                            error={!!error}
                            helperText={error ? error.message : null}
                            required={required}
                        />
                    )}
                />
            )}
        />
    );
}
