import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useProductCategoriesList } from '../../../admin/hooks';
import {
    ControlledDatePicker,
    IProduct,
    isAxiosError,
    isBadRequest,
    mapDateToApiDate,
    WarningDialog,
} from '../../../shared';
import { useSavePlanningEntry } from '../../hooks';
import { planningEntryFromFormMapper, planningEntryToFormMapper } from '../../mappers';
import { IPlanning, IPlanningEntry, IPlanningEntryForm } from '../../models';
import { usePlanningEntrySchema } from '../../validators';
import { UpdateEntryWithPatternWarning } from '../update-entry-with-pattern-warning/update-entry-with-pattern-warning.component';
import { CategoryRequirements } from './category-requirements.component';
import { ProductRequirements } from './product-requirements.component';

interface Props {
    open: boolean;
    planning: IPlanning;
    handleClose: () => void;
    date?: string;
    entry?: IPlanningEntry;
}

export const PlanningEntryDialog: FC<Props> = ({ open, planning, handleClose, date, entry }) => {
    const { t } = useTranslation();
    const [editedEntryWithPattern, setEditedEntryWithPattern] = useState<IPlanningEntryForm | undefined>(undefined);
    const [reservationExceededWarning, setReservationExceededWarning] = useState<null | {
        planned: string;
        delivered: string;
        addedPlanned: string;
        product: IProduct;
    }>(null);
    const [selectedTab, setSelectedTab] = useState('categories');

    const form = useForm<IPlanningEntryForm>({
        resolver: yupResolver(usePlanningEntrySchema()),
        mode: 'onSubmit',
    });

    const requirements = useFieldArray({ control: form.control, name: 'requirements' });
    const { data: categories, isPending: categoriesIsPending } = useProductCategoriesList({ pageSize: 100 });
    const { mutateAsync: savePlanningEntry } = useSavePlanningEntry();

    useEffect(() => {
        if (open) {
            if (entry) {
                form.reset(planningEntryToFormMapper(entry, categories?.data || []));
            } else if (date) {
                form.reset({
                    date: new Date(date),
                    requirements:
                        categories?.data.map((cat) => ({
                            categoryId: cat.id,
                            isCategory: true,
                            amount: 0,
                        })) || [],
                });
            } else {
                form.reset({
                    requirements:
                        categories?.data.map((cat) => ({
                            categoryId: cat.id,
                            isCategory: true,
                            amount: 0,
                        })) || [],
                });
            }
        }
    }, [date, entry, open, categories, form]);

    const onClose = () => {
        setEditedEntryWithPattern(undefined);
        setSelectedTab('categories');
        handleClose();
    };

    const onSubmit = async (item: IPlanningEntryForm, updateFutureEntries = false) => {
        if (!editedEntryWithPattern && entry?.patternEntry && entry.date === mapDateToApiDate(item.date)) {
            setEditedEntryWithPattern(item);
        } else {
            try {
                onClose();
                await savePlanningEntry({
                    planning: planning.id,
                    entry: planningEntryFromFormMapper(item, updateFutureEntries),
                });
            } catch (e) {
                if (isAxiosError(e) && isBadRequest(e) && (e.response?.data as any)?.product) {
                    setReservationExceededWarning({
                        planned: (e.response?.data as any)?.totalPlannedInCalendar,
                        delivered: (e.response?.data as any)?.totalDelivered,
                        addedPlanned: (e.response?.data as any)?.addedPlannedAmount,
                        product: (e.response?.data as any)?.product,
                    });
                } else {
                    throw e;
                }
            }
        }
    };

    const onUpdateEntryWithPattern = async (updateFutureEntries: boolean) => {
        if (editedEntryWithPattern) {
            await onSubmit(editedEntryWithPattern, updateFutureEntries);
            setEditedEntryWithPattern(undefined);
            onClose();
        }
    };

    return (
        <>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit((value) => onSubmit(value))} noValidate>
                    <Dialog open={open && !editedEntryWithPattern} onClose={onClose} fullWidth maxWidth="md">
                        <DialogTitle>{entry ? t('updatePlanningEntry') : t('newPlanningEntry')}</DialogTitle>
                        <DialogContent>
                            <Box sx={{ mb: 3, mt: 1 }}>
                                <ControlledDatePicker name="date" label={t('date')} required />
                            </Box>
                            <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                value={selectedTab}
                                variant="fullWidth"
                                sx={{
                                    borderBottom: 1,
                                    borderColor: `primary.main`,
                                    minHeight: 'fit-content',
                                    mb: 2,
                                    '.MuiTab-root': { minHeight: 'fit-content', py: 1 },
                                }}
                            >
                                <Tab
                                    label={t('categories')}
                                    value="categories"
                                    onClick={() => setSelectedTab('categories')}
                                    sx={{ backgroundColor: selectedTab === 'categories' ? `primary.light` : `none` }}
                                />
                                <Tab
                                    label={t('products')}
                                    value="products"
                                    onClick={() => setSelectedTab('products')}
                                    sx={{ backgroundColor: selectedTab === 'products' ? `primary.light` : `none` }}
                                />
                            </Tabs>

                            {selectedTab === 'categories' ? (
                                <CategoryRequirements requirements={requirements} categories={categories} />
                            ) : (
                                <ProductRequirements requirements={requirements} planningEntry={entry} />
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={form.handleSubmit((value) => onSubmit(value))}
                                variant="contained"
                                color="primary"
                                disabled={categoriesIsPending}
                            >
                                {t('save')}
                            </Button>
                            <Button onClick={onClose} color="secondary">
                                {t('cancel')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </FormProvider>
            <UpdateEntryWithPatternWarning
                open={!!editedEntryWithPattern}
                onSubmit={onUpdateEntryWithPattern}
                onCancel={onClose}
            />

            <WarningDialog
                open={!!reservationExceededWarning}
                onClose={() => setReservationExceededWarning(null)}
                title={t('reservationExceededTitle', { product: reservationExceededWarning?.product?.name })}
                text={
                    <Trans
                        i18nKey="reservationExceededText"
                        components={{ 1: <br /> }}
                        values={{
                            ...reservationExceededWarning,
                            measurementUnit: reservationExceededWarning?.product?.measurementUnit
                                ? t(reservationExceededWarning?.product?.measurementUnit?.toLowerCase())
                                : '',
                        }}
                    />
                }
            />
        </>
    );
};
