import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ControlledInput, FormCard, FormGrid, IParams, Page, Section } from '../../../shared';
import { DownloadCard } from '../../components';
import { useDevice, useSaveDevice } from '../../hooks';
import { ISaveDevice } from '../../models';
import { useNameSchema } from '../../validators';

export const DeviceEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: device, isFetching } = useDevice(id);

    const form = useForm<ISaveDevice>({
        resolver: yupResolver(useNameSchema()),
        mode: 'all',
    });
    const { mutateAsync: saveDevice, isPending: isPendingSave } = useSaveDevice();

    useEffect(() => {
        if (device) {
            form.reset(device);
        }
    }, [device, form]);

    const onSubmit = useCallback(
        async (item: ISaveDevice) => {
            await saveDevice({ id, item });
            navigate('/admin/devices');
        },
        [id, navigate, saveDevice],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            device ? <DownloadCard name={device.name} code={device.code} /> : <></>,
            <Button onClick={() => navigate('/admin/devices')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, device, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={device?.name || t('newDevice')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isPendingSave || isFetching}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Section title={t('data')}>
                        <FormGrid>
                            <ControlledInput name="name" label={t('name')} required={true} />
                            {id && <ControlledInput name="code" label={t('code')} disabled />}
                        </FormGrid>
                    </Section>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
