import { adminTranslationsEN } from './admin.en';
import { authTranslationsEN } from './auth.en';
import { commonTranslationsEN } from './common.en';
import { deliveriesTranslationsEN } from './deliveries.en';
import { planningTranslationsEN } from './planning.en';
import { stockTranslationsEN } from './stock.en';
import { validationTranslationsEN } from './validation.en';

export const translationsEN = {
    translation: {
        ...adminTranslationsEN,
        ...authTranslationsEN,
        ...commonTranslationsEN,
        ...deliveriesTranslationsEN,
        ...planningTranslationsEN,
        ...stockTranslationsEN,
        ...validationTranslationsEN,
    },
};
