export const planningTranslationsNL = {
    planning: 'Planning',
    dashboard: 'Dashboard',

    shipments: 'Verzendingen',
    shipment: 'Verzending',
    newShipment: 'Nieuwe verzending',
    shipmentDate: 'Verzenddatum',
    shipmentDocument: 'Verzenddocument',
    receiver: 'Organisatie',
    sender: 'Verzender',
    editShipment: 'Wijzig verzending',
    removeShipment: 'Verwijder verzending',
    removeShipmentWarning: 'Ben je zeker dat je deze verzending wilt verwijderen?',
    shipmentDetails: 'Verzending {{organisation}} - {{date}}',
    shipmentMail: 'Verzending mail',
    downloadDocuments: 'Download verzenddocumenten',
    sendShipmentMail: 'Verstuur mail met verzenddocument',
    completeShipment: 'Voltooi verzending',

    DRAFT: 'Concept',
    COMPLETED: 'Voltooid',
    PLANNED: 'Ingepland',

    mailStatus: 'Status mail',
    lastMailDate: 'Laatste mail verzonden op',
    notifyShipment: 'Verstuur verzenddocument',
    SENT: 'Verzonden',
    NOT_SENT: 'Nog niet verzonden',
    FAILED: 'Verzenden mislukt',
    emailNotConfigured: 'E-mailadres van ontvangende organisatie is niet ingesteld.',

    plannings: 'Planningen',
    newPlanning: 'Nieuwe planning',
    removePlanning: 'Verwijder planning',
    removePlanningWarning: 'Ben je zeker dat je deze planning wilt verwijderen?',
    planningDetails: 'Planning {{organisation}}',
    planningTiming: 'Planning loopt van {{startDate}} tot {{endDate}}',
    pattern: 'Patroon',
    startDate: 'Start datum',
    endDate: 'Eind datum',
    week: 'Week',
    month: 'Maand',
    workweek: 'Werkweek',
    addWeek: 'Week toevoegen',
    day: 'Weekdag',
    category: 'Categorie',
    addCategory: 'Categorie toevoegen',
    addProduct: 'Product toevoegen',
    monday: 'Maandag',
    tuesday: 'Dinsdag',
    wednesday: 'Woensdag',
    thursday: 'Donderdag',
    friday: 'Vrijdag',
    saturday: 'Zaterdag',
    sunday: 'Zondag',
    appointmentOnly: 'Enkel afspraak',
    desiredAmount: 'Gewenste totaal',
    newPlanningEntry: 'Nieuw item',
    newPattern: 'Nieuwe reeks',
    feadProductsPattern: 'Reeks van fead producten (automatisch verdeling)',
    updatePlanningEntry: 'Wijzig item',
    dateMustBeBefore: 'Datum moet voor {{date}} zijn',
    dateMustBeAfter: 'Datum moet na {{date}} zijn',
    dateInvalid: 'Datum niet geldig',
    deletePlanningEntryWarningTitle: 'Verwijder item',
    deletePlanningEntryWarningText: 'Ben je zeker dat je dit item wilt verwijderen?',
    changeRepeatingEventTitle: 'Je verandert een herhalende gebeurtenis',
    changeRepeatingEventContent: 'Wil je de enkel deze gebeurtenis wijzigen of  alle toekomstige gebeurtenissen?',
    onlyThisEvent: 'Deze gebeurtenis',
    allEvents: 'Alle gebeurtenissen',
    appointment: 'Afspraak',
    appointmentWithoutRequirements: 'Afspraak zonder behoeften',
    automaticDistribution: 'Autom. verdeling',
    feadProducts: 'Fead producten',
    totalPlanned: 'Totaal verwacht (st)',
    totalDelivered: 'Totaal geleverd (st)',
    totalAlreadyPlanned: 'Totaal reeds gepland (st)',
    totalAlreadyDelivered: 'Totaal reeds geleverd (st)',
    totalRemainingInStock: 'Totaal resterend in stock (st)',
    totalRemainingForPlanning: 'Totaal beschikbaar voor inplanning (st)',

    completeShipments: 'Voltooi verzendingen',
    sendMails: 'Verzenddocument',
    completeShipmentsDate: 'Kies de datum voor de te versturen verzendingen',
    hasMailingContact: 'Mailadres ingesteld',
    sendMailsOption: 'Verstuur verzenddocument',

    progressesProducts: 'Vooruitgang producten',
    progressesCategories: 'Vooruitgang categorieën',

    shipmentUnlinkStockMovementTitle: 'Stock beweging ontkoppelen',
    shipmentUnlinkStockMovementText: 'Ben je zeker dat je deze stock beweging wilt ontkoppelen van deze verzending?',

    errorOverlapPlannings: 'Organisatie en periode overlapt met die van een andere planning',

    reservationExceededTitle: 'Reservatie overschreden: {{product}}',
    reservationExceededText:
        'Huidige ingeplande hoeveelheid: {{planned}} {{measurementUnit}}<1/>' +
        'Hoeveelheid planning item: {{addedPlanned}} {{measurementUnit}}<1/>' +
        'In stock: {{delivered}} {{measurementUnit}}',
};
