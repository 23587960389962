import { IProgress, IProgressBar } from '../models';
import { IBlacklistedProduct, IProductCategory } from '../../admin/models';
import { TFunction } from 'i18next';

export function mapProgressToProgressBar(
    progress: IProgress,
    categories: IProductCategory[],
    t: TFunction,
    blacklistedCategories: IBlacklistedProduct[],
): IProgressBar {
    const percentage = Math.min((progress.totalScanned / progress.totalPlanned) * 100, 100);
    const category = categories.find(({ id }) => id === progress.category);
    const isBlacklisted = !!blacklistedCategories.find((blacklisted) => blacklisted.category?.id === progress.category);

    return {
        ...progress,
        name: category?.name || `${t('unknown')}`,
        abbreviation: category?.abbreviation || '',
        priority: category?.priority,
        percentage,
        percentageNotScanned: 100 - percentage,
        label: `${(progress.totalScanned / 1000).toFixed(1)}/${(progress.totalPlanned / 1000).toFixed(1)} kg`,
        limit:
            progress.totalScanned - progress.totalPlanned > -10000
                ? `${progress.totalScanned - progress.totalPlanned > 0 ? '+' : ''}${(
                      (progress.totalScanned - progress.totalPlanned) /
                      1000
                  ).toFixed()}`
                : '',
        showWarning: progress.totalScanned === 0,
        isBlacklisted,
    };
}
