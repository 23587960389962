import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, date, mixed, number, object, string } from 'yup';
import { IProduct, ISaveFeadDeliveryEntry } from '../../shared';

export function useFeadDeliverySchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                date: date().typeError(t('invalidDate')).required(),
                product: mixed<IProduct>()
                    .required()
                    .test(
                        'requiresReservation',
                        t('productRequiresReservationFalse'),
                        (value) => value.requiresReservation,
                    ),
                stockLocationId: string().test(
                    'required',
                    t('required'),
                    (value, context) =>
                        !(
                            context.parent.entries.some(
                                (entry: ISaveFeadDeliveryEntry) => (entry?.deliveredAmount || 0) > 0,
                            ) && !value
                        ),
                ),
                entries: array().of(
                    object().shape({
                        organisationId: string().required(),
                        plannedAmount: number().min(1).required(),
                        deliveredAmount: number()
                            .transform((value) => {
                                if (value || value === 0) {
                                    return value;
                                } else {
                                    return null;
                                }
                            })
                            .nullable(),
                    }),
                ),
            }),
        [t],
    );
}
