import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { Warning } from '@mui/icons-material';

interface Props {
    show: boolean;
    onSubmit: () => void;
    onCancel: () => void;
}

export const BatchWarning: FC<Props> = ({ show, onSubmit, onCancel }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={show}>
            <DialogTitle>
                <Stack direction="row" alignItems="center">
                    <Warning color="warning" /> {t('batchWarningTitle')}
                </Stack>
            </DialogTitle>
            <DialogContent>{t('batchWarningText')}</DialogContent>
            <DialogActions>
                <Button onClick={onSubmit}>{t('yes')}</Button>
                <Button variant="contained" onClick={onCancel}>
                    {t('no')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
