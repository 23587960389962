import { MenuItem } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledSelect } from '../../../shared';
import { useAllergenTypes } from '../../hooks';

interface Props {
    name: string;
}

export const AllergenTypePicker: FC<Props> = ({ name }) => {
    const { t } = useTranslation();
    const { data: allergenTypes } = useAllergenTypes();

    return (
        <ControlledSelect name={name} label={t('allergenType')}>
            {allergenTypes?.map((type) => (
                <MenuItem value={type.code} key={type.code}>
                    {type.text}
                </MenuItem>
            ))}
        </ControlledSelect>
    );
};
