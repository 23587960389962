import { logisticsApi } from '../../shared';
import { ISaveSettings, ISettings } from '../models';

class SettingsClient {
    public async getSettings(): Promise<ISettings> {
        const { data } = await logisticsApi.get(`/settings`);
        return data;
    }

    public async updateSettings(settings: ISaveSettings, onSuccess: string): Promise<ISettings> {
        const { data } = await logisticsApi.post(`/settings`, settings, { successMessage: onSuccess });
        return data;
    }
}

export const settingsClient = new SettingsClient();
