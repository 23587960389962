import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ControlledInput, FormCard, FormGrid, IParams, Page, Section } from '../../../shared';
import { DownloadCard } from '../../components';
import { ISaveStockLocation } from '../../models';
import { useNameSchema } from '../../validators';
import { useSaveStockLocation, useStockLocation } from '../../hooks';

export const StockLocationEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: stockLocation, isFetching } = useStockLocation(id);

    const form = useForm<ISaveStockLocation>({
        resolver: yupResolver(useNameSchema()),
        mode: 'all',
    });
    const { mutateAsync: saveStockLocation, isPending: isPendingSave } = useSaveStockLocation();

    useEffect(() => {
        if (stockLocation) {
            form.reset(stockLocation);
        }
    }, [stockLocation, form]);

    const onSubmit = useCallback(
        async (item: ISaveStockLocation) => {
            await saveStockLocation({ id, item });
            navigate('/admin/stock-locations');
        },
        [id, navigate, saveStockLocation],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            stockLocation ? <DownloadCard name={stockLocation.name} code={stockLocation.code} /> : <></>,
            <Button onClick={() => navigate('/admin/stock-locations')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, stockLocation, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={stockLocation?.name || t('newStockLocation')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isPendingSave}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Section title={t('data')}>
                        <FormGrid>
                            <ControlledInput name="name" label={t('name')} required={true} />
                            {id && <ControlledInput name="code" label={t('code')} disabled />}
                        </FormGrid>
                    </Section>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
