import { ListAltTwoTone } from '@mui/icons-material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MeasurementUnit, Widget } from '../../../shared';
import { IProgress, IProgressBar } from '../../models';
import { ProgressItems } from './progress-items.component';

interface Props {
    progresses: IProgress[];
    chartsPerRow?: number;
}

export const ProgressProducts: FC<Props> = ({ progresses, chartsPerRow }) => {
    const { t } = useTranslation();
    const productProgresses = useMemo(() => progresses.filter(({ productId }) => !!productId), [progresses]);

    const data = useMemo(() => {
        if (productProgresses?.length) {
            const data: IProgressBar[] = productProgresses.map((progress) => {
                const percentage = Math.min((progress.totalScanned / progress.totalPlanned) * 100, 100);
                const isGram = progress.product?.measurementUnit === MeasurementUnit.GRAM;

                return {
                    ...progress,
                    name: progress.product?.name || '',
                    percentage,
                    percentageNotScanned: 100 - percentage,
                    abbreviation: '',
                    label: isGram
                        ? `${(progress.totalScanned / 1000).toFixed(1)}/${(progress.totalPlanned / 1000).toFixed(1)} kg`
                        : `${progress.totalScanned}/${progress.totalPlanned} st`,
                    isBlacklisted: false,
                };
            });
            return data.sort((a, b) => a.name.localeCompare(b.name));
        }
    }, [productProgresses]);

    return (
        !!productProgresses.length && (
            <Widget title={t('progressesProducts')} icon={<ListAltTwoTone color="primary" />}>
                <ProgressItems progresses={data} chartsPerRow={chartsPerRow} />
            </Widget>
        )
    );
};
