import { Alert, Button, Snackbar } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { AxiosError } from 'axios';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    Page,
    PermissionKeys,
    RowActions,
    Search,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParams,
} from '../../../shared';
import { DownloadCard } from '../../components';
import { useDeleteProduct, useProductsList } from '../../hooks';

export const ProductsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.PRODUCTS_WRITE);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } =
        useQueryParams();

    const { isPending, data } = useProductsList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
        isDraft: false,
    });

    const { mutateAsync: deleteProduct } = useDeleteProduct();

    const onDelete = useCallback(
        async (id: string) => {
            try {
                await deleteProduct(id);
            } catch (err: unknown) {
                if ((err as AxiosError)?.response?.status === 400) {
                    setShowDeleteWarning(true);
                }
            }
        },
        [deleteProduct],
    );

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('name'), minWidth: 150, flex: 1 },
        { field: 'code', headerName: t('code'), minWidth: 150, flex: 1 },
        {
            field: 'category',
            headerName: t('productCategory'),
            minWidth: 150,
            flex: 1,
            valueFormatter: ({ value }) => value?.name,
            sortable: false,
        },
        {
            field: 'measurementUnit',
            headerName: t('measurementUnit'),
            minWidth: 150,
            flex: 1,
            valueFormatter: ({ value }) => t(value as string),
        },
        { field: 'weight', headerName: t('weight'), minWidth: 150, flex: 1 },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id, code, name } }: GridCellParams) => (
                <>
                    <DownloadCard variant="icon" code={code} name={name} />
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/admin/products/${id}/edit`)}
                            onDelete={() => onDelete(id)}
                            deleteWarningTitle={t('productDeleteWarningTitle')}
                            deleteWarningText={t('productDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('products')}
            actions={[
                <Search search={search} onSearch={setSearch} />,
                hasWritePermission && (
                    <Button
                        component={Link}
                        to="/admin/products/new"
                        color="primary"
                        variant="contained"
                        data-testid="new-product"
                    >
                        {t('newProduct')}
                    </Button>
                ),
            ]}
        >
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={showDeleteWarning}
                onClose={() => setShowDeleteWarning(false)}
            >
                <Alert onClose={() => setShowDeleteWarning(false)} severity="error" sx={{ width: '100%' }}>
                    {t('productHasStockMovements')}
                </Alert>
            </Snackbar>
            <TableComponent
                rows={data?.data || []}
                columns={columns}
                rowCount={data?.pagination.size || 0}
                paginationModel={{ page: page ? page - 1 : 0, pageSize }}
                onPaginationModelChange={(value) => {
                    setPage(value?.page + 1);
                    setPageSize(value?.pageSize || 5);
                }}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => hasWritePermission && navigate(`/admin/products/${row.id}/edit`)}
            />
        </Page>
    );
};
