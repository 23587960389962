import { Definition, IBatch, Widget } from '../../../shared';
import { FC } from 'react';
import { ListAltTwoTone } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
    batch: IBatch;
}

export const BatchDetail: FC<Props> = ({ batch }) => {
    const { t } = useTranslation();
    return (
        <Widget title={t('batch')} icon={<ListAltTwoTone color="primary" />}>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Definition label={t('date')}>{batch.date}</Definition>
                </Grid>
                <Grid item xs={3}>
                    <Definition label={t('supplier')}>{batch.supplier.name}</Definition>
                </Grid>
                <Grid item xs={3}>
                    <Definition label={t('referenceNumber')}>{batch?.referenceNumber}</Definition>
                </Grid>
                <Grid item xs={3}>
                    <Definition label={t('qrCode')}>{batch?.qrCode?.name}</Definition>
                </Grid>
            </Grid>
        </Widget>
    );
};
