import { Box, Grid, Stack, Typography } from '@mui/material';
import { ComponentProps, FC, ReactNode } from 'react';

type Props = ComponentProps<typeof Grid> & {
    children: ReactNode;
    title?: ReactNode;
    footer?: ReactNode;
};

export const Section: FC<Props> = ({ children, title, footer, ...props }) => {
    return (
        <Grid item {...props}>
            {title && (
                <Typography variant="subtitle2" sx={{ mb: 2 }}>
                    {title}
                </Typography>
            )}
            <Stack spacing={2} alignItems="flex-start">
                {children}
            </Stack>
            <Box sx={{ mt: 1 }}>{footer}</Box>
        </Grid>
    );
};
