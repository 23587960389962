import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledInput,
    FormCard,
    FormGrid,
    IParams,
    ISaveOrganisationType,
    Page,
    Section,
    useOrganisationType,
    useSaveOrganisationType,
} from '../../../shared';
import { useOrganisationTypeSchema } from '../../validators';

export const OrganisationTypeEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: organisationType, isFetching } = useOrganisationType(id);

    const form = useForm<ISaveOrganisationType>({
        resolver: yupResolver(useOrganisationTypeSchema()),
        mode: 'all',
    });
    const { mutateAsync: saveOrganisationType, isPending: isPendingSave } = useSaveOrganisationType();

    useEffect(() => {
        if (organisationType) {
            form.reset(organisationType);
        }
    }, [organisationType, form]);

    const onSubmit = useCallback(
        async (item: ISaveOrganisationType) => {
            await saveOrganisationType({ id, item });
            navigate('/admin/organisation-types');
        },
        [id, navigate, saveOrganisationType],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            <Button onClick={() => navigate('/admin/organisation-types')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={organisationType?.name || t('newOrganisationType')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isPendingSave || isFetching}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Section title={t('data')}>
                        <FormGrid>
                            <ControlledInput name="name" label={t('name')} required />
                        </FormGrid>
                    </Section>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
