import { FC, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { PermissionKeys } from '../../enums';
import { useHasPermission } from '../../utils';
import { ErrorFallback } from '../error-fallback/error-fallback.component';
import { Loading } from '../loading/loading.component';
import { Forbidden } from '../../pages';

interface Props {
    requiredPermission?: PermissionKeys | PermissionKeys[];
    children?: ReactNode;
}

export const ProtectedRoute: FC<Props> = ({ requiredPermission, children }) => {
    const { hasPermission, isPending } = useHasPermission();

    if (isPending) return <Loading fullscreen />;
    if (requiredPermission && !hasPermission(requiredPermission)) return <Forbidden />;

    return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>;
};
