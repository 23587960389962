export enum QueryKeys {
    AuthUser = 'AuthUser',
    AuthProviders = 'AuthProviders',
    UserRoles = 'UserRoles',
    AllergenTypes = 'AllergenTypes',
    Devices = 'Devices',
    Device = 'Device',
    Operators = 'Operators',
    Operator = 'Operator',
    OrganisationTypes = 'OrganisationTypes',
    OrganisationType = 'OrganisationType',
    Organisations = 'Organisations',
    Organisation = 'Organisation',
    ProductCategories = 'ProductCategories',
    ProductCategory = 'ProductCategory',
    Products = 'Products',
    Product = 'Product',
    QrCodes = 'QrCodes',
    QrCode = 'QrCode',
    Suppliers = 'Suppliers',
    Supplier = 'Supplier',
    StockLocations = 'StockLocations',
    StockLocation = 'StockLocation',
    Settings = 'Settings',
    BlacklistedProducts = 'BlacklistedProducts',
    BlacklistedProduct = 'BlacklistedProduct',
    Shipments = 'Shipments',
    Shipment = 'Shipment',
    Plannings = 'Plannings',
    Planning = 'Planning',
    Dashboard = 'Dashboard',
    StockMovements = 'StockMovements',
    StockMovement = 'StockMovement',
    StockProducts = 'StockProducts',
    StockProduct = 'StockProduct',
    Batches = 'Batches',
    Batch = 'Batch',
    FeadDeliveries = 'FeadDeliveries',
    FeadDelivery = 'FeadDelivery',
}
