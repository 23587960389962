import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MenuItem, Stack, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useProductsList, useStockLocationsList } from '../../../admin/hooks';
import { IStockLocation } from '../../../admin/models';
import {
    ControlledAutocomplete,
    ControlledDatePicker,
    ControlledSelect,
    FormCard,
    FormGrid,
    IFormFeadDelivery,
    IParams,
    isBadRequest,
    MeasurementUnit,
    Page,
    Section,
    useDebouncedState,
    useFeadDelivery,
    useSaveFeadDelivery,
    WarningDialog,
} from '../../../shared';
import { FeadDeliveryEntryInputs } from '../../components';
import { feadDeliveryFromFormMapper, feadDeliveryToFormMapper } from '../../mappers';
import { useFeadDeliverySchema } from '../../validators';

export const FeadDeliveryEditPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();
    const [showFeadDeliveryInUse, setShowFeadDeliveryInUse] = useState(false);

    const { data: delivery, isFetching } = useFeadDelivery(id);
    const { mutateAsync: saveDelivery, isPending: isSaving } = useSaveFeadDelivery();
    const [debouncedSearch, , setSearch] = useDebouncedState('');
    const { data: products, isPending: productsIsLoading } = useProductsList({
        isDraft: false,
        search: debouncedSearch,
    });
    const { data: stockLocations } = useStockLocationsList({ pageSize: 100 });

    const form = useForm<IFormFeadDelivery>({
        resolver: yupResolver(useFeadDeliverySchema()),
    });
    const watch = form.watch();
    const totalPlanned = useMemo(() => {
        return (watch.entries || []).map(({ plannedAmount }) => Number(plannedAmount)).reduce((a, b) => a + b, 0);
    }, [watch]);
    const totalDelivered = useMemo(() => {
        return (watch.entries || [])
            .map(({ deliveredAmount }) => Number(deliveredAmount || 0))
            .reduce((a, b) => a + b, 0);
    }, [watch]);
    const isGram = watch?.product?.measurementUnit === MeasurementUnit.GRAM;

    useEffect(() => {
        if (delivery) {
            form.reset(feadDeliveryToFormMapper(delivery));
        }
    }, [delivery, form]);

    const onSubmit = useCallback(
        async (item: IFormFeadDelivery, ignoreInUseWarning = false) => {
            setShowFeadDeliveryInUse(false);
            try {
                await saveDelivery({
                    id,
                    item: feadDeliveryFromFormMapper(item),
                    ignoreInUseWarning,
                });
                navigate('/fead/deliveries');
            } catch (err) {
                if (isBadRequest(err)) {
                    setShowFeadDeliveryInUse(true);
                } else {
                    throw err;
                }
            }
        },
        [id, navigate, saveDelivery],
    );

    const actions = useMemo(
        () => [
            <Button
                variant="contained"
                color="primary"
                onClick={form.handleSubmit((data) => onSubmit(data))}
                disabled={isSaving}
            >
                {t('save')}
            </Button>,
            <Button onClick={() => navigate('/fead/deliveries')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isSaving, t, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={t('feadDelivery')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isSaving}
        >
            <FormProvider {...form}>
                <FormCard actions={actions} handleSubmit={(data) => onSubmit(data)}>
                    <Section>
                        <FormGrid>
                            <ControlledDatePicker name="date" label={t('date')} required width={227} />
                            <ControlledAutocomplete
                                name="product"
                                label={t('product')}
                                defaultValue={null}
                                loading={productsIsLoading}
                                options={debouncedSearch ? products?.data || [] : []}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(product) => product.name}
                                noOptionsText={debouncedSearch ? t('noProductsFound') : t('startSearchingProducts')}
                                onInputChange={(e, value) => setSearch(value)}
                                disabled={!!id}
                                renderOption={(props, product) =>
                                    product.requiresReservation ? (
                                        <Typography {...props}>{product.name}</Typography>
                                    ) : (
                                        <Typography {...props}>
                                            <Stack
                                                sx={{ span: { fontStyle: 'italic', fontSize: '10px' } }}
                                                direction="column"
                                            >
                                                {product.name}
                                                <span>{t('reservationNotRequired')}</span>
                                            </Stack>
                                        </Typography>
                                    )
                                }
                            />
                            <ControlledSelect
                                name="stockLocationId"
                                label={t('stockLocation')}
                                required={totalDelivered > 0}
                            >
                                {(stockLocations?.data || []).map((stockLocation: IStockLocation) => (
                                    <MenuItem value={stockLocation.id} key={stockLocation.id}>
                                        {stockLocation.name}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        </FormGrid>
                        <FormGrid>
                            <FeadDeliveryEntryInputs delivery={delivery} />
                        </FormGrid>
                        <FormGrid>
                            <Typography sx={{ width: 227 }}>{t('total')}</Typography>
                            <Typography sx={{ width: 227 }}>
                                {totalPlanned} {t(isGram ? 'gram' : 'piece')}
                            </Typography>
                            <Typography sx={{ width: 227 }}>
                                {totalDelivered} {t(isGram ? 'gram' : 'piece')}
                            </Typography>
                        </FormGrid>
                    </Section>
                </FormCard>
            </FormProvider>
            <WarningDialog
                open={showFeadDeliveryInUse}
                onConfirm={form.handleSubmit((data) => onSubmit(data, true))}
                onClose={() => setShowFeadDeliveryInUse(false)}
                title={t('feadDeliveryInUseTitle')}
                text={
                    <>
                        <Typography>{t('feadDeliveryInUseDescription')}</Typography>
                        <b>{t('editFeadDeliveryInUseConfirmQuestion')}</b>
                    </>
                }
            />
        </Page>
    );
};
