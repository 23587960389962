import { IAllergenType, ListModel, logisticsApi } from '../../shared';

class AllergenTypesClient {
    public async getAllergenTypes(): Promise<IAllergenType[]> {
        const { data } = await logisticsApi.get<ListModel<IAllergenType>>(`/allergen-types`, {
            params: { pageSize: 300 },
        });
        return data.data;
    }
}

export const allergenTypesClient = new AllergenTypesClient();
