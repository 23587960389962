import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeadDeliveryProductsForOrganisation, Widget } from '../../../shared';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';

interface Props {
    organisationId?: string;
}
export const FeadProductsInfo: FC<Props> = ({ organisationId }) => {
    const { t } = useTranslation();
    const { data: feadProducts, isPending } = useFeadDeliveryProductsForOrganisation(organisationId);

    const feadProductsInfo = useMemo(() => {
        return feadProducts?.filter(
            (x) => !(x.totalPlanned === x.totalDelivered && x.totalDelivered - x.totalOutStock === 0),
        );
    }, [feadProducts]);

    const columns: GridColDef[] = [
        {
            field: 'productName',
            headerName: t('feadProducts'),
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
        {
            field: 'totalPlanned',
            headerName: t('totalPlanned'),
            minWidth: 100,
            flex: 1,
            sortable: false,
        },
        {
            field: 'totalDelivered',
            headerName: t('totalDelivered'),
            minWidth: 100,
            flex: 1,
            sortable: false,
        },
        {
            field: 'totalRemainingInStock',
            headerName: t('totalRemainingInStock'),
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderCell: ({ row: { totalDelivered, totalOutStock } }: GridCellParams) => totalDelivered - totalOutStock,
        },
        {
            field: 'totalRemainingForPlanning',
            headerName: t('totalRemainingForPlanning'),
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderCell: ({ row: { totalDelivered, totalScheduled } }: GridCellParams) =>
                totalDelivered - totalScheduled,
        },
    ];
    return (
        <>
            {feadProductsInfo?.length ? (
                <Widget sx={{ mb: 3, p: 1.5 }} hasTable>
                    <DataGrid
                        loading={isPending}
                        columns={columns}
                        rows={feadProductsInfo || []}
                        disableColumnFilter
                        disableColumnMenu
                        getRowId={(value) => value.productId}
                        rowHeight={32.5}
                        columnHeaderHeight={32.5}
                        hideFooter
                    />
                </Widget>
            ) : (
                <></>
            )}
        </>
    );
};
