import { ChevronLeft, ChevronRight, CloudDownloadOutlined } from '@mui/icons-material';
import { Button, Link, Stack } from '@mui/material';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDateString, Loading, SortOrder, TableComponent, useQueryParams, Widget } from '../../../shared';
import { useShipmentsList } from '../../hooks';

type Params = { date: string };
interface Props {
    selectedShipments: string[];
    onChangeSelectedShipments: (shipments: string[]) => void;
}
export const ShipmentCompleteShipmentsPage: FC<Props> = ({ selectedShipments, onChangeSelectedShipments }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { date } = useParams<keyof Params>() as Params;

    const { sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'status', sort: 'desc' }],
    });

    const { data: shipments, isPending } = useShipmentsList({
        page: 1,
        pageSize: 10000,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        shipmentDate: date,
    });

    const onNext = async () => {
        navigate(`/planning/shipments/shipments-complete-wizard/${date}/send-mails`);
    };

    const columns: GridColDef[] = [
        {
            field: 'shipmentDate',
            headerName: t('shipmentDate'),
            valueFormatter: ({ value }) => formatDateString(value as string),
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
        {
            field: 'receiver',
            headerName: t('receiver'),
            valueFormatter: ({ value }) => value?.name,
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
        {
            field: 'status',
            headerName: t('status'),
            valueFormatter: ({ value }) => t(value as string),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'shipmentDocument',
            headerName: t('shipmentDocument'),
            width: 150,
            align: 'center',
            renderCell: ({ row }) => (
                <Link target="_blank" href={`/logistics-api/shipments/${row.id}/document`}>
                    <CloudDownloadOutlined />
                </Link>
            ),
        },
    ];

    useEffect(() => {
        if (!selectedShipments?.length && shipments) {
            onChangeSelectedShipments(shipments?.data?.map(({ id }) => id));
        }
    }, [onChangeSelectedShipments, selectedShipments?.length, shipments]);

    const onSelectionChange = (ids: GridRowSelectionModel) => {
        onChangeSelectedShipments(ids as string[]);
    };

    return (
        <Widget
            hasTable
            footer={
                <Stack direction="row" justifyContent="space-between">
                    <Button
                        startIcon={<ChevronLeft />}
                        onClick={() => navigate(`/planning/shipments/shipments-complete-wizard?date=${date}`)}
                    >
                        {t('previous')}
                    </Button>
                    <Button
                        endIcon={<ChevronRight />}
                        variant="contained"
                        color="primary"
                        onClick={onNext}
                        disabled={isPending || !selectedShipments?.length}
                    >
                        {t('next')}
                    </Button>
                </Stack>
            }
        >
            {isPending ? (
                <Loading />
            ) : (
                <TableComponent
                    checkboxSelection
                    rowSelectionModel={selectedShipments}
                    onRowSelectionModelChange={onSelectionChange}
                    rows={shipments?.data || []}
                    columns={columns}
                    hideFooter={true}
                    onSortModelChange={setSort}
                    defaultSortModel={sort}
                    sortModel={sort}
                    paginationMode="client"
                />
            )}
        </Widget>
    );
};
