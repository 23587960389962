import { blacklistedProductsClient } from '../clients';
import { QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { IBlacklistedProduct, IBlacklistedProductQuery } from '../models';

const client = blacklistedProductsClient;
const listKey = QueryKeys.BlacklistedProducts;
const itemKey = QueryKeys.BlacklistedProduct;

export function useBlackListedProductsList(...args: UseListParams<IBlacklistedProduct, IBlacklistedProductQuery>) {
    return useList(client, listKey, ...args);
}

export function useBlackListedProduct(...args: UseItemParams<IBlacklistedProduct>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveBlackListedProduct() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteBlackListedProduct() {
    return useDelete(client, listKey);
}
