import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    BatchesDetailPage,
    BatchesEditPage,
    BatchesPage,
    StockMovementEditPage,
    StockMovementsPage,
    StockPage,
    StockProductPage,
    StockProductsPage,
} from './pages';

export const stockRoutes: IRouteConfig[] = [
    { path: '/stock', redirect: '/stock/movements' },
    { path: '/stock/*', component: StockPage },
];

export const stockSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/products', component: StockProductsPage, requiredPermissions: PermissionKeys.STOCK_PRODUCTS_READ },
    { path: '/products/:id', component: StockProductPage, requiredPermissions: PermissionKeys.STOCK_PRODUCTS_READ },

    { path: '/movements', component: StockMovementsPage, requiredPermissions: PermissionKeys.STOCK_MOVEMENTS_READ },
    {
        path: '/movements/new',
        component: StockMovementEditPage,
        requiredPermissions: PermissionKeys.STOCK_MOVEMENTS_WRITE,
    },
    {
        path: '/movements/:id/edit',
        component: StockMovementEditPage,
        requiredPermissions: PermissionKeys.STOCK_MOVEMENTS_WRITE,
    },

    { path: '/batches', component: BatchesPage, requiredPermissions: PermissionKeys.BATCHES_READ },
    {
        path: '/batches/new',
        component: BatchesEditPage,
        requiredPermissions: PermissionKeys.BATCHES_WRITE,
    },
    {
        path: '/batches/:id/edit',
        component: BatchesEditPage,
        requiredPermissions: PermissionKeys.BATCHES_WRITE,
    },
    {
        path: '/batches/:id/detail',
        component: BatchesDetailPage,
        requiredPermissions: PermissionKeys.BATCHES_READ,
    },
];
