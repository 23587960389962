import { ResultSet } from '@cubejs-client/core';
import { useTheme } from '@mui/styles';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Label,
    LabelList,
    Legend,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';
import { useColoredCategories } from '../../../planning/hooks';
import { useMapDataFoodDistributionBarChart } from '../../hooks';
import { IFilter } from '../../models';
import { OrganisationProgressLegend } from '../../../planning/components';
import { Box } from '@mui/material';
import { compareProductCategory } from '../../../planning/utils';

interface Props {
    actualResultSet?: ResultSet;
    plannedResultSet?: ResultSet;
    filter: IFilter;
}

export const FoodDistributionBarChart: FC<Props> = ({ actualResultSet, plannedResultSet, filter }) => {
    const { t } = useTranslation();
    const categories = useColoredCategories();
    const theme = useTheme();

    const usedCategories = useMemo(() => {
        if (filter.showTotalCategories) return [t('unknown')];
        if (filter.showPlanned) {
            return Array.from(
                new Set([
                    ...(actualResultSet?.tablePivot() || []).map((item: any) => item['ProductCategory.name']),
                    ...(plannedResultSet?.tablePivot() || []).map((item: any) => item['ProductCategory.name']),
                ]).values(),
            ).map((item) => item || t('unknown'));
        }
        return Array.from(
            new Set(
                (actualResultSet?.tablePivot() || [])
                    .map((item: any) => item['ProductCategory.name'] || t('unknown'))
                    .values(),
            ),
        );
    }, [filter.showTotalCategories, filter.showPlanned, t, actualResultSet, plannedResultSet]);

    const chartData = useMapDataFoodDistributionBarChart(filter, usedCategories, actualResultSet, plannedResultSet);

    const findCategoryAbbrev = useCallback(
        (value: string) => categories?.find((cat) => cat.name === value)?.abbreviation || '',
        [categories],
    );

    const sortedChartData = useMemo(() => {
        return chartData?.sort((a, b) => {
            if (!filter?.grouping) {
                return compareProductCategory(
                    categories?.find((cat) => cat.name === a.x) || {},
                    categories?.find((cat) => cat.name === b.x) || {},
                );
            }
            return a.x.localeCompare(b.x);
        });
    }, [chartData, filter?.grouping, categories]);

    const sortedUsedCategories = useMemo(
        () =>
            usedCategories?.sort((a, b) =>
                compareProductCategory(
                    categories?.find((cat) => cat.name === a) || {},
                    categories?.find((cat) => cat.name === b) || {},
                ),
            ),
        [usedCategories, categories],
    );

    return (
        <>
            {!filter?.grouping && !filter?.showTotalCategories && (
                <Box sx={{ mb: 4 }}>
                    <OrganisationProgressLegend />
                </Box>
            )}

            <ResponsiveContainer width="100%" height={350}>
                <BarChart data={sortedChartData} margin={{ top: 40, left: 25, right: 5, bottom: 20 }}>
                    <XAxis
                        dataKey="x"
                        xAxisId={0}
                        dy={10}
                        interval={0}
                        tickFormatter={filter.grouping ? undefined : findCategoryAbbrev}
                    />
                    {filter.showPlanned && <XAxis dataKey="x" xAxisId={1} hide />})
                    <YAxis tickFormatter={(value) => `${value / 1000}`}>
                        <Label value={t('totalWeightKg')} angle={-90} position="insideLeft" offset={-10} />
                    </YAxis>
                    {filter.grouping && !filter.showTotalCategories && (
                        <Legend
                            wrapperStyle={{ position: 'relative' }}
                            payloadUniqBy={true}
                            formatter={findCategoryAbbrev}
                        />
                    )}
                    <CartesianGrid />
                    {filter.grouping ? (
                        <>
                            {sortedUsedCategories?.map((category) => (
                                <Bar
                                    xAxisId={0}
                                    key={category}
                                    dataKey={`${category},StockMovement.totalWeight`}
                                    name={category}
                                    fill={
                                        categories.find(({ name }) => category === name)?.color ||
                                        (filter.showTotalCategories ? theme.palette.primary.main : '#D3D3D3')
                                    }
                                >
                                    {sortedChartData?.map((entry) => (
                                        <React.Fragment key={entry.x}>
                                            <Cell key={entry.x} />
                                            <LabelList
                                                position="top"
                                                valueAccessor={(value: any) => {
                                                    const actual =
                                                        Math.round(
                                                            value[`${category},StockMovement.totalWeight`] / 100,
                                                        ) / 10;
                                                    const planned =
                                                        Math.round(value[`${category},Requirement.amount`] / 100) / 10;

                                                    const abbrev = categories?.find((cat) => cat?.name === category)
                                                        ?.abbreviation;

                                                    const weight = filter.showPlanned
                                                        ? `${actual}/${planned}`
                                                        : `${actual}kg`;

                                                    return abbrev ? `${abbrev} ${weight}` : weight;
                                                }}
                                                fill="#000000"
                                            />
                                        </React.Fragment>
                                    ))}
                                </Bar>
                            ))}
                        </>
                    ) : (
                        <Bar
                            key={'StockMovement.totalWeight'}
                            dataKey={'StockMovement.totalWeight'}
                            name={'StockMovement.totalWeight'}
                            xAxisId={0}
                        >
                            {sortedChartData?.map((entry) => (
                                <React.Fragment key={entry.x}>
                                    <Cell
                                        key={entry.x}
                                        fill={
                                            categories.find(({ name }) => entry.x === name)?.color ||
                                            (filter.showTotalCategories ? theme.palette.primary.main : '#D3D3D3')
                                        }
                                    />
                                    <LabelList
                                        position="top"
                                        valueAccessor={(value: any) => {
                                            const actual = Math.round(value[`StockMovement.totalWeight`] / 100) / 10;
                                            const planned = Math.round(value[`Requirement.amount`] / 100) / 10;
                                            return filter.showPlanned ? `${actual}/${planned}` : `${actual}kg`;
                                        }}
                                        fill="#000000"
                                    />
                                </React.Fragment>
                            ))}
                        </Bar>
                    )}
                    {filter.showPlanned &&
                        (filter.grouping ? (
                            <>
                                {usedCategories?.map((category) => (
                                    <Bar
                                        key={category + 'planning'}
                                        dataKey={`${category},Requirement.amount`}
                                        name={category}
                                        xAxisId={1}
                                        legendType="none"
                                    >
                                        {sortedChartData?.map((entry) => (
                                            <React.Fragment key={entry.x}>
                                                <Cell fillOpacity={0} strokeWidth={2} stroke={'black'} />
                                            </React.Fragment>
                                        ))}
                                    </Bar>
                                ))}
                            </>
                        ) : (
                            <Bar key={'Requirement.amount'} dataKey={'Requirement.amount'} name={'planned'} xAxisId={1}>
                                {sortedChartData?.map((entry) => (
                                    <React.Fragment key={entry.x}>
                                        <Cell fillOpacity={0} strokeWidth={2} stroke={'black'} />
                                    </React.Fragment>
                                ))}
                            </Bar>
                        ))}
                </BarChart>
            </ResponsiveContainer>
        </>
    );
};
