import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledCheckbox,
    ControlledInput,
    FormCard,
    FormGrid,
    IParams,
    ISaveSupplier,
    Page,
    Section,
} from '../../../shared';
import { DownloadCard } from '../../components';
import { useSaveSupplier, useSupplier } from '../../hooks';
import { useSupplierSchema } from '../../validators';

export const SupplierEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: supplier, isFetching } = useSupplier(id);

    const form = useForm<ISaveSupplier>({
        resolver: yupResolver(useSupplierSchema()),
        mode: 'all',
    });
    const { mutateAsync: saveSupplier, isPending: isPendingSave } = useSaveSupplier();

    useEffect(() => {
        if (supplier) {
            form.reset(supplier);
        }
    }, [supplier, form]);

    const onSubmit = useCallback(
        async (item: ISaveSupplier) => {
            await saveSupplier({ id, item });
            navigate('/admin/suppliers');
        },
        [id, navigate, saveSupplier],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            supplier ? <DownloadCard name={supplier.name} code={supplier.code} /> : <></>,
            <Button onClick={() => navigate('/admin/suppliers')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, supplier, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={supplier?.name || t('newSupplier')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isPendingSave || isFetching}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Section title={t('data')}>
                        <FormGrid>
                            <ControlledInput name="name" label={t('name')} required={true} />
                            {id && <ControlledInput name="code" label={t('code')} disabled />}
                        </FormGrid>
                        <ControlledCheckbox
                            name="showOnShipmentDocument"
                            label={t('showOnShipmentDocument')}
                            sx={{ pl: 2 }}
                        />
                    </Section>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
