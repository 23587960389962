import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, MenuItem, Stack } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledSelect,
    DialogTitleWithClose,
    IOrganisation,
    IParams,
    Loading,
    useOrganisationsList,
} from '../../../shared';
import { useSavePlanning } from '../../hooks';
import { IPlanning, IPlanningDataForm } from '../../models';
import { usePlanningDataSchema } from '../../validators';

interface Props {
    open: boolean;
    onClose: () => void;
    plannings: IPlanning[];
}

export const CreatePlanningDialog: FC<Props> = ({ open, onClose, plannings }) => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();

    const { data: organisations, isPending: isOrganisationsLoading } = useOrganisationsList({ pageSize: 100 });
    const { mutateAsync: savePlanning, isPending: isSaveLoading } = useSavePlanning();

    const availableOrganisations =
        organisations?.data.filter(
            (organisation) => !plannings.some((planning) => planning.organisation?.id === organisation.id),
        ) || [];

    const form = useForm<IPlanningDataForm>({
        resolver: yupResolver(usePlanningDataSchema()),
        mode: 'all',
    });

    const onSubmit = async (item: IPlanningDataForm) => {
        const result = await savePlanning({ id, item });
        navigate(`/planning/plannings/${result.id}/detail`);
    };

    useEffect(() => {
        if (open) {
            form.reset();
        }
    }, [form, open]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <FormProvider {...form}>
                <DialogTitleWithClose onClose={onClose}>{t('newPlanning')}</DialogTitleWithClose>
                <DialogContent>
                    {isOrganisationsLoading ? (
                        <Loading />
                    ) : (
                        <Box sx={{ py: 2 }}>
                            <ControlledSelect name="organisationId" label={t('organisation')} required>
                                {availableOrganisations.map((organisation: IOrganisation) => (
                                    <MenuItem value={organisation.id} key={organisation.id}>
                                        {organisation.name}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        </Box>
                    )}
                </DialogContent>

                <DialogActions>
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={form.handleSubmit(onSubmit)}
                            disabled={isSaveLoading}
                        >
                            {t('save')}
                        </Button>
                        <Button onClick={onClose}>{t('cancel')}</Button>
                    </Stack>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};
