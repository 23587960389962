export const commonTranslationsEN = {
    addBtn: 'Add',
    cancel: 'Cancel',
    confirm: 'confirm',
    'creation-successful': 'Creation successful',
    data: 'Data',
    delete: 'Delete',
    edit: 'Edit',
    goBack: 'Go back to the previous page',
    noInfo: 'No info available.',
    ok: 'ok',
    pageNotFoundErr: 'This page could not be found.',
    save: 'Save',
    search: 'Search',
    'something-went-wrong': 'Something went wrong',
    'update-successful': 'Update successful',
    logOut: 'Log out',
    name: 'Name',
    code: 'Code',
    type: 'Type',
    home: 'Home',
    download: 'Download',
    address: 'Address',
    street: 'Street',
    number: 'Number',
    postalCode: 'Postal code',
    city: 'City',
    country: 'Country',
    total: 'Total',
    all: 'All',
    date: 'Date',
    status: 'Status',
    next: 'Next',
    previous: 'Previous',
    attachments: 'Attachments',
    addAttachment: 'Add attachment',
    deleteAttachmentTitle: 'Remove attachment?',
    deleteAttachmentDescription: 'Are you sure you want to remove this attachment?',
    upload: 'Upload',
    uploadFile: 'Drop your file here, or click to select a file.',
    uploadFailed: 'Something went wrong while uploading the file.',
    complete: 'Complete',
};
