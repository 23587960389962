import { Block } from '@mui/icons-material';
import React, { SVGProps } from 'react';
import { LabelProps } from 'recharts';

export const renderBlacklistedLabel = ({
    x,
    width,
    height,
    value,
}: Omit<SVGProps<SVGTextElement>, 'viewBox'> & LabelProps) => {
    return (
        value && (
            <g>
                <foreignObject
                    x={(x as number) - 10 + (width as number) / 2}
                    y={100 - Number(height)}
                    width={20}
                    height={20}
                    textAnchor="middle"
                >
                    <Block sx={{ color: 'red' }} fontSize={'small'} />
                </foreignObject>
            </g>
        )
    );
};
