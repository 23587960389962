import React, { FC } from 'react';
import { Definition, formatDateString, formatDateTimeString, FormGrid, Widget } from '../../../shared';
import { ListAltTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { IShipment } from '../../models';

interface Props {
    shipment: IShipment;
}

export const ShipmentDetailInfo: FC<Props> = ({ shipment }) => {
    const { t } = useTranslation();

    return (
        <Widget title={t('shipment')} icon={<ListAltTwoTone color="primary" />}>
            <FormGrid xs={12} md={4}>
                <Definition label={t('date')}>{formatDateString(shipment.shipmentDate)}</Definition>
                <Definition label={t('organisation')}>{shipment.receiver?.name}</Definition>
                <Definition label={t('status')}>{t(shipment.status)}</Definition>
                <Definition label={t('mailStatus')}>{t(shipment.mailStatus)}</Definition>
                <Definition label={t('lastMailDate')}>{formatDateTimeString(shipment.lastMailDate)}</Definition>
            </FormGrid>
        </Widget>
    );
};
