export const planningTranslationsEN = {
    planning: 'Planning',

    dashboard: 'Dashboard',

    shipments: 'Shipments',
    shipment: 'Shipment',
    newShipment: 'New shipment',
    shipmentDate: 'Shipment date',
    shipmentDocument: 'Shipment document',
    receiver: 'Organisation',
    sender: 'Sender',
    editShipment: 'Edit shipment',
    removeShipment: 'Remove shipment',
    removeShipmentWarning: 'Are you sure you want to remove this shipment?',
    shipmentDetails: 'Shipment {{organisation}} - {{date}}',
    shipmentMail: 'Shipment mail',
    sendShipmentMail: 'Send shipment mail',
    completeShipment: 'Complete shipment',

    DRAFT: 'Draft',
    COMPLETED: 'Completed',
    PLANNED: 'Ingepland',

    mailStatus: 'Mail status',
    notifyShipment: 'Send shipment document',
    SENT: 'Mail sent',
    NOT_SENT: 'Mail not sent yet',
    FAILED: 'Mail failed',

    plannings: 'Plannings',
    newPlanning: 'New planning',
    removePlanning: 'Remove planning',
    removePlanningWarning: 'Are you sure you want to remove this planning?',
    planningDetails: 'Planning {{organisation}}',
    planningTiming: 'Planning starts on {{startDate}} and ends on {{endDate}}',
    pattern: 'Pattern',
    startDate: 'Start date',
    endDate: 'End date',
    week: 'Week',
    month: 'Month',
    workweek: 'Workweek',
    addWeek: 'Add week',
    day: 'Weekday',
    category: 'Category',
    addCategory: 'Add category',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    desiredAmountInWeight: 'Desired amount in gram',
};
