import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MenuItem } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledInput,
    ControlledSelect,
    FormCard,
    FormGrid,
    IOrganisationForm,
    IParams,
    Page,
    Section,
    useOrganisation,
    useOrganisationTypesList,
    useSaveOrganisation,
} from '../../../shared';
import { DownloadCard, OrganisationContacts } from '../../components';

import { organisationFromFormMapper, organisationToFormMapper } from '../../mappers';
import { useOrganisationSchema } from '../../validators';

export const OrganisationEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: organisation, isFetching } = useOrganisation(id);
    const { data: organisationTypes } = useOrganisationTypesList({ pageSize: 300 });
    const { mutateAsync: saveOrganisation, isPending: isPendingSave } = useSaveOrganisation();

    const form = useForm<IOrganisationForm>({
        resolver: yupResolver(useOrganisationSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (organisation) {
            form.reset(organisationToFormMapper(organisation));
        }
    }, [organisation, form]);

    const onSubmit = useCallback(
        async (item: IOrganisationForm) => {
            await saveOrganisation({ id, item: organisationFromFormMapper(item) });
            navigate('/admin/organisations');
        },
        [id, navigate, saveOrganisation],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            organisation ? <DownloadCard name={organisation.name} code={organisation.code} /> : <></>,
            <Button onClick={() => navigate('/admin/organisations')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, organisation, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={organisation?.name || t('newOrganisation')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isPendingSave || isFetching}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Section title={t('data')}>
                        <FormGrid>
                            <ControlledInput name="name" label={t('name')} required />
                            {id && <ControlledInput name="code" label={t('code')} disabled />}
                            <ControlledSelect name="type" label={t('type')} required>
                                {organisationTypes?.data.map((type) => (
                                    <MenuItem key={type.id} value={type.id}>
                                        {type.name}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        </FormGrid>
                    </Section>
                    <Section title={t('address')}>
                        <FormGrid>
                            <ControlledInput name="address.street" label={t('street')} />
                            <ControlledInput name="address.number" label={t('number')} />
                            <ControlledInput name="address.postalCode" label={t('postalCode')} />
                            <ControlledInput name="address.city" label={t('city')} />
                            <ControlledInput name="address.country" label={t('country')} />
                        </FormGrid>
                    </Section>
                    <OrganisationContacts />
                </FormCard>
            </FormProvider>
        </Page>
    );
};
