import { useCallback } from 'react';
import { IProgress } from '../models';

export function useGetProgressColor() {
    return useCallback((progressEntry: IProgress) => {
        const differencePercentage =
            (progressEntry.totalPlanned - progressEntry.totalScanned) / progressEntry.totalPlanned;
        if (differencePercentage < -0.05) {
            return 'red';
        } else if (differencePercentage < 0.05) {
            return 'green';
        }
        return 'yellow';
    }, []);
}
