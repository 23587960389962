import { QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { planningsClient } from '../clients';
import { IPlanning, IPlanningQuery } from '../models';

const client = planningsClient;
const listKey = QueryKeys.Plannings;
const itemKey = QueryKeys.Planning;

export function usePlanningsList(...args: UseListParams<IPlanning, IPlanningQuery>) {
    return useList(client, listKey, ...args);
}

export function usePlanning(...args: UseItemParams<IPlanning>) {
    return useItem(client, itemKey, ...args);
}

export function useSavePlanning() {
    return useSave(client, listKey, itemKey);
}

export function useDeletePlanning() {
    return useDelete(client, listKey);
}
