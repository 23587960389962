import { Checkbox, ListItemText, MenuItem } from '@mui/material';
import { FC, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledSelect } from '../../../shared';
import { IFilter } from '../../models';
import { useProductCategoriesList } from '../../../admin/hooks';

export const ReportFilterCategoriesSelect: FC<{
    form: UseFormReturn<IFilter>;
    filter: Partial<IFilter>;
    onChange: (filter: IFilter) => void;
}> = ({ filter, form, onChange }) => {
    const { t } = useTranslation();
    const { data: productCategories } = useProductCategoriesList({ pageSize: 100 });

    useEffect(() => {
        if (productCategories && !filter.productCategories) {
            const productCategoryValues = [...productCategories?.data?.map(({ id }) => id), ''];
            form.setValue('productCategories', productCategoryValues);
            onChange({ ...filter, productCategories: productCategoryValues });
        }
    }, [productCategories, form, filter, onChange]);

    return (
        <ControlledSelect
            name="productCategories"
            label={t('category')}
            multiple
            renderValue={(selected) =>
                (selected as string[])?.length == (productCategories?.pagination.size || 0) + 1
                    ? t('all')
                    : (selected as string[])
                          .map((value) => productCategories?.data?.find((item) => item.id == value))
                          .map((item) => (item?.name ? item.name : t('unknown')))
                          .join(', ')
            }
            items={productCategories?.data ? [...productCategories?.data?.map(({ id }) => id), ''] : ['']}
            size="small"
        >
            <MenuItem value="all">
                <Checkbox
                    checked={filter?.productCategories?.length === (productCategories?.pagination?.size || 0) + 1}
                />
                <ListItemText primary={t('all')} />
            </MenuItem>
            {productCategories?.data?.map((category) => (
                <MenuItem value={category.id} key={category.id}>
                    <Checkbox checked={filter?.productCategories?.includes(category.id)} />
                    <ListItemText primary={category.name} />
                </MenuItem>
            ))}
            <MenuItem value="">
                <Checkbox checked={filter?.productCategories?.includes('')} />
                <ListItemText primary={t('unknown')} />
            </MenuItem>
        </ControlledSelect>
    );
};
