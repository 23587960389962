import { createContext, FC, ReactNode, useContext, useState } from 'react';

interface IDrawerContext {
    showDrawer: boolean;
    setShowDrawer: (show: boolean) => void;
}

type Props = {
    children?: ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const DrawerContext = createContext<IDrawerContext>({ showDrawer: false, setShowDrawer: () => {} });

export const DrawerContextProvider: FC<Props> = ({ children }) => {
    const [showDrawer, setShowDrawer] = useState(false);

    return <DrawerContext.Provider value={{ showDrawer, setShowDrawer }}>{children}</DrawerContext.Provider>;
};

export const useShowDrawer = (): IDrawerContext => useContext(DrawerContext);
