import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Page, PermissionKeys, ProtectedRoute } from '../../../shared';
import { ShipmentAttachmentsPage } from '../shipment-attachments/shipment-attachments.page';
import { ShipmentContentPage } from '../shipment-content/shipment-content.page';
import { ShipmentDataPage } from '../shipment-data/shipment-data.page';

export const ShipmentWizardPage: FC = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const activeStep = useMemo(() => {
        const activeStepPath = location.pathname.split('/').pop();
        return Math.max(['', 'content', 'attachments'].indexOf(`${activeStepPath}`), 0);
    }, [location]);

    return (
        <Page>
            <Box sx={{ p: 3, pt: 0 }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step>
                        <StepLabel>{t('data')}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{t('content')}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{t('attachments')}</StepLabel>
                    </Step>
                </Stepper>
            </Box>
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute requiredPermission={PermissionKeys.SHIPMENTS_WRITE}>
                            <ShipmentDataPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/content"
                    element={
                        <ProtectedRoute requiredPermission={PermissionKeys.SHIPMENTS_WRITE}>
                            <ShipmentContentPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/attachments"
                    element={
                        <ProtectedRoute requiredPermission={PermissionKeys.SHIPMENTS_WRITE}>
                            <ShipmentAttachmentsPage />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Page>
    );
};
