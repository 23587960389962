import { AttachFileTwoTone } from '@mui/icons-material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Widget } from '../../../shared';
import { IShipment } from '../../models';
import { Asset } from '../asset/asset.component';

interface Props {
    shipment: IShipment;
}

export const ShipmentDetailAttachments: FC<Props> = ({ shipment }) => {
    const { t } = useTranslation();

    return (
        <Widget title={t('attachments')} icon={<AttachFileTwoTone color="primary" />}>
            <Asset
                asset={{
                    id: 'shipmentDocument',
                    fileName: t('shipmentDocument'),
                    mimeType: 'application/pdf',
                    downloadUrl: `/shipments/${shipment.id}/document`,
                }}
                height="fit-content"
            />

            {shipment.assets.map((asset) => (
                <Asset asset={asset} />
            ))}
        </Widget>
    );
};
