import { ISupplier, QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { suppliersClient } from '../clients';

const client = suppliersClient;
const listKey = QueryKeys.Suppliers;
const itemKey = QueryKeys.Supplier;

export function useSuppliersList(...args: UseListParams<ISupplier>) {
    return useList(client, listKey, ...args);
}

export function useSupplier(...args: UseItemParams<ISupplier>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveSupplier() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteSupplier() {
    return useDelete(client, listKey);
}
