import { useMemo } from 'react';
import { boolean, object, string } from 'yup';

export function useSupplierSchema() {
    return useMemo(
        () =>
            object().shape({
                name: string().required(),
                showOnShipmentDocument: boolean().required(),
            }),
        [],
    );
}
