import { Search as SearchIcon } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    formatDateString,
    mapDateStringToApiDate,
    Page,
    PermissionKeys,
    RowActions,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParamsContext,
} from '../../../shared';
import { ShipmentsFilter } from '../../components';
import { useDeleteShipment, useShipmentsList } from '../../hooks';

export const ShipmentsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SHIPMENTS_WRITE);

    const {
        queryParams: { page, pageSize, filter, sort },
        setFilter,
        setPage,
        setPageSize,
        setSort,
    } = useQueryParamsContext('shipments', {
        sort: [{ field: 'shipmentDate', sort: 'desc' }],
    });

    const { mutateAsync: deleteShipment } = useDeleteShipment();
    const { isPending, data } = useShipmentsList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        shipmentDate: mapDateStringToApiDate(filter.shipmentDate) || undefined,
        status: filter.status || undefined,
        receiverId: filter.receiverId || undefined,
        mailStatus: filter.mailStatus || undefined,
    });

    const columns: GridColDef[] = [
        {
            field: 'shipmentDate',
            valueGetter: ({ row }) => formatDateString(row.shipmentDate),
            headerName: t('shipmentDate'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'receiver',
            headerName: t('receiver'),
            valueFormatter: ({ value }) => value?.name,
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
        {
            field: 'status',
            headerName: t('status'),
            valueFormatter: ({ value }) => t(value as string),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'mailStatus',
            headerName: t('mailStatus'),
            valueFormatter: ({ value }) => t(value as string),
            minWidth: 150,
            flex: 1,
        },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    <IconButton onClick={() => navigate(`/planning/shipments/${id}/detail`)}>
                        <SearchIcon />
                    </IconButton>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/planning/shipments/${id}`)}
                            onDelete={() => deleteShipment(id)}
                            deleteWarningTitle={t('removeShipment')}
                            deleteWarningText={t('removeShipmentWarning')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('shipments')}
            filter={<ShipmentsFilter filter={filter} onChange={setFilter} />}
            actions={
                hasWritePermission
                    ? [
                          <Button
                              component={Link}
                              to="/planning/shipments/shipments-complete-wizard"
                              color="primary"
                              variant="outlined"
                          >
                              {t('completeShipments')}
                          </Button>,
                          <Button
                              component={Link}
                              to="/planning/shipments/new"
                              color="primary"
                              variant="contained"
                              data-testid="new-shipment"
                          >
                              {t('newShipment')}
                          </Button>,
                      ]
                    : []
            }
        >
            <TableComponent
                rows={data?.data || []}
                columns={columns}
                rowCount={data?.pagination.size || 0}
                paginationModel={{ page: page ? page - 1 : 0, pageSize: pageSize || 10 }}
                onPaginationModelChange={(value) => {
                    setPage(value?.page + 1);
                    setPageSize(value?.pageSize || 5);
                }}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => navigate(`/planning/shipments/${row.id}/detail`)}
            />
        </Page>
    );
};
