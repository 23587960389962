import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QueryKeys } from '../../shared';
import { planningsClient } from '../clients';
import { ISavePattern } from '../models';

export function useSavePlanningPattern(): UseMutationResult<
    void,
    AxiosError,
    { planning: string; pattern: ISavePattern }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ planning, pattern }) => planningsClient.createPattern(planning, pattern),

        onSuccess: async (r, { planning }) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Planning, planning] });
        },
    });
}
