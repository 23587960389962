import { Delete } from '@mui/icons-material';
import { Button, IconButton, Stack } from '@mui/material';
import { FC } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledInput, Section } from '../../../shared';

export const ProductDietInformation: FC = () => {
    const { t } = useTranslation();
    const dietInformation = useFieldArray({ name: 'dietInformation' });

    return (
        <Section title={t('dietInformation')}>
            <Stack spacing={2} sx={{ pl: 2 }}>
                {dietInformation.fields.map((field, index) => (
                    <Stack alignItems="flex-start" spacing={2} direction="row" key={field.id}>
                        <ControlledInput name={`dietInformation.${index}.value`} label={t('dietInformationEntry')} />
                        <IconButton onClick={() => dietInformation.remove(index)}>
                            <Delete />
                        </IconButton>
                    </Stack>
                ))}
            </Stack>
            <Button size="small" onClick={() => dietInformation.append({ value: '' })}>
                {t('addDietInformation')}
            </Button>
        </Section>
    );
};
