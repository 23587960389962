import { useDelete, useEntityMap, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { organisationsClient } from '../clients';
import { IOrganisation } from '../models';
import { QueryKeys } from '../enums';

const client = organisationsClient;
const listKey = QueryKeys.Organisations;
const itemKey = QueryKeys.Organisation;

export function useOrganisationsList(...args: UseListParams<IOrganisation>) {
    return useList(client, listKey, ...args);
}

export function useOrganisation(...args: UseItemParams<IOrganisation>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveOrganisation() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteOrganisation() {
    return useDelete(client, listKey);
}

export function useOrganisationsMap(): Record<string, string> {
    const { data: organisations } = useOrganisationsList({ pageSize: 100 });
    return useEntityMap(organisations);
}
