import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    formatDateString,
    mapDateStringToApiDate,
    Page,
    PermissionKeys,
    RowActions,
    Search,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParams,
} from '../../../shared';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { Box, Button, IconButton } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useBatchesList, useDeleteBatch } from '../../hooks';

export const BatchesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.BATCHES_WRITE);
    const [filterDate, setFilterDate] = useState<string | null>();

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'date', sort: 'desc' as GridSortDirection }],
    });

    const { mutateAsync: deleteBatch } = useDeleteBatch();
    const { data: batches, isPending } = useBatchesList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        date: mapDateStringToApiDate(filterDate) || undefined,
        search: debouncedSearch,
    });

    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: t('date'),
            minWidth: 150,
            flex: 1,
            valueFormatter: ({ value }) => formatDateString(value),
        },
        {
            field: 'supplier',
            headerName: t('supplier'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: ({ value }) => value?.name,
        },
        { field: 'referenceNumber', headerName: t('referenceNumber'), minWidth: 150, flex: 1, sortable: false },
        {
            field: 'qrCode',
            headerName: t('qrCode'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: ({ value }) => value?.name,
        },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => [
                <IconButton onClick={() => navigate(`/stock/batches/${id}/detail`)} key={'search'}>
                    <SearchIcon />
                </IconButton>,
                <Box key={'rowActions'}>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/stock/batches/${id}/edit`)}
                            onDelete={() => deleteBatch(id)}
                            deleteWarningTitle={t('batchDeleteWarningTitle')}
                            deleteWarningText={t('batchDeleteWarningText')}
                        />
                    )}
                </Box>,
            ],
        },
    ];

    return (
        <Page
            title={t('batches')}
            actions={[
                <DatePicker
                    label={t('date')}
                    value={filterDate}
                    onChange={setFilterDate}
                    format="dd/MM/yyyy"
                    slotProps={{
                        textField: {
                            size: 'small',
                        },
                    }}
                />,
                <Search search={search} onSearch={setSearch} />,
                hasWritePermission && (
                    <Button
                        component={Link}
                        to="/stock/batches/new"
                        color="primary"
                        variant="contained"
                        data-testid="new-batch"
                    >
                        {t('newBatch')}
                    </Button>
                ),
            ]}
        >
            <TableComponent
                rows={batches?.data || []}
                columns={columns}
                rowCount={batches?.pagination.size || 0}
                paginationModel={{ page: page ? page - 1 : 0, pageSize: pageSize || 10 }}
                onPaginationModelChange={(value) => {
                    setPage(value?.page + 1);
                    setPageSize(value?.pageSize || 5);
                }}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => navigate(`/stock/batches/${row.id}/detail`)}
            />
        </Page>
    );
};
