import { DeleteOutline } from '@mui/icons-material';
import { Box, Button, Grid, IconButton } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { UseFieldArrayReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useProductsList } from '../../../admin/hooks';
import { ControlledAutocomplete, useDebouncedState } from '../../../shared';
import { IPlanningEntry, IPlanningEntryForm } from '../../models';
import { EntryAmount } from '../entry-amount/entry-amount.component';

interface Props {
    requirements: UseFieldArrayReturn<IPlanningEntryForm, 'requirements', 'id'>;
    planningEntry?: IPlanningEntry;
}

export const ProductRequirements: FC<Props> = ({ requirements, planningEntry }) => {
    const { t } = useTranslation();
    const [debouncedSearch, , setSearch] = useDebouncedState('');
    const { data: products, isPending: productsIsLoading } = useProductsList({
        isDraft: false,
        search: debouncedSearch,
    });

    return (
        <Box sx={{ pt: 1 }}>
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                {requirements.fields.map((requirement, index) => (
                    <Fragment key={requirement.id}>
                        {!requirement.isCategory && (
                            <>
                                <Grid item xs={5.5} sx={{ textAlign: 'right' }}>
                                    <ControlledAutocomplete
                                        name={`requirements.${index}.product`}
                                        defaultValue={null}
                                        loading={productsIsLoading}
                                        options={debouncedSearch ? products?.data || [] : []}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={(product) => product.name}
                                        noOptionsText={
                                            debouncedSearch ? t('noProductsFound') : t('startSearchingProducts')
                                        }
                                        onInputChange={(e, value) => setSearch(value)}
                                        size="small"
                                        disabled={!!requirement.product?.name}
                                    />
                                </Grid>
                                <Grid item xs={2.5}>
                                    <EntryAmount
                                        path={`requirements.${index}`}
                                        label={t('amount')}
                                        required
                                        disabledWhenProductRequiresReservation={!!planningEntry?.patternEntry}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => requirements.remove(index)}>
                                        <DeleteOutline />
                                    </IconButton>
                                </Grid>
                            </>
                        )}
                    </Fragment>
                ))}
            </Grid>

            <Button
                onClick={() =>
                    requirements.append({
                        product: null,
                        amount: 0,
                        isCategory: false,
                    })
                }
            >
                + {t('addProduct')}
            </Button>
        </Box>
    );
};
