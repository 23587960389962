import { ListAltTwoTone } from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IStockLocation } from '../../../admin/models';
import { Widget } from '../../../shared';
import { IStockProduct } from '../../models';

interface Props {
    stockProduct: IStockProduct;
}

export const StockProductLocations: FC<Props> = ({ stockProduct: { stockCounts } }) => {
    const { t } = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'stockLocation',
            headerName: t('name'),
            minWidth: 150,
            flex: 1,
            valueFormatter: ({ value }) => (value as IStockLocation).name,
        },
        { field: 'amount', headerName: t('amount'), minWidth: 150, flex: 1 },
    ];

    const stockCountsWithTotal = useMemo(() => {
        const total = stockCounts.reduce((total, { amount }) => total + amount, 0);
        return [...stockCounts, { id: 'TOTAL', stockLocation: { name: t('total') }, amount: total }];
    }, [stockCounts, t]);

    return (
        <Widget title={t('stockLocations')} icon={<ListAltTwoTone color="primary" />} hasTable>
            <DataGrid
                rows={stockCountsWithTotal}
                columns={columns}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                hideFooter
                autoHeight
            />
        </Widget>
    );
};
