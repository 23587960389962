import { RefreshOutlined } from '@mui/icons-material';
import { IconButton, MenuItem } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    checkDifferentFilterAndFormValues,
    ControlledDatePicker,
    ControlledSelect,
    FormGrid,
    Options,
    OptionsToggle,
} from '../../../shared';
import { TimeGranularity } from '../../enums';
import { Report } from '../../enums/report.enum';
import { IFilter } from '../../models';
import { ReportFilterCategoriesSelect } from './report-filter-categories-select.component';
import { ReportFilterOrganisationsSelect } from './report-filter-organisations-select.component';
import { ReportFilterSuppliersSelect } from './report-filter-suppliers-select.component';

interface Props {
    filter: IFilter;
    onChange: (filter: IFilter) => void;
    report: Report;
}

export const ReportFilter: FC<Props> = ({ filter, onChange, report }) => {
    const { t } = useTranslation();
    const defaultOptions: Options = useMemo(
        () => ({
            filter: { type: 'title', label: t('filter') },
            endDate: { active: false, label: t('endDate') },
            supplier: { active: false, label: t('supplier') },
            organisation: { active: false, label: t('organisation') },
            category: { active: false, label: t('category') },
            options: { type: 'title', label: t('options') },
            grouping: { active: false, label: t('grouping') },
            showPlanned: { active: false, label: t('showPlanned') },
            showTotalCategories: { active: false, label: t('showTotalCategories') },
        }),
        [t],
    );
    const [options, setOptions] = useState<Options>(defaultOptions);

    useEffect(() => {
        if (report === Report.SUPPLIED) {
            setOptions({
                ...defaultOptions,
                showPlanned: {
                    active: false,
                    label: t('showPlanned'),
                    hide: true,
                },
                supplier: { active: false, label: t('supplier'), hide: false },
            });
        } else if (report === Report.REDISTRIBUTED) {
            setOptions({
                ...defaultOptions,
                showPlanned: { active: false, label: t('showPlanned'), hide: false },
                supplier: { active: false, label: t('supplier'), hide: true },
            });
        }
    }, [defaultOptions, report, t]);

    const form = useForm<IFilter>({ mode: 'onChange' });
    const formValues = form.watch();

    useEffect(() => {
        form.reset({ ...filter });
    }, [form, filter]);

    useEffect(() => {
        form.setValue('showPlanned', options.showPlanned.active);
    }, [form, options.showPlanned.active]);

    useEffect(() => {
        form.setValue('showTotalCategories', options.showTotalCategories.active);
    }, [form, options.showTotalCategories.active]);

    useEffect(() => {
        const { endDate, supplier, organisation, category, grouping } = options;
        if (!endDate.active) {
            form.setValue('endDate', undefined);
        }

        if (!supplier.active) {
            form.setValue('suppliers', undefined);
        }

        if (!organisation.active) {
            form.setValue('organisations', undefined);
        }

        if (!category.active) {
            form.setValue('productCategories', undefined);
        }

        if (!grouping.active) {
            form.setValue('grouping', undefined);
        }
    }, [form, options]);

    const showReload = !!Object.keys(formValues).length && checkDifferentFilterAndFormValues(formValues, filter);

    return (
        <FormProvider {...form}>
            <FormGrid alignItems="center">
                <OptionsToggle options={options} onChange={setOptions} />

                <ControlledDatePicker name="startDate" label={t('startDate')} size="small" />

                {options.endDate.active && <ControlledDatePicker name="endDate" label={t('endDate')} size="small" />}

                {options.supplier.active && (
                    <ReportFilterSuppliersSelect form={form} onChange={onChange} report={report} filter={formValues} />
                )}

                {options.organisation.active && (
                    <ReportFilterOrganisationsSelect
                        form={form}
                        onChange={onChange}
                        report={report}
                        filter={formValues}
                    />
                )}

                {options.category.active && (
                    <ReportFilterCategoriesSelect form={form} onChange={onChange} filter={formValues} />
                )}

                {options.grouping.active && (
                    <ControlledSelect name="grouping" label={t('grouping')} size="small" displayEmpty>
                        <MenuItem value="">{t('none')}</MenuItem>
                        {Object.values(TimeGranularity).map((granularity) => (
                            <MenuItem value={granularity} key={granularity}>
                                {t(granularity)}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}

                {showReload && (
                    <IconButton onClick={() => onChange(formValues)}>
                        <RefreshOutlined color="primary" />
                    </IconButton>
                )}
            </FormGrid>
        </FormProvider>
    );
};
