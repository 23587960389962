import { Search as SearchIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page, Search, SortOrder, TableComponent, useQueryParams } from '../../../shared';
import { useStockProductsList } from '../../hooks';
import { IStockCount } from '../../models';

const calculateTotalAmountInStock = (stockCounts: IStockCount[]): number =>
    stockCounts.reduce((totalInStock, stockCount) => totalInStock + stockCount.amount, 0);

export const StockProductsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } =
        useQueryParams();

    const { isPending, data } = useStockProductsList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('name'), minWidth: 150, flex: 1 },
        { field: 'code', headerName: t('code'), minWidth: 150, flex: 1 },
        {
            field: 'category',
            headerName: t('productCategory'),
            minWidth: 150,
            flex: 1,
            valueFormatter: ({ value }) => value?.name,
            sortable: false,
        },
        { field: 'weight', headerName: t('weight'), minWidth: 150, flex: 1 },
        {
            field: 'stockCounts',
            headerName: t('stockCounts'),
            minWidth: 150,
            flex: 1,
            valueFormatter: ({ value }) => calculateTotalAmountInStock(value as IStockCount[]),
            sortable: false,
        },
        {
            field: 'measurementUnit',
            headerName: t('measurementUnit'),
            minWidth: 150,
            flex: 1,
            valueFormatter: ({ value }) => t(value as string),
        },
        {
            field: ' ',
            headerName: '',
            width: 50,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <IconButton onClick={() => navigate(`/stock/products/${id}`)}>
                    <SearchIcon />
                </IconButton>
            ),
        },
    ];

    return (
        <Page title={t('stockProducts')} actions={[<Search search={search} onSearch={setSearch} />]}>
            <TableComponent
                rows={data?.data || []}
                columns={columns}
                rowCount={data?.pagination.size || 0}
                paginationModel={{ page: page ? page - 1 : 0, pageSize: pageSize || 10 }}
                onPaginationModelChange={(value) => {
                    setPage(value?.page + 1);
                    setPageSize(value?.pageSize || 5);
                }}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => navigate(`/stock/products/${row.id}`)}
            />
        </Page>
    );
};
