import { Button, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    formatDateString,
    isBadRequest,
    mapDateToApiDate,
    Page,
    PermissionKeys,
    RowActions,
    Search,
    SortOrder,
    TableComponent,
    useDeleteFeadDelivery,
    useFeadDeliveriesList,
    useHasPermission,
    useQueryParamsContext,
    WarningDialog,
} from '../../../shared';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const FeadDeliveriesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showFeadDeliveryInUse, setShowFeadDeliveryInUse] = useState(false);
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.FEAD_DELIVERIES_WRITE);

    const {
        queryParams: { page, pageSize, sort, search, filter },
        debouncedQueryParams,
        setPage,
        setPageSize,
        setSort,
        setSearch,
        setFilter,
    } = useQueryParamsContext('deliveries', {
        sort: [{ field: 'date', sort: 'desc' }],
    });

    const { mutateAsync: deleteFeadDelivery } = useDeleteFeadDelivery();
    const { isPending, data } = useFeadDeliveriesList({
        page,
        pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedQueryParams.search,
        date: mapDateToApiDate(filter.date) || undefined,
        isDelivered: !filter.isNotDelivered,
    });

    const onDelete = async (id: string) => {
        try {
            await deleteFeadDelivery(id);
        } catch (err) {
            if (isBadRequest(err)) return setShowFeadDeliveryInUse(true);
            throw err;
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: t('date'),
            minWidth: 150,
            flex: 1,
            valueFormatter: ({ value }) => formatDateString(value),
        },
        {
            field: 'product',
            headerName: t('product'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueGetter: ({ row }) => row.product.name,
        },
        {
            field: ' ',
            headerName: '',
            width: 200,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/fead/deliveries/${id}/edit`)}
                            onDelete={() => onDelete(id)}
                            deleteWarningTitle={t('feadDeliveryDeleteWarningTitle')}
                            deleteWarningText={t('feadDeliveryDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('feadDeliveries')}
            actions={[
                <Stack direction="row" alignItems="center" pr={3}>
                    <FormControlLabel
                        label={t('notDelivered')}
                        control={
                            <Checkbox
                                checked={filter.isNotDelivered}
                                onChange={(e) => setFilter({ ...filter, isNotDelivered: e.target.checked })}
                            />
                        }
                    />
                </Stack>,
                <DatePicker
                    label={t('date')}
                    value={filter.date}
                    onChange={(value) => setFilter({ ...filter, date: value })}
                    format="dd/MM/yyyy"
                    sx={{ maxWidth: '200px' }}
                    slotProps={{
                        textField: {
                            size: 'small',
                        },
                        field: { clearable: true },
                        inputAdornment: { position: 'start' },
                    }}
                />,
                <Search search={search} onSearch={setSearch} />,
                hasWritePermission && (
                    <Button component={Link} to="/fead/deliveries/new" color="primary" variant="contained">
                        {t('newFeadDelivery')}
                    </Button>
                ),
            ]}
        >
            <TableComponent
                rows={data?.data || []}
                columns={columns}
                rowCount={data?.pagination.size || 0}
                paginationModel={{ page: page ? page - 1 : 0, pageSize: pageSize || 10 }}
                onPaginationModelChange={(value) => {
                    setPage(value?.page + 1);
                    setPageSize(value?.pageSize || 5);
                }}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => navigate(`/fead/deliveries/${row.id}/edit`)}
            />
            <WarningDialog
                open={showFeadDeliveryInUse}
                onClose={() => setShowFeadDeliveryInUse(false)}
                title={t('feadDeliveryInUseTitle')}
                text={t('feadDeliveryInUseDescription')}
            />
        </Page>
    );
};
