import { Delete } from '@mui/icons-material';
import { Button, IconButton, Stack } from '@mui/material';
import { FC } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledInput, Section } from '../../../shared';

export const ProductLinks: FC = () => {
    const { t } = useTranslation();
    const links = useFieldArray({ name: 'links' });

    return (
        <Section title={t('links')}>
            <Stack spacing={2} sx={{ pl: 2 }}>
                {links.fields.map((field, index) => (
                    <Stack alignItems="flex-start" spacing={2} direction="row" key={field.id}>
                        <ControlledInput name={`links.${index}.url`} label={t('linksEntry')} />
                        <IconButton onClick={() => links.remove(index)}>
                            <Delete />
                        </IconButton>
                    </Stack>
                ))}
            </Stack>
            <Button size="small" onClick={() => links.append({ url: '' })}>
                {t('addLink')}
            </Button>
        </Section>
    );
};
