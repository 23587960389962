import { BaseClient, logisticsApi } from '../../shared';
import { IDevice, ISaveDevice } from '../models';

class DevicesClient extends BaseClient<IDevice, ISaveDevice> {
    constructor() {
        super(logisticsApi, '/devices');
    }
}

export const devicesClient = new DevicesClient();
