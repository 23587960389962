import { Button } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    Page,
    PermissionKeys,
    RowActions,
    Search,
    SortOrder,
    TableComponent,
    useDeleteOrganisationType,
    useHasPermission,
    useOrganisationTypesList,
    useQueryParams,
} from '../../../shared';

export const OrganisationTypesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.ORGANISATION_TYPES_WRITE);

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } =
        useQueryParams();

    const { isPending, data } = useOrganisationTypesList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    const { mutate: deleteOrganisationType } = useDeleteOrganisationType();

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('name'), minWidth: 150, flex: 1 },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/admin/organisation-types/${id}/edit`)}
                            onDelete={() => deleteOrganisationType(id)}
                            deleteWarningTitle={t('organisationTypeDeleteWarningTitle')}
                            deleteWarningText={t('organisationTypeDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('organisationTypes')}
            actions={[
                <Search search={search} onSearch={setSearch} />,
                hasWritePermission && (
                    <Button
                        component={Link}
                        to="/admin/organisation-types/new"
                        color="primary"
                        variant="contained"
                        data-testid="new-organisationType"
                    >
                        {t('newOrganisationType')}
                    </Button>
                ),
            ]}
        >
            <TableComponent
                rows={data?.data || []}
                columns={columns}
                rowCount={data?.pagination.size || 0}
                paginationModel={{ page: page ? page - 1 : 0, pageSize: pageSize }}
                onPaginationModelChange={(value) => {
                    setPage(value?.page + 1);
                    setPageSize(value?.pageSize || 5);
                }}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => hasWritePermission && navigate(`/admin/organisation-types/${row.id}/edit`)}
            />
        </Page>
    );
};
