import { QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams } from '../../shared';
import { stockMovementsClient } from '../clients';
import { ISaveStockMovement, IStockMovement, IStockMovementQuery } from '../models';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const client = stockMovementsClient;
const listKey = QueryKeys.StockMovements;
const itemKey = QueryKeys.StockMovement;

export function useStockMovementsList(...args: UseListParams<IStockMovement, IStockMovementQuery>) {
    return useList(client, listKey, ...args);
}

export function useStockMovement(...args: UseItemParams<IStockMovement>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveStockMovement(): UseMutationResult<
    IStockMovement,
    AxiosError,
    { item: ISaveStockMovement; id?: string; ignoreBlacklistedWarning: boolean; ignoreInUseWarning: boolean }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ item, id, ignoreBlacklistedWarning, ignoreInUseWarning }) =>
            id
                ? client.updateStockMovement(id, item, ignoreInUseWarning)
                : client.createStockMovement(item, ignoreBlacklistedWarning),
        onSuccess: async ({ id }) => {
            await queryClient.invalidateQueries({ queryKey: [listKey] });
            await queryClient.invalidateQueries({ queryKey: [itemKey, id] });
        },
    });
}

export function useDeleteStockMovement() {
    return useDelete(client, listKey);
}
