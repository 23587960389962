import { BaseClient, logisticsApi } from '../../shared';
import { IProductCategory, ISaveProductCategory } from '../models';

class ProductCategoriesClient extends BaseClient<IProductCategory, ISaveProductCategory> {
    constructor() {
        super(logisticsApi, '/product-categories');
    }
}

export const productCategoriesClient = new ProductCategoriesClient();
