import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormCard, IParams, IProduct, IProductForm, Page } from '../../../shared';
import {
    DownloadCard,
    ProductAllergenInformation,
    ProductCategories,
    ProductContent,
    ProductData,
    ProductDietInformation,
    ProductLinks,
    ProductMeasurement,
} from '../../components';
import { useProduct, useSaveProduct } from '../../hooks';
import { productFromFormMapper, productToFormMapper } from '../../mappers';
import { useProductSchema } from '../../validators';

export const ProductEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { id } = useParams<keyof IParams>() as IParams;

    const { data: product, isFetching } = useProduct(id);
    const { mutateAsync: saveProduct, isPending: isPendingSave } = useSaveProduct();

    const form = useForm<IProductForm>({
        resolver: yupResolver(useProductSchema()),
        mode: 'all',
        defaultValues: productToFormMapper(product || ({ name: '' } as IProduct)),
    });

    useEffect(() => {
        if (product) {
            form.reset(productToFormMapper(product));
        }
    }, [product, form]);

    const onSubmit = useCallback(
        async (item: IProductForm) => {
            await saveProduct({ id, item: productFromFormMapper(item) });
            navigate(pathname.startsWith('/admin/draft-products') ? '/admin/draft-products' : '/admin/products');
        },
        [id, navigate, pathname, saveProduct],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            product ? <DownloadCard name={product.name} code={product.code} /> : <></>,
            <Button onClick={() => navigate('/admin/products')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, product, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={product?.name || t('newProduct')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isPendingSave}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <ProductData id={id} />
                    <ProductMeasurement />
                    <ProductCategories />
                    <ProductContent />
                    <ProductDietInformation />
                    <ProductAllergenInformation />
                    <ProductLinks />
                </FormCard>
            </FormProvider>
        </Page>
    );
};
