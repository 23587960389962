import { Box, Button, Dialog, DialogContent, FormHelperText, Stack } from '@mui/material';
import { GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { FC, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    DialogTitleWithClose,
    mapDateStringToApiDate,
    Search,
    SortOrder,
    TableComponent,
    useQueryParams,
} from '../../../shared';
import { IStockMovementBatchForm } from '../../models';
import { useBatchesList } from '../../hooks';

type Props = {
    value?: IStockMovementBatchForm | null;
    onChange: (value: IStockMovementBatchForm) => void;
    error?: FieldError;
};

export const BatchPicker: FC<Props> = ({ value: selectedBatch, onChange, error }) => {
    const { t } = useTranslation();
    const [showPicker, setShowPicker] = useState(false);
    const [filterDate, setFilterDate] = useState<string | null>(null);

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'date', sort: 'desc' as GridSortDirection }],
        defaultPageSize: 5,
    });

    const onClose = () => {
        setShowPicker(false);
        setFilterDate(null);
        setSearch('');
    };

    const { data: batches, isPending } = useBatchesList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        date: mapDateStringToApiDate(filterDate) || undefined,
        search: debouncedSearch,
    });

    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: t('date'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'supplier',
            headerName: t('supplier'),
            minWidth: 150,
            flex: 1,
            renderCell: ({ value }) => value.name,
        },
        {
            field: 'referenceNumber',
            headerName: t('referenceNumber'),
            minWidth: 150,
            flex: 1,
        },
    ];

    return (
        <>
            <Button variant="contained" size="small" onClick={() => setShowPicker(true)}>
                {selectedBatch ? t('alterBatch') : t('selectBatch')}
            </Button>

            {error && <FormHelperText error>{error.message}</FormHelperText>}

            <Dialog open={showPicker} onClose={onClose}>
                <DialogTitleWithClose onClose={onClose}>{t('pickBatch')}</DialogTitleWithClose>
                <DialogContent>
                    <Stack direction="row" spacing={2} py={2}>
                        <DatePicker
                            label={t('date')}
                            value={filterDate}
                            onChange={setFilterDate}
                            format="dd/MM/yyyy"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                },
                            }}
                        />
                        <Search search={search} onSearch={setSearch} />
                    </Stack>
                    <Box sx={{ height: 371, overflow: 'auto' }}>
                        <TableComponent
                            rows={batches?.data || []}
                            columns={columns}
                            rowCount={batches?.pagination.size || 0}
                            paginationModel={{ page: page ? page - 1 : 0, pageSize: pageSize || 5 }}
                            onPaginationModelChange={(value) => {
                                setPage(value?.page + 1);
                                setPageSize(value?.pageSize || 5);
                            }}
                            pageSizeOptions={[5]}
                            loading={isPending}
                            sortModel={sort}
                            onSortModelChange={setSort}
                            defaultSortModel={sort}
                            onRowClick={({ row }) => {
                                onChange(row ? { ...row, supplierName: row.supplier.name } : null);
                                onClose();
                            }}
                            autoHeight={false}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
