import {
    formatDateTimeString,
    IParams,
    IProduct,
    mapDateStringToApiDate,
    MeasurementUnit,
    PermissionKeys,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParams,
    Widget,
} from '../../../shared';
import React, { FC, useState } from 'react';
import { EditOutlined, ListAltTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { IOperator, IStockLocation } from '../../../admin/models';
import { useNavigate, useParams } from 'react-router-dom';
import { StockMovementsFilter } from '../stock-movements-filter/stock-movements-filter.component';
import { StockMovementType } from '../../enums';
import { Box, IconButton, Stack } from '@mui/material';
import { useStockMovementsList } from '../../hooks';

export const BatchStockMovements: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();

    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.BATCHES_WRITE);
    const [filter, setFilter] = useState<Record<string, any>>({});

    const { page, pageSize, sort, setPage, setPageSize, setSort } = useQueryParams({
        defaultSort: [{ field: 'createdDate', sort: 'desc' }],
    });

    const { data: stockMovements, isPending } = useStockMovementsList(
        {
            page: page,
            pageSize: pageSize,
            sortBy: sort[0].field,
            sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
            batch: id,
            date: mapDateStringToApiDate(filter.date) || undefined,
            type: (filter.type as StockMovementType) || undefined,
            product: filter.product || undefined,
            operator: filter.operator || undefined,
            stockLocation: filter.stockLocation || undefined,
            organisation: filter.organisation || undefined,
            productCode: filter.productCode || undefined,
        },
        { refetchInterval: 5000 },
    );

    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: t('createdDate'),
            valueFormatter: ({ value }) => formatDateTimeString(value as string),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'type',
            headerName: t('type'),
            valueFormatter: ({ value }) => t(value as string),
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'operator',
            headerName: t('operator'),
            valueFormatter: ({ value }) => (value as IOperator)?.name,
            flex: 1,
            minWidth: 150,
            sortable: false,
        },
        {
            field: 'stockLocation',
            headerName: t('stockLocation'),
            valueFormatter: ({ value }) => (value as IStockLocation)?.name,
            flex: 1,
            minWidth: 150,
            sortable: false,
        },
        {
            field: 'organisation',
            headerName: t('organisation'),
            valueFormatter: ({ value }) => value?.name,
            flex: 1,
            minWidth: 150,
            sortable: false,
        },
        {
            field: 'product',
            headerName: t('product'),
            valueFormatter: ({ value }) => (value as IProduct)?.name,
            flex: 2,
            minWidth: 200,
            sortable: false,
        },
        {
            field: 'amount',
            headerName: t('amount'),
            minWidth: 150,
            flex: 1,
            renderCell: ({ row }: GridCellParams) => (
                <>
                    {row.amount}{' '}
                    {!row.product ? '' : row.product?.measurementUnit === MeasurementUnit.GRAM ? t('gram') : t('PIECE')}
                </>
            ),
            sortable: false,
        },
        {
            field: 'totalWeight',
            headerName: t('totalWeight'),
            minWidth: 150,
            flex: 1,
            renderCell: ({ row }: GridCellParams) => <>{row.product && row.amount * (row.product.weight || 1)}</>,
            sortable: false,
        },
        {
            field: ' ',
            headerName: ' ',
            width: 100,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        hasWritePermission &&
                            navigate(`/stock/movements/${row.id}/edit`, { state: `/stock/batches/${id}/detail` });
                    }}
                >
                    <EditOutlined />
                </IconButton>
            ),
        },
    ];

    return (
        <Widget
            title={
                <Stack direction="row" spacing={2} alignItems="center">
                    <Box sx={{ minWidth: 'fit-content' }}>{t('stockMovements')}</Box>
                    <StockMovementsFilter filter={filter} onChange={setFilter} showSupplier={false} />
                </Stack>
            }
            icon={<ListAltTwoTone color="primary" />}
            hasTable
        >
            <Stack spacing={2}>
                <TableComponent
                    rows={stockMovements?.data || []}
                    columns={columns}
                    rowCount={stockMovements?.pagination.size || 0}
                    paginationModel={{ page: page ? page - 1 : 0, pageSize: pageSize || 10 }}
                    onPaginationModelChange={(value) => {
                        setPage(value?.page + 1);
                        setPageSize(value?.pageSize || 5);
                    }}
                    loading={isPending}
                    sortModel={sort}
                    onSortModelChange={setSort}
                    defaultSortModel={[{ field: 'createdDate', sort: 'desc' }]}
                    onRowClick={(row) =>
                        hasWritePermission &&
                        navigate(`/stock/movements/${row.id}/edit`, { state: `/stock/batches/${id}/detail` })
                    }
                />
            </Stack>
        </Widget>
    );
};
