import { IRouteConfig, IRouteConfigWithComponent } from '../shared';
import { ReportFoodDistributionSupplied, ReportFoodDistributionRedistributed, ReportPage } from './pages';

export const reportsRoutes: IRouteConfig[] = [
    { path: '/reports', redirect: '/reports/food-distribution-redistributed' },
    { path: '/reports/*', component: ReportPage },
];

export const reportSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/food-distribution-redistributed', component: ReportFoodDistributionRedistributed },
    { path: '/food-distribution-supplied', component: ReportFoodDistributionSupplied },
];
