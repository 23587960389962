import { ListAltTwoTone } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Definition, Widget } from '../../../shared';
import { IStockProduct } from '../../models';

interface Props {
    stockProduct: IStockProduct;
}

export const StockProductDetail: FC<Props> = ({ stockProduct }) => {
    const { t } = useTranslation();
    return (
        <Widget title={t('product')} icon={<ListAltTwoTone color="primary" />}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Definition label={t('name')}>{stockProduct.name}</Definition>
                </Grid>
                <Grid item xs={6}>
                    <Definition label={t('code')}>{stockProduct.code}</Definition>
                </Grid>
                <Grid item xs={6}>
                    <Definition label={t('measurementUnit')}>{t(stockProduct.measurementUnit)}</Definition>
                </Grid>
                <Grid item xs={6}>
                    <Definition label={t('weight')}>{stockProduct.weight}</Definition>
                </Grid>
                <Grid item xs={6}>
                    <Definition label={t('productCategory')}>{stockProduct.category?.id}</Definition>
                </Grid>
            </Grid>
        </Widget>
    );
};
