import { QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { operatorsClient } from '../clients';
import { IOperator } from '../models';

const client = operatorsClient;
const listKey = QueryKeys.Operators;
const itemKey = QueryKeys.Operator;

export function useOperatorsList(...args: UseListParams<IOperator>) {
    return useList(client, listKey, ...args);
}

export function useOperator(...args: UseItemParams<IOperator>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveOperator() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteOperator() {
    return useDelete(client, listKey);
}
