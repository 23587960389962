import { IFeadDelivery, IFeadDeliveryProduct, IFeadDeliveryProductForOrganisation, ISaveFeadDelivery } from '../models';
import { BaseClient } from './base.client';
import { logisticsApi } from './logistics-api.client';

class FeadDeliveriesClient extends BaseClient<IFeadDelivery, ISaveFeadDelivery> {
    constructor() {
        super(logisticsApi, '/fead-deliveries');
    }

    public async getFeadProduct(productId?: string) {
        const { data } = await this.api.get<IFeadDeliveryProduct[]>(`${this.basePath}/products/${productId}`);
        return data;
    }

    public async getFeadProductsForOrganisation(organisation?: string) {
        const { data } = await this.api.get<IFeadDeliveryProductForOrganisation[]>(`${this.basePath}/products`, {
            params: { organisation },
        });
        return data;
    }

    public async updateFeadDelivery(
        id: string,
        item: ISaveFeadDelivery,
        ignoreInUseWarning: boolean,
    ): Promise<IFeadDelivery> {
        const { data } = await this.api.put<IFeadDelivery>(
            `${this.basePath}/${id}?ignoreInUseWarning=${ignoreInUseWarning}`,
            item,
        );
        return data;
    }
}

export const feadDeliveriesClient = new FeadDeliveriesClient();
