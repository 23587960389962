import { QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { productCategoriesClient } from '../clients';
import { IProductCategory } from '../models';

const client = productCategoriesClient;
const listKey = QueryKeys.ProductCategories;
const itemKey = QueryKeys.ProductCategory;

export function useProductCategoriesList(...args: UseListParams<IProductCategory>) {
    return useList(client, listKey, ...args);
}

export function useProductCategory(...args: UseItemParams<IProductCategory>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveProductCategory() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteProductCategory() {
    return useDelete(client, listKey);
}
