import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, mixed, number, object, string } from 'yup';
import { StockMovementType } from '../enums';

export function useStockMovementSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                type: mixed<StockMovementType>().oneOf(Object.values(StockMovementType), t('required')).required(),
                productCode: string().required(),
                amount: number().typeError(t('invalidNumber')).required().min(0, t('amountGreaterThanOrEqualToZero')),
                supplierId: string()
                    .nullable()
                    .when('type', {
                        is: (value: StockMovementType) =>
                            value === StockMovementType.IN_STOCK || value === StockMovementType.DISTRIBUTION,
                        then: (schema) => schema.required(),
                    }),
                stockLocationId: string()
                    .nullable()
                    .when('type', {
                        is: (value: StockMovementType) =>
                            value === StockMovementType.IN_STOCK || value === StockMovementType.OUT_STOCK,
                        then: (schema) => schema.required(),
                    }),
                organisationId: string()
                    .nullable()
                    .when('type', {
                        is: (value: StockMovementType) =>
                            value === StockMovementType.OUT_STOCK || value === StockMovementType.DISTRIBUTION,
                        then: (schema) => schema.required(),
                    }),
                productName: string().optional(),
                useBatch: boolean().optional(),
                batch: object()
                    .shape({
                        id: string().required(),
                        supplierName: string().required(),
                        date: string().required(),
                        referenceNumber: string(),
                    })
                    .optional()
                    .nullable()
                    .when('useBatch', {
                        is: true,
                        then: (schema) => schema.required(),
                    }),
            }),
        [t],
    );
}
