import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { allergenTypesClient } from '../clients';
import { IAllergenType, QueryKeys } from '../../shared';
import { AxiosError } from 'axios';

export function useAllergenTypes(): UseQueryResult<IAllergenType[]> {
    return useQuery<IAllergenType[], AxiosError>({
        queryKey: [QueryKeys.AllergenTypes],
        queryFn: () => allergenTypesClient.getAllergenTypes(),
    });
}
