import { useTranslation } from 'react-i18next';
import { array, boolean, date, mixed, number, object, string } from 'yup';
import { IProduct } from '../../shared';

export function usePlanningEntrySchema() {
    const { t } = useTranslation();
    return object().shape({
        id: string().optional(),
        date: date().typeError(t('dateInvalid')).required(),
        requirements: array()
            .of(
                object().shape({
                    categoryId: string()
                        .nullable()
                        .when('isCategory', { is: true, then: (schema) => schema.required() }),
                    product: mixed<IProduct>()
                        .nullable()
                        .when('isCategory', { is: false, then: (schema) => schema.required() }),
                    amount: number().typeError(t('invalidNumber')).min(0).required(),
                    isCategory: boolean().required(),
                }),
            )
            .required(),
    });
}
