import { QueryKeys } from '../../shared';
import { shipmentsClient } from '../clients';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export function useUploadShipmentAsset(): UseMutationResult<void, AxiosError, { id: string; file: File }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id, file }) => shipmentsClient.uploadAsset(id, file),
        onSuccess: async (r, { id }) => await queryClient.invalidateQueries({ queryKey: [QueryKeys.Shipment, id] }),
    });
}

export function useDeleteShipmentAsset(): UseMutationResult<void, AxiosError, { id: string; assetId: string }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id, assetId }) => shipmentsClient.deleteAsset(id, assetId),
        onSuccess: async (r, { id }) => await queryClient.invalidateQueries({ queryKey: [QueryKeys.Shipment, id] }),
    });
}
