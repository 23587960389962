import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MenuItem } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledInput,
    ControlledSelect,
    FormCard,
    IOrganisation,
    Page,
    PermissionKeys,
    Section,
    useHasPermission,
    useOrganisationsList,
} from '../../../shared';
import { useSettings, useUpdateSettings } from '../../hooks';
import { ISaveSettings } from '../../models';
import { useSettingSchema } from '../../validators';

export const SettingsEditPage: FC = () => {
    const { t } = useTranslation();
    const { data: settings } = useSettings();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SETTINGS_WRITE);

    const form = useForm<ISaveSettings>({
        resolver: yupResolver(useSettingSchema()),
        mode: 'all',
    });
    const { mutateAsync: updateSettings, isPending: isPendingSave } = useUpdateSettings();
    const { data: organisations, isPending: isOrganisationsLoading } = useOrganisationsList({ pageSize: 100 });

    useEffect(() => {
        if (settings) {
            form.reset(settings);
        }
    }, [settings, form]);

    const onSubmit = useCallback(
        async (settings: ISaveSettings) => {
            await updateSettings(settings);
        },
        [updateSettings],
    );

    const actions = useMemo(
        () => [
            hasWritePermission && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={form.handleSubmit(onSubmit)}
                    disabled={isPendingSave}
                >
                    {t('save')}
                </Button>
            ),
        ],
        [hasWritePermission, form, onSubmit, isPendingSave, t],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page title={t('settings')} actions={reversedActions} loading={isOrganisationsLoading || isPendingSave}>
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Section title={t('data')}>
                        <ControlledSelect
                            name="organisationId"
                            label={t('organisation')}
                            disabled={!hasWritePermission}
                            required
                        >
                            {(organisations?.data || []).map((organisation: IOrganisation) => (
                                <MenuItem value={organisation.id} key={organisation.id}>
                                    {organisation.name}
                                </MenuItem>
                            ))}
                        </ControlledSelect>

                        <ControlledInput
                            name="shipmentMailSubject"
                            label={t('shipmentMailSubject')}
                            disabled={!hasWritePermission}
                            required
                        />

                        <ControlledInput
                            name="shipmentMailBody"
                            label={t('shipmentMailBody')}
                            disabled={!hasWritePermission}
                            required
                            rows={10}
                            multiline
                        />
                    </Section>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
