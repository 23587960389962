import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ControlledDatePicker, IParams } from '../../../shared';
import { useSavePlanningPattern } from '../../hooks/planning-pattterns.hook';
import { mapPatternFormToDto } from '../../mappers';
import { IPlanningPatternForm } from '../../models';
import { usePlanningPatternEntrySchema } from '../../validators';
import { emptyWeek, PatternEntryInputs } from '../pattern-entry-inputs/pattern-entry-inputs.component';

interface Props {
    open: boolean;
    handleClose: () => void;
}

export const PatternDialog: FC<Props> = ({ open, handleClose }) => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;

    const { mutateAsync: createPattern } = useSavePlanningPattern();
    const form = useForm<IPlanningPatternForm>({
        resolver: yupResolver(usePlanningPatternEntrySchema()),
        mode: 'all',
    });

    const watchFeadProductsPattern = form.watch('feadProductsPattern');

    useEffect(() => {
        form.reset({ weeks: [emptyWeek] });
    }, [open, form]);

    useEffect(() => {
        form.setValue('weeks', [emptyWeek]);
    }, [form, watchFeadProductsPattern]);

    const onSave = async (item: IPlanningPatternForm) => {
        await createPattern({
            planning: id,
            pattern: {
                ...mapPatternFormToDto(item),
            },
        });
        handleClose();
    };
    return (
        <FormProvider {...form}>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth={watchFeadProductsPattern ? 'md' : 'xl'}>
                <DialogTitle>{t('newPattern')}</DialogTitle>
                <DialogContent>
                    <Stack direction="row" spacing={2} sx={{ pt: 2, mb: 2 }}>
                        <ControlledDatePicker name="startDate" label={t('startDate')} required />
                        <ControlledDatePicker name="endDate" label={t('endDate')} required />
                    </Stack>
                    <PatternEntryInputs />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={form.handleSubmit(onSave)}>
                        {t('save')}
                    </Button>
                    <Button onClick={handleClose} color="secondary">
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
};
