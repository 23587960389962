export const compareProductCategory = (
    a: { priority?: number | null; name?: string },
    b: { priority?: number | null; name?: string },
): number => {
    if (a.priority && b.priority) {
        if (a.priority === b.priority) {
            return (a.name || '').localeCompare(b.name || '');
        }
        return a.priority - b.priority;
    } else if (a.priority || b.priority) {
        return a.priority ? -1 : 1;
    }
    return (a.name || '')?.localeCompare(b.name || '');
};
