import { MenuItem } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledSelect, FormGrid, Section } from '../../../shared';
import { NutriScore } from '../../enums';

export const ProductContent: FC = () => {
    const { t } = useTranslation();

    return (
        <Section title={t('content')}>
            <FormGrid containerProps={{ direction: 'column' }}>
                <ControlledInput name="ingredients" label={t('ingredients')} multiline rows={3} />

                <ControlledInput name="nutritionalInformation" label={t('nutritionalInformation')} multiline rows={3} />

                <ControlledSelect name="nutriScore" label={t('nutriScore')} minWidth={406}>
                    <MenuItem value={''} />
                    {Object.values(NutriScore).map((score) => (
                        <MenuItem value={score} key={score}>
                            {t(score)}
                        </MenuItem>
                    ))}
                </ControlledSelect>
            </FormGrid>
        </Section>
    );
};
