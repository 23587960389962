export const stockTranslationsNL = {
    stock: 'Stock',
    stockProducts: 'Huidige stock',
    stockCounts: 'Totaal in stock',

    IN_STOCK: 'In stock',
    OUT_STOCK: 'Uit stock',
    DISTRIBUTION: 'Distributie',

    stockMovements: 'Stock bewegingen',
    newStockMovement: 'Nieuwe stock beweging',
    stockMovementDeleteWarningTitle: 'Stock beweging verwijderen?',
    stockMovementDeleteWarningText: 'Ben je zeker dat je deze stock beweging wilt verwijderen?',
    editStockMovement: 'Wijzig stock beweging',
    amount: 'Aantal',
    productCode: 'Product barcode',
    expirationDate: 'Te gebruiken tot',
    createdDate: 'Datum',
    stockMovementInUseTitle: 'Stock beweging in gebruik',
    stockMovementInUseDescription: 'Dit product is al uitgescant sinds je deze stockbeweging hebt aangemaakt.',
    editStockMovementInUseConfirmQuestion: 'Ben je zeker dat je deze stockbeweging wilt aanpassen?',
    feadProduct: 'Fead product',
    feadProductNotAllowed: 'Fead producten kan je enkel in stock nemen via het fead luik.',
    feadProductNotInStock:
        'Dit fead product is niet voldoende in stock, ga naar het fead luik om een fead product in stock te nemen.',

    totalWeight: 'Totaalgewicht (g)',
    gram: 'g',

    blackListedProductWarningTitle: 'Waarschuwing',
    blackListedProductWarningTextPart1: 'De organisatie wenst dit product momenteel niet te ontvangen:',
    blackListedProductWarningTextPart2: 'Wens je toch de stockbeweging aan te maken?',
    timePeriod: 'Tijdsperiode',

    batches: 'Batches',
    batch: 'Batch',
    newBatch: 'Nieuwe batch',
    editBatch: 'Batch bewerken',
    referenceNumber: 'Referentienummer',
    supplierName: 'Leverancier',
    qrCode: 'Qr-code',
    batchDeleteWarningTitle: 'Verwijder batch',
    batchDeleteWarningText: 'Bent u zeker dat u deze batch wilt verwijderen?',
    batchWarningTitle: 'QR code is al in gebruik',
    batchWarningText:
        'De Qr code is al gekoppeld aan een batch. Wil je de Qr code ontkoppelen van de oude batch en koppelen aan de nieuw batch?',
    useBatch: 'Gebruik batch',
    pickBatch: 'Kies een batch',
    selectBatch: 'Selecteer een batch',
    alterBatch: 'Wijzig batch',
    detailBatch: '{{supplier}} - {{date}} - {{reference}}',

    actions: 'Acties',
    columnMenuHideColumn: 'Verberg kolom',
    columnMenuManageColumns: 'Beheer kolommen',
    columnMenuSortAsc: 'Sorteer oplopend',
    columnMenuSortDesc: 'Sorteer aflopend',
    columnsPanelTextFieldLabel: 'Zoek kolom op naam',
    columnsPanelShowAllButton: 'Toon alle',
    columnsPanelHideAllButton: 'Verberg alle',
};
