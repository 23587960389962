import { BaseClient, logisticsApi } from '../../shared';
import { IBlacklistedProduct, ISaveBlacklistedProduct } from '../models';

class BlacklistedProductsClient extends BaseClient<IBlacklistedProduct, ISaveBlacklistedProduct> {
    constructor() {
        super(logisticsApi, '/blacklisted-products');
    }
}

export const blacklistedProductsClient = new BlacklistedProductsClient();
