import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStockLocationsList, useSuppliersList } from '../../../admin/hooks';
import { IBlacklistedProduct, IStockLocation } from '../../../admin/models';
import {
    ControlledCheckbox,
    ControlledDatePicker,
    ControlledInput,
    ControlledSelect,
    Definition,
    formatDateString,
    FormCard,
    FormGrid,
    IOrganisation,
    IParams,
    isBadRequest,
    ISupplier,
    Page,
    Section,
    useOrganisationsList,
    WarningDialog,
} from '../../../shared';
import { BatchPicker, BlacklistedProductWarning } from '../../components';
import { StockMovementType } from '../../enums';
import { useSaveStockMovement, useStockMovement } from '../../hooks';
import { stockMovementToFormMapper } from '../../mappers';
import { IStockMovementForm } from '../../models';
import { useStockMovementSchema } from '../../validators';

export const StockMovementEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state: redirectToBatchDetail } = useLocation();
    const { id } = useParams<keyof IParams>() as IParams;

    const { data: stockMovement, isFetching } = useStockMovement(id);
    const redirect = new URLSearchParams(location.search).get('redirect');

    const form = useForm<IStockMovementForm>({
        resolver: yupResolver(useStockMovementSchema()),
        mode: 'all',
    });
    const watchBatch = form.watch('batch');
    const watchUseBatch = form.watch('useBatch');

    useEffect(() => {
        if (!watchUseBatch) {
            form.setValue('batch', null);
        }
    }, [form, watchUseBatch]);

    const { mutateAsync: saveStockMovement, isPending: isSaveLoading } = useSaveStockMovement();
    const [showStockMovementInUse, setShowStockMovementInUse] = useState(false);
    const [warning, setWarning] = useState<{ title: string; text: string } | undefined>();
    const [blacklistedProductWarning, setBlacklistedProductWarning] = useState<{
        show: boolean;
        blacklistedProducts?: IBlacklistedProduct[];
    }>({ show: false });

    const { data: suppliers, isPending: isSuppliersLoading } = useSuppliersList({ pageSize: 100 });
    const { data: stockLocations, isPending: isStockLocationsLoading } = useStockLocationsList({ pageSize: 100 });
    const { data: organisations, isPending: isOrganisationsLoading } = useOrganisationsList({ pageSize: 100 });

    const selectedType = form.watch('type');

    useEffect(() => {
        if (stockMovement) {
            form.reset(stockMovementToFormMapper(stockMovement));
        }
    }, [stockMovement, form]);

    const onSubmit = useCallback(
        async (item: IStockMovementForm, ignoreBlacklistedWarning = false, ignoreInUseWarning = false) => {
            setShowStockMovementInUse(false);
            setBlacklistedProductWarning({ show: false });
            try {
                await saveStockMovement({
                    id,
                    item: {
                        ...item,
                        batchId: item.batch?.id,
                        expirationDate: item.expirationDate && format(item.expirationDate, 'yyyy-MM-dd'),
                    },
                    ignoreBlacklistedWarning,
                    ignoreInUseWarning,
                });

                if (redirect) {
                    navigate(redirect);
                } else {
                    navigate(redirectToBatchDetail ? redirectToBatchDetail : '/stock/movements');
                }
            } catch (err) {
                if (isBadRequest(err)) {
                    const type = (err as AxiosError<any>)?.response?.data?.type;
                    const blacklistedProducts = (err as AxiosError<any>)?.response?.data?.blacklistedProducts;
                    if (blacklistedProducts) {
                        return setBlacklistedProductWarning({ show: true, blacklistedProducts });
                    } else if (type === 'inUse') {
                        return setShowStockMovementInUse(true);
                    } else if (type === 'fead') {
                        return setWarning({
                            title: t('feadProduct'),
                            text: t('feadProductNotAllowed'),
                        });
                    } else if (type === 'fead_amount') {
                        return setWarning({
                            title: t('feadProduct'),
                            text: t('feadProductNotInStock'),
                        });
                    } else {
                        return setWarning({ title: '', text: t('tryAgainLater') });
                    }
                }
                throw err;
            }
        },
        [id, navigate, redirect, redirectToBatchDetail, saveStockMovement, t],
    );

    const actions = useMemo(
        () => [
            <Button
                variant="contained"
                color="primary"
                onClick={form.handleSubmit((data) => onSubmit(data))}
                disabled={isSaveLoading}
            >
                {t('save')}
            </Button>,
            <Button onClick={() => navigate('/stock/movements')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, isSaveLoading, t, onSubmit, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={stockMovement?.id ? t('editStockMovement') : t('newStockMovement')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={
                isFetching || isOrganisationsLoading || isSuppliersLoading || isStockLocationsLoading || isSaveLoading
            }
        >
            <WarningDialog
                open={!!warning}
                onClose={() => setWarning(undefined)}
                title={warning?.title}
                text={warning?.text}
            />
            <WarningDialog
                open={showStockMovementInUse}
                onConfirm={form.handleSubmit((data) => onSubmit(data, false, true))}
                onClose={() => setShowStockMovementInUse(false)}
                title={t('stockMovementInUseTitle')}
                text={
                    <>
                        <Typography>{t('stockMovementInUseDescription')}</Typography>
                        <b>{t('editStockMovementInUseConfirmQuestion')}</b>
                    </>
                }
            />
            <BlacklistedProductWarning
                show={blacklistedProductWarning.show}
                onCancel={() => setBlacklistedProductWarning({ show: false, blacklistedProducts: undefined })}
                onConfirm={form.handleSubmit((data) => onSubmit(data, true))}
                blacklistProducts={blacklistedProductWarning.blacklistedProducts || []}
            />

            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit((data) => onSubmit(data))} actions={actions}>
                    <Section title={t('parties')}>
                        <FormGrid>
                            <ControlledSelect
                                name="type"
                                label={t('type')}
                                required
                                disabled={Boolean(stockMovement?.id)}
                            >
                                {Object.values(StockMovementType).map((type) => (
                                    <MenuItem value={type} key={type}>
                                        {t(type)}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                            {!watchUseBatch &&
                                [StockMovementType.IN_STOCK, StockMovementType.DISTRIBUTION].includes(selectedType) && (
                                    <ControlledSelect name="supplierId" label={t('supplier')} required>
                                        {(suppliers?.data || []).map((supplier: ISupplier) => (
                                            <MenuItem value={supplier.id} key={supplier.id}>
                                                {supplier.name}
                                            </MenuItem>
                                        ))}
                                    </ControlledSelect>
                                )}

                            {[StockMovementType.IN_STOCK, StockMovementType.OUT_STOCK].includes(selectedType) && (
                                <ControlledSelect name="stockLocationId" label={t('stockLocation')} required>
                                    {(stockLocations?.data || []).map((stockLocation: IStockLocation) => (
                                        <MenuItem value={stockLocation.id} key={stockLocation.id}>
                                            {stockLocation.name}
                                        </MenuItem>
                                    ))}
                                </ControlledSelect>
                            )}

                            {[StockMovementType.OUT_STOCK, StockMovementType.DISTRIBUTION].includes(selectedType) && (
                                <ControlledSelect name="organisationId" label={t('organisation')} required>
                                    {(organisations?.data || []).map((organisation: IOrganisation) => (
                                        <MenuItem value={organisation.id} key={organisation.id}>
                                            {organisation.name}
                                        </MenuItem>
                                    ))}
                                </ControlledSelect>
                            )}
                        </FormGrid>

                        <Box sx={{ pl: 2 }}>
                            {[StockMovementType.IN_STOCK, StockMovementType.OUT_STOCK].includes(selectedType) && (
                                <ControlledCheckbox name="useBatch" label={t('useBatch')} />
                            )}

                            {watchUseBatch && (
                                <Controller
                                    name="batch"
                                    control={form.control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                        <BatchPicker value={value} onChange={onChange} error={error} />
                                    )}
                                />
                            )}

                            {watchBatch && (
                                <Stack direction="row" spacing={10} sx={{ py: 1, px: 3, mt: 2, border: 1 }}>
                                    <Definition label={t('date')}>{formatDateString(watchBatch.date)}</Definition>
                                    <Definition label={t('supplierName')}>{watchBatch.supplierName}</Definition>
                                    <Definition label={t('referenceNumber')}>{watchBatch.referenceNumber}</Definition>
                                </Stack>
                            )}
                        </Box>
                    </Section>

                    <Section title={t('data')}>
                        <FormGrid>
                            <ControlledInput
                                name="productCode"
                                label={t('productCode')}
                                required
                                disabled={Boolean(stockMovement?.id)}
                            />

                            {id && <ControlledInput name="productName" label={t('name')} disabled />}

                            <ControlledInput name="amount" label={t('amount')} required />
                            <ControlledDatePicker name="expirationDate" label={t('expirationDate')} />
                        </FormGrid>
                    </Section>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
