import React, { FC, Fragment } from 'react';
import { Box, Grid } from '@mui/material';
import { ListModel, useEntityMap } from '../../../shared';
import { IPlanningEntryForm } from '../../models';
import { useTranslation } from 'react-i18next';
import { IProductCategory } from '../../../admin/models';
import { UseFieldArrayReturn } from 'react-hook-form';
import { EntryAmount } from '../entry-amount/entry-amount.component';

interface Props {
    requirements: UseFieldArrayReturn<IPlanningEntryForm, 'requirements', 'id'>;
    categories: ListModel<IProductCategory> | undefined;
}

export const CategoryRequirements: FC<Props> = ({ requirements, categories }) => {
    const { t } = useTranslation();
    const categoriesMap = useEntityMap(categories);

    return (
        <Box sx={{ pt: 1 }}>
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                {requirements.fields.map((requirement, index) => (
                    <Fragment key={requirement.id}>
                        {requirement.isCategory && (
                            <>
                                <Grid item xs={3.5} sx={{ textAlign: 'right' }}>
                                    {categoriesMap[requirement.categoryId || '']}
                                </Grid>
                                <Grid item xs={2.5}>
                                    <EntryAmount path={`requirements.${index}`} label={t('amount')} required />
                                </Grid>
                            </>
                        )}
                    </Fragment>
                ))}
            </Grid>
        </Box>
    );
};
