import { Box } from '@mui/material';
import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { ErrorFallback, ProtectedRoute, SideBar, SideNav } from '../../../shared';
import { reportSubRoutes } from '../../reports.routes';

export const ReportPage: FC = () => {
    const { t } = useTranslation();
    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav
                    items={[
                        { text: t('foodDistribution'), key: 'foodDistribution' },
                        {
                            path: '/reports/food-distribution-redistributed',
                            text: t('foodDistributionRedistributed'),
                        },
                        { path: '/reports/food-distribution-supplied', text: t('foodDistributionSupplied') },
                    ]}
                />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {reportSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ProtectedRoute requiredPermission={subRoute.requiredPermissions}>
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        {<subRoute.component />}
                                    </ErrorBoundary>
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};
