import { format } from 'date-fns';

export function formatDateString(date: string | undefined | null): string {
    return date ? format(new Date(date), 'dd/MM/yyyy') : '';
}

export function formatDateTimeString(date: string | undefined | null): string {
    return date ? format(new Date(date), 'dd/MM/yyyy - HH:mm') : '';
}

export function formatDateStringToMonth(date: string | undefined): string {
    return date ? format(new Date(date), 'MM/yyyy') : '';
}

export function formatDateStringToQuarter(date: string | undefined): string {
    return date ? format(new Date(date), 'QQQ, yyyy') : '';
}

export function formatDateStringToYear(date: string | undefined): string {
    return date ? format(new Date(date), 'yyyy') : '';
}
