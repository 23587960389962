import { Grid } from '@mui/material';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IParams, Loading, Page } from '../../../shared';
import { StockProductDetail, StockProductLocations } from '../../components';
import { useStockProduct } from '../../hooks';

export const StockProductPage: FC = () => {
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;
    const { data: stockProduct } = useStockProduct(id);

    return stockProduct ? (
        <Page title={stockProduct?.name} onBack={() => navigate(-1)}>
            <Grid container spacing={3} alignItems="flex-start">
                <Grid item xs={6}>
                    <StockProductDetail stockProduct={stockProduct} />
                </Grid>
                <Grid item xs={6}>
                    <StockProductLocations stockProduct={stockProduct} />
                </Grid>
            </Grid>
        </Page>
    ) : (
        <Loading />
    );
};
