import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { GridCellParams, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IOperator, IStockLocation } from '../../../admin/models';
import {
    formatDateTimeString,
    IParams,
    IProduct,
    Loading,
    SortOrder,
    TableComponent,
    useQueryParams,
    Widget,
} from '../../../shared';
import { useSaveShipment, useShipment } from '../../hooks';
import { useStockMovementsList } from '../../../stock/hooks';

export const ShipmentContentPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();
    const { sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'createdDate', sort: 'desc' }],
    });
    const [selectedStockMovements, setSelectedStockMovements] = useState<string[]>([]);

    const { mutateAsync: saveShipment, isPending: isPendingSave } = useSaveShipment();
    const { data: shipment, isPending: shipmentLoading } = useShipment(id);
    const { data: stockMovements, isPending: stockMovementsLoading } = useStockMovementsList(
        {
            page: 1,
            pageSize: 10000,
            sortBy: sort[0].field,
            sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
            organisation: shipment?.receiver.id,
            shipment: shipment?.id,
            readyForShipment: true,
        },
        { enabled: Boolean(shipment?.receiver) },
    );

    const onNext = async () => {
        if (shipment) {
            await saveShipment({
                id,
                item: {
                    ...shipment,
                    receiverId: shipment.receiver.id,
                    stockMovements: selectedStockMovements,
                },
            });
            navigate(`/planning/shipments/${shipment.id}/attachments`);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: t('createdDate'),
            valueFormatter: ({ value }) => formatDateTimeString(value as string),
            flex: 1,
        },
        {
            field: 'type',
            headerName: t('type'),
            valueFormatter: ({ value }) => t(value as string),
            flex: 1,
        },
        {
            field: 'operator',
            headerName: t('operator'),
            valueFormatter: ({ value }) => (value as IOperator)?.name,
            flex: 1,
        },
        {
            field: 'supplier',
            headerName: t('supplier'),
            flex: 1,
            renderCell: ({ row }: GridCellParams) => (row.batch ? row.batch.supplier?.name : row.supplier?.name),
        },
        {
            field: 'batch',
            headerName: t('batch'),
            valueFormatter: ({ value }) => value?.referenceNumber,
            flex: 1,
            minWidth: 150,
            sortable: false,
        },
        {
            field: 'stockLocation',
            headerName: t('stockLocation'),
            valueFormatter: ({ value }) => (value as IStockLocation)?.name,
            flex: 1,
        },
        {
            field: 'product',
            headerName: t('product'),
            valueFormatter: ({ value }) => (value as IProduct)?.name,
            flex: 1,
        },
        { field: 'amount', headerName: t('amount'), minWidth: 150, flex: 1 },
    ];

    useEffect(() => {
        if (shipment) {
            setSelectedStockMovements(shipment.stockMovements.map(({ id }) => id));
        }
    }, [shipment]);

    const onSelectionChange = (ids: GridRowSelectionModel) => {
        setSelectedStockMovements(ids as string[]);
    };

    return (
        <Widget
            hasTable
            footer={
                <Stack direction="row" justifyContent="space-between">
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(`/planning/shipments/${id}`)}>
                        {t('previous')}
                    </Button>
                    <Button
                        endIcon={<ChevronRight />}
                        variant="contained"
                        color="primary"
                        onClick={onNext}
                        disabled={isPendingSave}
                    >
                        {t('next')}
                    </Button>
                </Stack>
            }
        >
            {stockMovementsLoading || shipmentLoading ? (
                <Loading />
            ) : (
                <>
                    {stockMovements?.data && (
                        <TableComponent
                            checkboxSelection
                            rowSelectionModel={selectedStockMovements}
                            onRowSelectionModelChange={onSelectionChange}
                            rows={stockMovements.data || []}
                            columns={columns}
                            onSortModelChange={setSort}
                            defaultSortModel={sort}
                            sortModel={sort}
                            mode="client"
                            pageSizeOptions={[100]}
                            hideFooter={stockMovements.data.length <= 100}
                        />
                    )}
                </>
            )}
        </Widget>
    );
};
