import { BaseClient, logisticsApi } from '../../shared';
import { ISaveStockMovement, IStockMovement, IStockMovementQuery } from '../models';

class StockMovementsClient extends BaseClient<IStockMovement, ISaveStockMovement, IStockMovementQuery> {
    constructor() {
        super(logisticsApi, '/stock-movements');
    }

    public async deallocateFromShipment(id: string): Promise<void> {
        await this.api.put<void>(`${this.basePath}/${id}/deallocate-shipment`);
    }

    public async updateStockMovement(
        id: string,
        item: ISaveStockMovement,
        ignoreInUseWarning: boolean,
    ): Promise<IStockMovement> {
        const { data } = await this.api.put<IStockMovement>(
            `${this.basePath}/${id}?ignoreInUseWarning=${ignoreInUseWarning}`,
            item,
        );
        return data;
    }

    public async createStockMovement(
        item: ISaveStockMovement,
        ignoreBlacklistedWarning: boolean,
    ): Promise<IStockMovement> {
        const { data } = await this.api.post<IStockMovement>(
            `${this.basePath}?ignoreWarnings=${ignoreBlacklistedWarning}`,
            item,
        );
        return data;
    }
}

export const stockMovementsClient = new StockMovementsClient();
