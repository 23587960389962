import { Checkbox, ListItemText, MenuItem } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSuppliersList } from '../../../admin/hooks';
import { ControlledSelect } from '../../../shared';
import { Report } from '../../enums/report.enum';
import { IFilter } from '../../models';

export const ReportFilterSuppliersSelect: FC<{
    report: Report;
    form: UseFormReturn<IFilter>;
    filter: Partial<IFilter>;
    onChange: (filter: IFilter) => void;
}> = ({ filter, report, form, onChange }) => {
    const { t } = useTranslation();

    const { data: suppliers } = useSuppliersList({ pageSize: 200 });

    useEffect(() => {
        if (suppliers) {
            const supplierValues =
                report === Report.SUPPLIED
                    ? suppliers?.data?.map(({ id }) => id)
                    : [...suppliers?.data?.map(({ id }) => id), ''];
            form.setValue('suppliers', supplierValues);
            onChange({ ...filter, suppliers: supplierValues });
        }
    }, [suppliers, form, report]); // eslint-disable-line react-hooks/exhaustive-deps

    const supplierItems = useMemo(() => {
        if (report !== Report.SUPPLIED) {
            return suppliers?.data ? [...suppliers?.data?.map(({ id }) => id), ''] : [''];
        }
        return suppliers?.data?.map(({ id }) => id) || [];
    }, [suppliers, report]);

    return (
        <ControlledSelect
            name="suppliers"
            label={t('supplier')}
            multiple
            renderValue={(selected) =>
                (selected as string[])?.length ==
                (suppliers?.pagination.size || 0) + (report === Report.SUPPLIED ? 0 : 1)
                    ? t('all')
                    : (selected as string[])
                          .map((value) => suppliers?.data?.find((item) => item.id == value))
                          .map((item) => (item?.name ? item.name : t('unknown')))
                          .join(', ')
            }
            items={supplierItems}
            size="small"
        >
            <MenuItem value="all">
                <Checkbox
                    checked={
                        filter?.suppliers?.length ===
                        (suppliers?.pagination?.size || 0) + (report === Report.SUPPLIED ? 0 : 1)
                    }
                />
                <ListItemText primary={t('all')} />
            </MenuItem>
            {suppliers?.data?.map((supplier) => (
                <MenuItem value={supplier.id} key={supplier.id}>
                    <Checkbox checked={filter?.suppliers?.includes(supplier.id)} />
                    <ListItemText primary={supplier.name} />
                </MenuItem>
            ))}
            {report !== Report.SUPPLIED && (
                <MenuItem value="">
                    <Checkbox checked={filter?.suppliers?.includes('')} />
                    <ListItemText primary={t('unknown')} />
                </MenuItem>
            )}
        </ControlledSelect>
    );
};
