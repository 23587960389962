import { QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { stockLocationsClient } from '../clients';
import { IStockLocation } from '../models';

const client = stockLocationsClient;
const listKey = QueryKeys.StockLocations;
const itemKey = QueryKeys.StockLocation;

export function useStockLocationsList(...args: UseListParams<IStockLocation>) {
    return useList(client, listKey, ...args);
}

export function useStockLocation(...args: UseItemParams<IStockLocation>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveStockLocation() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteStockLocation() {
    return useDelete(client, listKey);
}
