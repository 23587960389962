import { QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { devicesClient } from '../clients';
import { IDevice } from '../models';

const client = devicesClient;
const listKey = QueryKeys.Devices;
const itemKey = QueryKeys.Device;

export function useDevicesList(...args: UseListParams<IDevice>) {
    return useList(client, listKey, ...args);
}

export function useDevice(...args: UseItemParams<IDevice>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveDevice() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteDevice() {
    return useDelete(client, listKey);
}
